import { useState } from "react"
import styled, { css } from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"

import Button from "~/components/Button"
import { DeleteIcon, EditIcon } from "~/components/Icon"
import InputLabel from "~/components/InputLabel"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Table from "~/components/Table"
import { useAlert } from "~/contexts/AlertContext"
import { dangerColor } from "~/utilities/styles"
import trpc, { swr } from "~/utilities/trpc"

import CreateLevelModal from "./CreateLevelModal"
import EditLevelModal from "./EditLevelModal"

const GamificationLevels: React.FC = () => {
	const alert = useAlert()

	const { data: levels, error, mutate } = swr.community.listLevels.useSWR()

	const [isCreateModalOpen, setCreateModalOpen] = useState(false)
	const [editModal, setEditModal] = useState<{ isOpen: boolean; levelId?: number }>({ isOpen: false })

	return (
		<Container>
			<CreateLevelModal isOpen={isCreateModalOpen} onClose={() => setCreateModalOpen(false)} onReload={mutate} />
			<EditLevelModal
				isOpen={editModal.isOpen}
				level={levels?.find(x => x.id === editModal.levelId) ?? null}
				onClose={() => setEditModal(c => ({ ...c, isOpen: false }))}
				onReload={mutate}
			/>
			<InputLabel>Levels</InputLabel>
			{error ? (
				<p>Failed to load levels.</p>
			) : !levels ? (
				<PartialLoadingPage />
			) : (
				<>
					<Table
						isCompact
						columns={["Label", "Unlock points", "Actions"]}
						data={levels.map(level => [
							level.label,
							level.unlockPoints,
							<Actions>
								<Tooltip tooltip="Edit">
									<Action onClick={() => setEditModal({ isOpen: true, levelId: level.id })}>
										<EditIcon />
									</Action>
								</Tooltip>
								<Tooltip tooltip="Delete">
									<Action
										$danger
										onClick={async () => {
											const modal = await alert.confirm(
												"Delete level",
												`Are you sure you want to delete the level **${level.label}**?`,
											)
											if (!modal.result) return
											await trpc.community.deleteLevel.mutate(level.id)
											await mutate()
											modal.close()
										}}
									>
										<DeleteIcon />
									</Action>
								</Tooltip>
							</Actions>,
						])}
					/>
					<CreateLevelButton variant="primary" onClick={() => setCreateModalOpen(true)}>
						Create new level
					</CreateLevelButton>
				</>
			)}
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Actions = styled.div`
	display: flex;
	gap: 16px;
`

const Action = styled(Button)<{ $danger?: boolean }>`
	width: 24px;
	height: 24px;

	${props =>
		props.$danger &&
		css`
			color: ${dangerColor};
		`}
`

const CreateLevelButton = styled(Button)`
	align-self: flex-end;
`

export default GamificationLevels
