import styled from "styled-components"

const DetailedList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export const DetailedListItem = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eaeaea;
	border-radius: 8px;
	padding: 22px;
`

export const DetailedListItemTitle = styled.h2`
	font-size: 20px;
	font-weight: 600;
`

export default DetailedList
