import { type FC, useState } from "react"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"
import Tooltip from "@forento/shared/components/Tooltip"

import { SubmitButton } from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import { CheckmarkIcon } from "~/components/Icon"
import InputField from "~/components/InputField"
import { successColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

interface Props {
	content: { type: "question"; examId: number } | { type: "answer"; questionId: number }
	onCreate(): void
}

const CreateItem: FC<Props> = ({ content, onCreate }) => {
	const [value, setValue] = useState("")
	const [isCorrect, setCorrect] = useState(false)
	const [isSubmitting, setSubmitting] = useState(false)

	const handleSubmit = async () => {
		setSubmitting(true)
		try {
			if (content.type === "question") {
				await trpc.exam.createQuestion.mutate({ examId: content.examId, text: value })
			} else {
				await trpc.exam.createAnswer.mutate({
					questionId: content.questionId,
					data: { text: value, isCorrect },
				})
			}
			setValue("")
			setCorrect(false)
			onCreate()
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Container onSubmit={handleSubmit}>
			<InputField compact value={value} onChange={setValue} placeholder={`Create new ${content.type}`} />
			{content.type === "answer" && (
				<Tooltip tooltip="Is correct?">
					<Checkbox isChecked={isCorrect} onChange={setCorrect} />
				</Tooltip>
			)}
			<Tooltip tooltip={`Add ${content.type}`}>
				<CreateButton isLoading={isSubmitting} replaceOnLoading>
					<CreateIcon />
				</CreateButton>
			</Tooltip>
		</Container>
	)
}

const Container = styled(Form)`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 6px;
`

const CreateButton = styled(SubmitButton)`
	width: 24px;
	height: 24px;
`

const CreateIcon = styled(CheckmarkIcon)`
	width: 100%;
	height: 100%;
	color: ${successColor};
`

export default CreateItem
