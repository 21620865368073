import { type FC } from "react"

import { usePlatform } from "~/contexts/UserContext"

import AppSumo from "./AppSumo"
import Regular from "./Regular"
import VieDeDingue from "./VieDeDingue"

const Plans: FC = () => {
	const platform = usePlatform()!

	switch (platform.plan.type) {
		case "free":
		case "regular":
			return <Regular platformPlan={platform.plan} />
		case "appsumo":
			return <AppSumo platformPlan={platform.plan} />
		case "viededingue":
			return <VieDeDingue platformPlan={platform.plan} />
	}
}

export default Plans
