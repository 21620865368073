import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { type CommunityPostReaction } from "@forento/shared/models/community"

const Reaction: React.FC<{ reaction: CommunityPostReaction }> = ({ reaction }) => (
	<Container>
		<CommunityProfileAvatar size={32} profile={reaction.profile} />
		<p key={reaction.profile.id}>
			{reaction.profile.firstName} {reaction.profile.lastName}
		</p>
	</Container>
)

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

export default Reaction
