import { Fragment } from "react"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import { type Student } from "@forento/shared/models/user"
import { toDateTimeString } from "@forento/shared/utilities/date"

import Button from "~/components/Button"
import { ArrowIcon } from "~/components/Icon"
import Table from "~/components/Table"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"

const Users: React.FC<{ users: Student[] }> = ({ users }) => {
	const platform = useUser().user!.platform!

	return (
		<Table
			isCompact
			columns={[
				"Name",
				"Email",
				"Register date",
				{
					label: "Purchases",
					tooltip: "Hover your cursor over the numbers in the column to see what they mean",
				},
				{
					label: "",
					flex: { grow: 0, basis: 24 },
				},
			]}
			data={users
				.toSorted((a, b) => b.registerDate.getTime() - a.registerDate.getTime())
				.map(user => {
					const purchaseCellItems = [
						<Tooltip tooltip="Purchased courses">{user.purchases.courses}</Tooltip>,
						<Tooltip tooltip="Purchased videos">{user.purchases.videos}</Tooltip>,
						<Tooltip tooltip="Purchased downloadables">{user.purchases.downloadables}</Tooltip>,
						<Tooltip tooltip="Purchased sessions">{user.purchases.sessions}</Tooltip>,
						<Tooltip tooltip="Purchased events">{user.purchases.events}</Tooltip>,
						...(platform.content.memberships
							? [<Tooltip tooltip="Membership">{user.membership?.title ?? "None"}</Tooltip>]
							: []),
					]

					return [
						`${user.firstName} ${user.lastName}`,
						user.email,
						toDateTimeString(user.registerDate),
						<PurchasesCell>
							{purchaseCellItems.map((item, index, items) => (
								<Fragment key={index}>
									<PurchasesItem>{item}</PurchasesItem>
									{index + 1 < items.length && "/"}
								</Fragment>
							))}
						</PurchasesCell>,
						<DetailButton onClick={routes.user.detail(user.id)}>
							<ArrowIcon direction="right" />
						</DetailButton>,
					]
				})}
		/>
	)
}

const PurchasesCell = styled.div`
	display: flex;
	gap: 4px;
`

const PurchasesItem = styled.div`
	cursor: pointer;
`

const DetailButton = styled(Button)`
	width: 24px;
	height: 24px;
`

export default Users
