import { useState } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"

import { type CoursePageAttachment } from "@forento/shared/models/course"
import { getDataUrlByFile } from "@forento/shared/utilities/file"

import Button, { SubmitButton } from "~/components/Button"
import { AddIcon } from "~/components/Icon"
import InputField from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { primaryColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

const AddAttachment: React.FC<{ pageId: number; onAdd: (value: CoursePageAttachment) => void }> = ({
	pageId,
	onAdd,
}) => {
	const [selectedFile, setSelectedFile] = useState<File>()
	const [label, setLabel] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const isValid = label.trim().length > 0 && selectedFile !== undefined

	const handleDrop = async (acceptedFiles: File[]) => {
		if (acceptedFiles.length === 0) return
		setSelectedFile(acceptedFiles[0])
	}

	const handleSubmit = async () => {
		if (!isValid) return
		setSubmitting(true)
		try {
			const attachment = await trpc.course.createPageAttachment.mutate({
				pageId,
				label,
				dataUrl: await getDataUrlByFile(selectedFile),
			})
			onAdd(attachment)
			setSelectedFile(undefined)
			setLabel("")
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	const dropzone = useDropzone({ onDrop: handleDrop })

	return (
		<>
			<DefaultModal isOpen={selectedFile !== undefined} onSubmit={handleSubmit}>
				<ModalTitle>Add attachment</ModalTitle>
				<ModalInputs>
					<InputField label="Label" value={label} onChange={setLabel} />
				</ModalInputs>
				<ModalButtons>
					<Button variant="secondary" onClick={() => setSelectedFile(undefined)} isDisabled={isSubmitting}>
						Cancel
					</Button>
					<SubmitButton variant="primary" isLoading={isSubmitting} isDisabled={!isValid}>
						Add
					</SubmitButton>
				</ModalButtons>
			</DefaultModal>
			<Container {...dropzone.getRootProps()}>
				<input {...dropzone.getInputProps()} />
				{getContent({
					isUploading: isSubmitting,
					isDragActive: dropzone.isDragActive,
					isDragReject: dropzone.isDragReject,
				})}
			</Container>
		</>
	)
}

const getContent = ({
	isUploading,
	isDragActive,
	isDragReject,
}: {
	isUploading: boolean
	isDragActive: boolean
	isDragReject: boolean
}) => {
	if (isUploading) return <PartialLoadingPage />
	if (isDragActive)
		return <InfoText>{isDragReject ? "That file type is not supported" : `Drop here to add attachment`}</InfoText>

	return (
		<>
			<StyledAddIcon />
			<InfoText>Add attachment</InfoText>
		</>
	)
}

const Container = styled.div`
	display: flex;
	align-items: center;
	border: 1px dashed ${primaryColor};
	border-radius: 8px;
	padding: 8px;
	font-weight: 500;
	font-size: 16px;
	cursor: pointer;
`

const StyledAddIcon = styled(AddIcon)`
	width: 24px;
	height: 24px;
`

const InfoText = styled.p`
	font-weight: 500;
	font-size: 16px;
`

export default AddAttachment
