import { type FC, useState } from "react"
import styled from "styled-components"

import { type AppSumoPlatformPlan } from "@forento/shared/models/platform"
import { exhaustiveGuard } from "@forento/shared/utilities/switch"

import Button from "~/components/Button"
import InputField from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import LoadingIndicator from "~/components/LoadingIndicator"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import trpc, { swr } from "~/utilities/trpc"

import { PlanBillingInterval, PlanDivider, PlanPadding, PlanPrice, PlanSpacer, PlanTitle } from "./Plan"
import Plan from "./Plan"
import useLtdAddon from "./useLtdAddon"

type Props = { platformPlan: AppSumoPlatformPlan }
const AppSumo: FC<Props> = ({ platformPlan }) => {
	const user = useUser()
	const alert = useAlert()

	const addon = useLtdAddon({ platformPlan })

	const keys = swr.platform.listAppSumoKeys.useSWR()

	const [code, setCode] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	async function handleSubmitCode() {
		setSubmitting(true)
		try {
			const { status } = await trpc.platform.claimAppSumoKey.mutate(code)
			if (status === "invalid") {
				alert.show("Invalid code", "That code is either invalid or has already been used.")
				setSubmitting(false)
				return
			}
			if (status === "version-mismatch") {
				alert.show(
					"Invalid code",
					"That code's version does not match your current codes. Please contact support in order to redeem them.",
				)
				setSubmitting(false)
				return
			}
			await Promise.all([user.reload(), keys.mutate()])
			setCode("")
			await alert.show("Code redeemed", "Your code has been redeemed successfully.")
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	switch (addon.status) {
		case "error":
			return <p>Failed to load plans.</p>
		case "loading":
			return <PartialLoadingPage />
		case "success":
			return (
				<Container>
					{addon.paymentIntervalComponent}
					<PlansList>
						<Plan>
							<PlanTitle>{platformPlan.label}</PlanTitle>
							<PlanPrice>Lifetime</PlanPrice>
							<PlanBillingInterval>AppSumo plan</PlanBillingInterval>
							<PlanSpacer />
							<PlanPadding />
							<InputLabel>Redeemed keys</InputLabel>
							{keys.error ? (
								<p>Failed to load keys.</p>
							) : !keys.data ? (
								<LoadingIndicator size={24} />
							) : (
								keys.data.map(key => <div key={key}>{key}</div>)
							)}
							<PlanPadding />
							<StyledInputField label="Extra AppSumo code" value={code} onChange={setCode} />
							<Button
								variant="primary"
								onClick={handleSubmitCode}
								isLoading={isSubmitting}
								isDisabled={code.trim().length === 0}
							>
								Redeem code
							</Button>
							<PlanDivider />
							<Button variant="primary" isDisabled>
								Owned
							</Button>
						</Plan>
						{addon.planComponent}
					</PlansList>
				</Container>
			)
		default:
			return exhaustiveGuard(addon)
	}
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const PlansList = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 32px;
`

const StyledInputField = styled(InputField)`
	margin-bottom: 20px;
`

export default AppSumo
