import type React from "react"
import { useState } from "react"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"

import Button from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import { EditIcon } from "~/components/Icon"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

type Props = {
	id: string
	isRequired: boolean
	label: string
	description: string
	isEnabled: boolean
	reloadEmails(): Promise<unknown>
}

const Email: React.FC<Props> = ({ id, isRequired, label, description, isEnabled, reloadEmails }) => {
	const [isSubmitting, setSubmitting] = useState(false)

	return (
		<Container>
			<div>
				<Title>{label}</Title>
				<Subtitle>{description}</Subtitle>
			</div>
			<Actions>
				<Tooltip tooltip="Edit email">
					<Action onClick={routes.settings.studentEmail.detail(id)}>
						<EditIcon />
					</Action>
				</Tooltip>
				<Tooltip tooltip={isRequired ? "This email is required" : "Enable email"}>
					<Checkbox
						isChecked={isEnabled}
						onChange={async isEnabled => {
							setSubmitting(true)
							try {
								await trpc.studentEmails.update.mutate({ emailId: id, data: { isEnabled } })
								await reloadEmails()
							} catch (error) {
								console.error(error)
							} finally {
								setSubmitting(false)
							}
						}}
						isLoading={isSubmitting}
						isDisabled={isRequired}
					/>
				</Tooltip>
			</Actions>
		</Container>
	)
}

const Container = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eaeaea;
	border-radius: 8px;
	padding: 22px;
	display: flex;
	justify-content: space-between;
	gap: 16px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 20px;
	text-align: left;
`

const Subtitle = styled.p`
	font-size: 16px;
	text-align: left;
`

const Actions = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`

const Action = styled(Button)`
	width: 24px;
	height: 24px;
`

export default Email
