type MjmlImplementation = (mjml: string) => { errors: { message: string }[] }

export function checkMjml(
	mjml: string,
	implementation: MjmlImplementation,
): { status: "valid" } | { status: "invalid"; errors: string[] } {
	try {
		const { errors } = implementation(mjml)
		if (errors.length > 0) return { status: "invalid", errors: errors.map(error => error.message) }

		return { status: "valid" }
	} catch {
		return { status: "invalid", errors: [] }
	}
}

export function isMjmlValid(mjml: string, implementation: MjmlImplementation): boolean {
	try {
		return implementation(mjml).errors.length === 0
	} catch {
		return false
	}
}
