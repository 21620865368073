import { type FC, useState } from "react"

import Button, { SubmitButton } from "~/components/Button"
import InputField from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalTitle } from "~/components/Modal"
import { useAlert } from "~/contexts/AlertContext"
import trpc from "~/utilities/trpc"

interface Props {
	isOpen: boolean
	onClose(): void
	onSubmit(referralCode: string): void
}

const ReferralCodeModal: FC<Props> = ({ isOpen, onClose, onSubmit }) => {
	const alert = useAlert()

	const [code, setCode] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const handleSubmit = async () => {
		setSubmitting(true)
		try {
			const referrer = await trpc.referral.getCode.query(code)
			if (referrer === null) {
				await alert.show("Invalid code", "The referral code you entered is invalid.")
				return
			}
			onSubmit(referrer.code)
			onClose()
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={isOpen} onSubmit={handleSubmit}>
			<ModalTitle>Add referral code</ModalTitle>
			<ModalInputs>
				<InputField label="Referral code" value={code} onChange={setCode} />
			</ModalInputs>
			<ModalButtons>
				<Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
					Cancel
				</Button>
				<SubmitButton variant="primary" isLoading={isSubmitting}>
					Save
				</SubmitButton>
			</ModalButtons>
		</DefaultModal>
	)
}

export default ReferralCodeModal
