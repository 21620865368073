import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { type FC } from "react"
import styled, { css } from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"

import Button from "~/components/Button"
import { DeleteIcon, DragIndicatorIcon, EditIcon, QuizIcon } from "~/components/Icon"
import { useAlert } from "~/contexts/AlertContext"
import routes from "~/utilities/routes"
import { dangerColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

interface Props {
	exam: { id: number; name: string; publishDate: Date | null }
	onDelete(): void
}

const Exam: FC<Props> = ({ exam: { id, name, publishDate }, onDelete }) => {
	const alert = useAlert()

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

	const style = { transform: CSS.Transform.toString(transform), transition }

	const handleDelete = async () => {
		const dialog = await alert.confirm(
			"Delete exam",
			"Are you sure you want to delete this exam? This action cannot be undone.",
		)
		if (!dialog.result) return

		try {
			await trpc.exam.delete.mutate(id)
			dialog.close()
			onDelete()
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<ListItem ref={setNodeRef} style={style}>
			<div>
				<Button onClick={routes.exam.detail(id)}>
					<Title>{name}</Title>
				</Button>
				<Subtitle>{publishDate ? `Published ${publishDate.toLocaleDateString()}` : "Not published"}</Subtitle>
			</div>
			<Actions>
				<Tooltip tooltip="Completions">
					<ActionButton onClick={routes.exam.completions(id)}>
						<QuizIcon />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Edit">
					<ActionButton onClick={routes.exam.detail(id)}>
						<EditIcon />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Delete">
					<ActionButton isDanger onClick={handleDelete}>
						<DeleteIcon />
					</ActionButton>
				</Tooltip>
				<DragButton {...attributes} {...listeners}>
					<DragIndicatorIcon />
				</DragButton>
			</Actions>
		</ListItem>
	)
}

const ListItem = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 0;

	&:not(:last-child) {
		border-bottom: 1px solid #d6d6d6;
	}
`

const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 16px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 4px;
`

const Subtitle = styled.p`
	font-size: 14px;
	color: #6f6f6f;
`

const ActionButton = styled(Button)<{ isDanger?: boolean }>`
	width: 24px;
	height: 24px;

	${props =>
		props.isDanger &&
		css`
			color: ${dangerColor};
		`}
`

const DragButton = styled.div`
	width: 24px;
	height: 24px;
	cursor: grab;
`

export default Exam
