import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { type CSSProperties, type FC } from "react"
import styled, { css } from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"
import Tooltip from "@forento/shared/components/Tooltip"
import { type Downloadable as DownloadableType } from "@forento/shared/models/downloadable"
import { getExtensionByFileName } from "@forento/shared/utilities/file"
import { toSlug } from "@forento/shared/utilities/string"

import Button from "~/components/Button"
import { DeleteIcon, DownloadIcon, DragIndicatorIcon, EditIcon } from "~/components/Icon"
import { useAlert } from "~/contexts/AlertContext"
import routes from "~/utilities/routes"
import { dangerColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

const Downloadable: FC<{ downloadable: DownloadableType; onDelete(): void }> = ({ downloadable, onDelete }) => {
	const alert = useAlert()
	const { setNodeRef, transform, transition, attributes, listeners } = useSortable({ id: downloadable.id })

	const style: CSSProperties = { transform: CSS.Transform.toString(transform), transition }

	const handleDownload = async () => {
		if (downloadable.type === "url") {
			window.open(downloadable.url, "_blank")
			return
		}
		const response = await fetch(downloadable.filePath, { headers: { "Content-Type": "application/octet-stream" } })
		const extension = getExtensionByFileName(downloadable.filePath)
		const fileUrl = URL.createObjectURL(await response.blob())
		const element = document.createElement("a")
		element.href = fileUrl
		element.download = toSlug(downloadable.title) + (extension !== null ? `.${extension}` : "")
		document.body.appendChild(element)
		element.click()
		URL.revokeObjectURL(fileUrl)
		element.remove()
	}

	const handleDelete = async () => {
		const dialog = await alert.confirm(
			"Delete downloadable",
			`Are you sure you want to delete the downloadable *${downloadable.title}*?`,
		)
		if (!dialog.result) return

		await trpc.downloadable.delete.mutate(downloadable.id)

		dialog.close()
		onDelete()
	}

	return (
		<Container ref={setNodeRef} style={style}>
			{downloadable.thumbnailFilePath && (
				<ThumbnailContainer>
					<AspectRatio aspectRatio={16 / 9} width={{ unit: "percent", value: 100 }}>
						<Thumbnail alt="Thumbnail" src={downloadable.thumbnailFilePath} />
					</AspectRatio>
				</ThumbnailContainer>
			)}
			<Details>
				<Title>{downloadable.title}</Title>
				{downloadable.price !== null && <Purchases>Purchases: {downloadable.orderCount}</Purchases>}
			</Details>
			<ActionsContainer>
				<Tooltip tooltip="Download">
					<ActionButton onClick={handleDownload}>
						<DownloadIcon />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Edit">
					<ActionButton onClick={routes.downloadable.edit(downloadable.id)}>
						<EditIcon />
					</ActionButton>
				</Tooltip>
				{downloadable.orderCount > 0 ? (
					<Tooltip tooltip="You cannot delete this as it has been sold at least once">
						<ActionButton isDanger isDisabled>
							<DeleteIcon />
						</ActionButton>
					</Tooltip>
				) : (
					<Tooltip tooltip="Delete">
						<ActionButton isDanger onClick={handleDelete}>
							<DeleteIcon />
						</ActionButton>
					</Tooltip>
				)}
				<DragButton {...attributes} {...listeners}>
					<DragIndicatorIcon />
				</DragButton>
			</ActionsContainer>
		</Container>
	)
}

const Container = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eaeaea;
	border-radius: 8px;
	padding: 22px;
	display: flex;
	gap: 16px;
`

const ThumbnailContainer = styled.div`
	flex: 0 0 250px;
`

const Thumbnail = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

const Details = styled.div`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 20px;
	text-align: left;
`

const Purchases = styled.p`
	font-size: 16px;
	margin-top: 8px;
`

const ActionsContainer = styled.div`
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	gap: 8px;
`

const ActionButton = styled(Button)<{ isDanger?: boolean }>`
	width: 24px;
	height: 24px;

	${props =>
		props.isDanger &&
		css`
			color: ${dangerColor};
		`}
`

const DragButton = styled.div`
	width: 24px;
	height: 24px;
	cursor: grab;
`

export default Downloadable
