import { type FC, type SetStateAction, useState } from "react"
import styled from "styled-components"

import {
	type VideoCaptionLanguageCode,
	videoCaptionLanguageCodes,
	videoCaptionLanguages,
} from "@forento/shared/models/video"

import Button from "~/components/Button"
import Dropdown from "~/components/Dropdown"
import FileChooser from "~/components/FileChooser"
import { CloseIcon, SubtitlesIcon } from "~/components/Icon"
import { DefaultModal, ModalButtons, ModalInputs, ModalTitle } from "~/components/Modal"
import { primaryColor } from "~/utilities/styles"

type Props = {
	captions: VideoCaptionLanguageCode[]
	addCaptions: { language: VideoCaptionLanguageCode; file: File }[]
	removeCaptions: VideoCaptionLanguageCode[]
	setAddCaptions(value: SetStateAction<{ language: VideoCaptionLanguageCode; file: File }[]>): void
	setRemoveCaptions(value: SetStateAction<VideoCaptionLanguageCode[]>): void
}

const VideoCaptions: FC<Props> = ({ captions, addCaptions, removeCaptions, setAddCaptions, setRemoveCaptions }) => {
	const [newCaptionLanguage, setNewCaptionLanguage] = useState<VideoCaptionLanguageCode>("en")
	const [newCaptionFile, setNewCaptionFile] = useState<File | null>(null)

	return (
		<>
			<DefaultModal isOpen={Boolean(newCaptionFile)}>
				<ModalTitle>Add captions</ModalTitle>
				<ModalInputs>
					<Dropdown
						items={videoCaptionLanguageCodes.map(x => ({ id: x, title: videoCaptionLanguages[x] }))}
						selectedItemId={newCaptionLanguage}
						onChange={value =>
							setNewCaptionLanguage(videoCaptionLanguageCodes.find(x => x === value) ?? "en")
						}
					/>
				</ModalInputs>
				<ModalButtons>
					<Button variant="secondary" onClick={() => setNewCaptionFile(null)}>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							if (!newCaptionFile) return
							setAddCaptions(current => [
								...current,
								{ language: newCaptionLanguage, file: newCaptionFile },
							])
							setNewCaptionFile(null)
							setNewCaptionLanguage("en")
						}}
					>
						Add captions
					</Button>
				</ModalButtons>
			</DefaultModal>
			<Captions>
				{captions
					.filter(
						existing =>
							!addCaptions.some(x => x.language === existing) && !removeCaptions.includes(existing),
					)
					.map(language => (
						<Caption key={`existing-${language}`}>
							<CaptionIcon />
							{videoCaptionLanguages[language]}
							<DeleteCaption onClick={() => setRemoveCaptions(current => [...current, language])}>
								<DeleteCaptionIcon />
							</DeleteCaption>
						</Caption>
					))}
				{addCaptions.map(caption => (
					<Caption key={`new-${caption}`}>
						<CaptionIcon />
						{videoCaptionLanguages[caption.language]}
						<DeleteCaption
							onClick={() =>
								setAddCaptions(current => current.filter(x => x.language !== caption.language))
							}
						>
							<DeleteCaptionIcon />
						</DeleteCaption>
					</Caption>
				))}
			</Captions>
			<UploadCaptions>
				<FileChooser type="video-captions" value={newCaptionFile} onChange={setNewCaptionFile} />
			</UploadCaptions>
		</>
	)
}

const Captions = styled.div`
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
`

const Caption = styled.div`
	padding: 8px 16px;
	border-radius: 36px;
	line-height: 1;
	color: white;
	background-color: ${primaryColor};
	display: flex;
	align-items: center;
	gap: 8px;
`

const CaptionIcon = styled(SubtitlesIcon)`
	width: 20px;
	height: 20px;
`

const DeleteCaption = styled(Button)`
	width: 20px;
	height: 20px;
`

const DeleteCaptionIcon = styled(CloseIcon)`
	color: white;
	width: 100%;
	height: 100%;
`

const UploadCaptions = styled.div`
	max-width: 300px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`

export default VideoCaptions
