import { type Dispatch, type SetStateAction } from "react"

export const replaceArrayValue = <T>(array: T[], index: number, newValue: T): T[] => [
	...array.slice(0, index),
	newValue,
	...array.slice(index + 1),
]

export const toDistinctArray = <T>(array: T[]): T[] => [...new Set(array)]

export function toggleArrayItem<T>(array: T[], item: T): T[] {
	return array.includes(item) ? array.filter(i => i !== item) : [...array, item]
}

export function toggleStateArray<T>(dispatcher: Dispatch<SetStateAction<T[]>>, item: T) {
	dispatcher(current => (current.includes(item) ? current.filter(i => i !== item) : [...current, item]))
}
