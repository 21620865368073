import { useState } from "react"
import { Navigate, useNavigate } from "react-router"
import styled from "styled-components"

import Button from "~/components/Button"
import Layout, { PageTitle } from "~/components/Layout"
import { useUser } from "~/contexts/UserContext"
import withAccessRequirement from "~/hocs/withAccessRequirement"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

const header = <PageTitle>Community</PageTitle>

const EnableCommunityPage: React.FC = () => {
	const user = useUser()
	const navigate = useNavigate()

	const [isSubmitting, setSubmitting] = useState(false)

	if (user.user!.platform!.community !== null) return <Navigate to={routes.community.posts()} />

	const handleEnableCommunity = async () => {
		setSubmitting(true)
		try {
			await trpc.platform.update.mutate({ isCommunityEnabled: true })
			if (user.user!.communityProfileId === null) {
				await trpc.community.createProfile.mutate()
			}
			await user.reload()
			navigate(routes.community.settings())
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout>
			{header}
			<Container>
				<p>
					The community is the place for your users to interact with you and each other. This includes posts,
					comments, reactions, and direct messages. We recommend enabling this if you are want to have a
					direct interaction with your customers.
				</p>
				<Button variant="primary" onClick={handleEnableCommunity} isLoading={isSubmitting}>
					Enable community
				</Button>
			</Container>
		</Layout>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`

export default withAccessRequirement("community", header, EnableCommunityPage)
