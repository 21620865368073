export const communityPrivileges = ["view", "react", "comment", "post"] as const

export type CommunityPrivilege = (typeof communityPrivileges)[number]

export type ThinCommunityProfile = {
	id: number
	email: string
	firstName: string
	lastName: string
	avatarFilePath: string
	registerDate: { community: Date; user: Date }
}

export interface PublicCommunityProfile {
	id: number
	platformId: number
	firstName: string
	lastName: string
	avatarFilePath: string
	isCreator: boolean
	posts: number
	reactions: number
	comments: number
	gamification: {
		points: number
		currentLevel: {
			tier: number
			label: string
			unlockPoints: number
		} | null
		nextLevel: {
			tier: number
			label: string
			unlockPoints: number
		} | null
	}
	registerDate: {
		community: Date
		user: Date
	}
}

export interface Conversation {
	otherProfile: PublicCommunityProfile
	lastMessage: {
		text: string
		sendDate: Date
	}
}

export interface CommunityMessage {
	id: number
	text: string
	senderProfileId: number
	receiverProfileId: number
	sendDate: Date
}

export interface CommunityPostCategory {
	id: number
	label: string
}

export interface CommunityPost {
	id: number
	profile: PublicCommunityProfile
	platformId: number
	category: CommunityPostCategory | null
	title: string
	htmlContent: string
	pollOptions: CommunityPostPollOption[]
	attachments: CommunityPostAttachment[]
	hasUserReacted: boolean
	reactions: number
	comments: number
	createDate: Date
	editDate: Date | null
}

export interface CommunityPostReaction {
	profile: PublicCommunityProfile
}

export interface CommunityPostComment {
	id: number
	profile: PublicCommunityProfile
	text: string
	createDate: Date
	editDate: Date | null
}

export interface CommunityPostPollOption {
	id: number
	label: string
	votes: {
		total: number
		share: number
	}
	isSelected: boolean
}

export interface CommunityPostAttachment {
	id: number
	label: string
	filePath: string
}

export interface GamificationLevel {
	id: number
	platformId: number
	label: string
	unlockPoints: number
}
