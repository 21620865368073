import { type FC, useState } from "react"
import styled from "styled-components"

import { toDateString } from "@forento/shared/utilities/date"

import Button from "~/components/Button"
import { useAlert } from "~/contexts/AlertContext"
import routes from "~/utilities/routes"
import { dangerColor, primaryColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

import SelectedStatus from "../SelectedStatus"
import CheckSlider from "./CheckSlider"

type Props = {
	domain: {
		id: number
		domain: string
		isSelected: boolean
		platform: { subdomain: string }
		expireDate: Date
		isAutoRenewEnabled: boolean
	}
	reload(): Promise<void>
}
const AutomaticDomain: FC<Props> = ({ domain, reload }) => {
	const alert = useAlert()

	const [isTogglingAutoRenew, setTogglingAutoRenew] = useState(false)
	const [isSelecting, setSelecting] = useState(false)

	async function handleToggleAutoRenew() {
		setTogglingAutoRenew(true)
		try {
			await trpc.domain.updateAutomatic.mutate({ id: domain.id, data: { autoRenew: !domain.isAutoRenewEnabled } })
			await reload()
		} catch (error) {
			console.error("Failed to toggle domain auto renew!", error)
		} finally {
			setTogglingAutoRenew(false)
		}
	}

	async function handleSetSelected(selected: boolean) {
		setSelecting(true)
		try {
			await trpc.domain.setSelected.mutate({ domain: selected ? domain.domain : null })
			await reload()
		} catch (error) {
			console.error(`Failed to ${selected ? "select" : "deselect"} domain!`, error)
		} finally {
			setSelecting(false)
		}
	}

	async function handleChangeSubdomain() {
		const result = await alert.input(
			"Change subdomain",
			`Enter a new subdomain for the domain **${domain.domain}**`,
			"Subdomain",
			domain.platform.subdomain,
		)
		if (result.result === null) return

		await trpc.domain.updateManual.mutate({ id: domain.id, platformSubdomain: result.result.trim() })
		await reload()

		result.close()
	}

	return (
		<Container>
			<Header>
				<Details>
					<Title>{domain.domain}</Title>
					<SelectedStatus isSelected={domain.isSelected} />
				</Details>
				<ExpireDate>{toDateString(domain.expireDate)}</ExpireDate>
				<AutoRenew>
					<CheckSlider
						isChecked={domain.isAutoRenewEnabled}
						isLoading={isTogglingAutoRenew}
						onClick={handleToggleAutoRenew}
					/>
				</AutoRenew>
				<Actions>
					<ActionButton onClick={routes.settings.domain.manageDns(domain.id)}>Manage DNS</ActionButton>
					<ActionButton onClick={handleChangeSubdomain}>Change subdomain</ActionButton>
					{domain.isSelected ? (
						<ActionButton $isDanger onClick={() => handleSetSelected(false)} isLoading={isSelecting}>
							Disconnect
						</ActionButton>
					) : (
						<ActionButton onClick={() => handleSetSelected(true)} isLoading={isSelecting}>
							Publish
						</ActionButton>
					)}
				</Actions>
			</Header>
		</Container>
	)
}

const Container = styled.div`
	background-color: #fbfbf9;
	border: 1px solid #eaeaea;
	border-radius: 12px;
	padding: 16px 22px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

const Details = styled.div`
	flex: 2 0 0;
`

const Title = styled.p`
	font-weight: 600;
	font-size: 16px;
`

const ExpireDate = styled.div`
	flex: 1 0 0;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	justify-content: center;
`

const AutoRenew = styled.div`
	flex: 1 0 0;
	font-weight: 600;
	font-size: 14px;
	display: flex;
	justify-content: center;
`

const Actions = styled.div`
	flex: 0 0 320px;
	display: flex;
	justify-content: flex-end;
	gap: 16px;
`

const ActionButton = styled(Button)<{ $isDanger?: boolean }>`
	font-weight: 600;
	font-size: 14px;
	color: ${props => (props.$isDanger ? dangerColor : primaryColor)};
	display: flex;
	align-items: center;
	gap: 6px;
	white-space: nowrap;
`

export default AutomaticDomain
