import { type FC } from "react"
import styled from "styled-components"

import Layout, { PageTitle } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useUser } from "~/contexts/UserContext"
import ConnectGateway from "~/pages/payments/PaymentsPage/ConnectGateway"
import { swr } from "~/utilities/trpc"

import PayPalMerchant from "./PayPalMerchant"
import PaymentsTab from "./PaymentsTab"
import Stripe from "./Stripe"

const PaymentsPage: React.FC = () => {
	const platform = useUser().user!.platform!

	if (!platform.paymentGateway?.isEnabled) return <ConnectGateway />

	return <Content />
}

export default PaymentsPage

const Content: FC = () => {
	const { data: paymentInformation, error, mutate } = swr.payment.getPaymentInformation.useSWR()

	return (
		<Layout>
			<PageTitle>Payments</PageTitle>
			{error ? (
				<p>Failed to load payments.</p>
			) : paymentInformation === undefined ? (
				<PartialLoadingPage />
			) : paymentInformation.type === "stripe" ? (
				<Stripe paymentInformation={paymentInformation} />
			) : (
				<>
					<Header>
						<PayPalMerchant reload={mutate} />
					</Header>
					<PaymentsTab payments={paymentInformation.payments} />
				</>
			)}
		</Layout>
	)
}

const Header = styled.div`
	display: flex;
	gap: 32px;
	justify-content: flex-end;
	margin-bottom: 14px;
`
