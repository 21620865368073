import { useState } from "react"

import Button, { SubmitButton } from "~/components/Button"
import InputField from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalTitle } from "~/components/Modal"
import trpc from "~/utilities/trpc"

interface Props {
	isOpen: boolean
	onClose(): void
	onReload(): Promise<unknown>
}

const CreateLevelModal: React.FC<Props> = ({ isOpen, onClose, onReload }) => {
	const [isSubmitting, setSubmitting] = useState(false)
	const [label, setLabel] = useState("")
	const [unlockPoints, setUnlockPoints] = useState("")

	const handleSave = async () => {
		setSubmitting(true)
		try {
			await trpc.community.createLevel.mutate({ label: label.trim(), unlockPoints: parseInt(unlockPoints) })
			await onReload()
			setLabel("")
			setUnlockPoints("")
			onClose()
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={isOpen} onSubmit={handleSave}>
			<ModalTitle>Create new level</ModalTitle>
			<ModalInputs>
				<InputField label="Label" value={label} onChange={setLabel} />
				<InputField label="Unlock points" inputType="number" value={unlockPoints} onChange={setUnlockPoints} />
			</ModalInputs>
			<ModalButtons>
				<Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
					Cancel
				</Button>
				<SubmitButton
					variant="primary"
					isLoading={isSubmitting}
					isDisabled={label.trim().length === 0 || unlockPoints.length === 0}
				>
					Create
				</SubmitButton>
			</ModalButtons>
		</DefaultModal>
	)
}

export default CreateLevelModal
