import { type FC } from "react"
import { useParams } from "react-router"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import { parseNumber } from "@forento/shared/utilities/number"

import Button from "~/components/Button"
import { CloseIcon, LikeIcon } from "~/components/Icon"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

const ReviewCommentsPage: FC = () => {
	const courseId = parseNumber(useParams().courseId) ?? -1

	const course = swr.course.get.useSWR(courseId)
	const comments = swr.course.listUnapprovedComments.useSWR({ courseId })

	return (
		<Layout>
			<PageBreadcrumb
				title="Review comments"
				path={[
					{ title: "Courses", link: routes.course.index() },
					{ title: course.data?.title ?? "...", link: routes.course.detail(courseId) },
				]}
			/>
			{comments.error ? (
				<p>Failed to load comments.</p>
			) : !comments.data ? (
				<PartialLoadingPage />
			) : (
				<Comments>
					{comments.data.map(comment => (
						<Comment key={`${comment.type}-${comment.id}`}>
							<div>
								<CommentAuthor>
									{comment.user.firstName} {comment.user.lastName}
								</CommentAuthor>
								<CommentText>{comment.text}</CommentText>
							</div>
							<Actions>
								<Tooltip tooltip="Approve">
									<Action
										onClick={async () => {
											if (comment.type === "comment") {
												await trpc.course.approveComment.mutate(comment.id)
											} else {
												await trpc.course.approveCommentReply.mutate(comment.id)
											}
											await comments.mutate(
												comments.data!.filter(
													x => !(x.type === comment.type && x.id === comment.id),
												),
											)
										}}
									>
										<LikeIcon />
									</Action>
								</Tooltip>
								<Tooltip tooltip="Reject">
									<Action
										onClick={async () => {
											if (comment.type === "comment") {
												await trpc.course.deleteComment.mutate(comment.id)
											} else {
												await trpc.course.deleteCommentReply.mutate(comment.id)
											}
											await comments.mutate(
												comments.data!.filter(
													x => !(x.type === comment.type && x.id === comment.id),
												),
											)
										}}
									>
										<CloseIcon />
									</Action>
								</Tooltip>
							</Actions>
						</Comment>
					))}
				</Comments>
			)}
		</Layout>
	)
}

const Comments = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Comment = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eaeaea;
	border-radius: 8px;
	padding: 22px;
	display: flex;
	justify-content: space-between;
	gap: 16px;
`

const CommentAuthor = styled.h2`
	font-weight: 600;
	font-size: 18px;
	text-align: left;
`

const CommentText = styled.p`
	font-size: 16px;
	text-align: left;
`

const Actions = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`

const Action = styled(Button)`
	width: 24px;
	height: 24px;
`

export default ReviewCommentsPage
