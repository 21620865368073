import { useState } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import InputField from "~/components/InputField"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import trpc from "~/utilities/trpc"

const Setup: React.FC = () => {
	const user = useUser()
	const alert = useAlert()

	const [code, setCode] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const handleChangeCode = (value: string) => {
		// Limit to only ASCII characters
		setCode(value.replace(/[^\x30-\x39|\x41-\x57|\x61-\x7A]/g, "").toUpperCase())
	}

	const handleSubmit = async () => {
		if (code.length === 0) return
		setSubmitting(true)
		try {
			const { status } = await trpc.referral.create.mutate({ code })
			if (status === "code-taken") {
				alert.show("Code taken", "That code is already taken unfortunately.")
				return
			}
			await user.reload()
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Container>
			<p>
				With referrals you can earn a 40% commission from all users you refer to Forento through their whole
				lifetime. You will receive 40% of the subscription plan and 40% of our cut of sold courses, videos, and
				memberships.
			</p>
			<p>In order to enable referrals you need to choose a code to use when referring customers.</p>
			<InputField placeholder="Referral code" value={code} onChange={handleChangeCode} />
			<p>
				By enabling referrals, you accept our{" "}
				<a
					href="https://forento-cdn.ams3.cdn.digitaloceanspaces.com/policies/Terms%20&%20Conditions%20of%20Forento%20Referral%20Program.pdf"
					target="_blank"
					rel="noreferrer noopener"
				>
					Terms &amp; Conditions of Forento Referral Program
				</a>
				.
			</p>
			<Button variant="primary" onClick={handleSubmit} isLoading={isSubmitting}>
				Enable referrals
			</Button>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`

export default Setup
