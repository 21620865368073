import dayjs from "dayjs"
import { type FC, useState } from "react"
import { Navigate } from "react-router"
import { useSearchParams } from "react-router-dom"
import styled from "styled-components"

import { type PlatformFindSource } from "@forento/shared/models/platform"

import Button from "~/components/Button"
import { usePlatform, useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

import Layout, { Content, Title } from "../Layout"
import blogImage from "./images/blog.svg"
import friendImage from "./images/friend.svg"
import influencerImage from "./images/influencer.svg"
import landingImage from "./images/landing.svg"
import marketplaceImage from "./images/marketplace.svg"
import otherImage from "./images/other.svg"
import searchEngineImage from "./images/searchEngine.svg"
import socialMediaImage from "./images/socialMedia.svg"

const SelectFindSourcePage: FC = () => {
	const user = useUser()
	const platform = usePlatform()!
	const [searchParams] = useSearchParams()

	const [startDate] = useState(dayjs())

	const options: { id: PlatformFindSource; label: string; image: string }[] = [
		{ id: "landing", label: "Forento's website", image: landingImage },
		{ id: "search-engine", label: "Search engine", image: searchEngineImage },
		{ id: "marketplace", label: "Software marketplace", image: marketplaceImage },
		{ id: "friend", label: "Friend", image: friendImage },
		{ id: "blog", label: "Blog", image: blogImage },
		{ id: "influencer", label: "Influencer", image: influencerImage },
		{ id: "social-media", label: "Social media", image: socialMediaImage },
		{ id: "other", label: "Other", image: otherImage },
	]

	if (platform.hasChosenFindSource) {
		return <Navigate to={searchParams.get("next") ?? routes.account.selectIntent()} />
	}

	async function handleSelect(id: PlatformFindSource) {
		await trpc.platform.setFindSource.mutate({
			source: id,
			answerTime: Math.floor(dayjs().diff(startDate, "milliseconds") / 10) / 100,
		})
		await user.reload()
	}

	return (
		<Layout>
			<Content $uncappedWidth>
				<Title>How did you hear about us?</Title>
				<DesktopOptions>
					{options.map(option => (
						<DesktopOption key={option.id}>
							<OptionImage alt="Illustration" src={option.image} />
							<TitleContainer>
								<OptionTitle>{option.label}</OptionTitle>
							</TitleContainer>
							<Button variant="primary" onClick={() => handleSelect(option.id)}>
								Select
							</Button>
						</DesktopOption>
					))}
				</DesktopOptions>
				<MobileOptions>
					{options.map(option => (
						<Button key={option.id} variant="primary" onClick={() => handleSelect(option.id)}>
							{option.label}
						</Button>
					))}
				</MobileOptions>
			</Content>
		</Layout>
	)
}

const DesktopOptions = styled.div`
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 16px;
	justify-content: center;

	@media (max-width: 599px) {
		display: none;
	}

	@media (min-width: 1100px) {
		width: ${250 * 4 + 16 * 3}px;
	}
`

const MobileOptions = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;

	@media (min-width: 600px) {
		display: none;
	}
`

const DesktopOption = styled.div`
	background-color: white;
	padding: 16px;
	box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 5px 3px;
	border: 2px solid rgba(0, 0, 0, 0.03);
	border-radius: 12px;
	display: flex;
	gap: 16px;

	@media (min-width: 600px) {
		flex: 0 0 250px;
		flex-direction: column;
	}
`

const OptionImage = styled.img`
	align-self: center;
	width: 80%;
	height: 150px;
	object-fit: contain;

	@media (max-width: 599px) {
		display: none;
	}
`

const TitleContainer = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`

const OptionTitle = styled.h2`
	font-size: 20px;
	font-weight: 600;
	text-align: center;
`

export default SelectFindSourcePage
