import { useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router"

import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import VideoUploader from "~/components/VideoUploader"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"
import { type VideoUploadProgress, startVideoUpload } from "~/utilities/video"

const UploadVideoPage: React.FC = () => {
	const { videoId } = useParams()
	const navigate = useNavigate()

	const { data: video, error } = swr.video.get.useSWR({ videoId: Number(videoId) })
	const [progress, setProgress] = useState<VideoUploadProgress>()

	if (video === null || (video !== undefined && video.uploadCompleteDate !== null))
		return <Navigate to={routes.video.index()} />

	const handleUpload = async (file: File) => {
		if (video === undefined || video.host !== "bunny") return

		await startVideoUpload(file, video, async progress => {
			setProgress(progress)
			if (progress.status === "completed") {
				await trpc.video.completeUpload.mutate({ videoId: video.id })
				navigate(routes.video.index())
			}
		})
	}

	return (
		<Layout>
			<PageBreadcrumb title="Upload" path={[{ title: "Videos", link: routes.video.index() }]} />
			{error ? (
				<p>Failed to load video.</p>
			) : video === undefined ? (
				<PartialLoadingPage />
			) : (
				<VideoUploader
					onUpload={handleUpload}
					progress={
						progress !== undefined
							? progress.status === "uploading"
								? progress.progress
								: progress.status === "completed"
									? 1
									: 0
							: null
					}
				/>
			)}
		</Layout>
	)
}

export default UploadVideoPage
