import { type FC, useLayoutEffect, useRef, useState } from "react"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"

import Button, { SubmitButton } from "~/components/Button"
import { CheckmarkIcon } from "~/components/Icon"
import { successColor } from "~/utilities/styles"

interface Props {
	text: string
	onSubmit(text: string): void
}

const InlineTextEditor: FC<Props> = ({ text, onSubmit }) => {
	const [editText, setEditText] = useState<string>()
	const [width, setWidth] = useState(0)

	const formRef = useRef<HTMLFormElement>(null)
	const inputRef = useRef<HTMLInputElement>(null)

	useLayoutEffect(() => {
		if (inputRef.current === null) return

		setWidth(inputRef.current.scrollWidth)
	}, [editText])

	return editText !== undefined ? (
		<Container
			ref={formRef}
			onSubmit={() => {
				setEditText(undefined)
				onSubmit(editText)
			}}
		>
			<TextInput
				width={width}
				ref={inputRef}
				value={editText}
				onChange={event => setEditText(event.target.value)}
				onBlur={() => formRef.current?.requestSubmit()}
				autoFocus
			/>
			<SaveButton>
				<SaveIcon />
			</SaveButton>
		</Container>
	) : (
		<Button onClick={() => setEditText(text)}>{text}</Button>
	)
}

const Container = styled(Form)`
	display: flex;
	align-items: center;
	gap: 8px;
`

const TextInput = styled.input.attrs({ type: "text" })<{ width: number }>`
	border: none;
	width: ${props => props.width}px;
	font-style: italic;
`

const SaveButton = styled(SubmitButton)`
	width: 24px;
	height: 24px;
`

const SaveIcon = styled(CheckmarkIcon)`
	width: 100%;
	height: 100%;
	color: ${successColor};
`

export default InlineTextEditor
