import { useState } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import { useUser } from "~/contexts/UserContext"
import trpc from "~/utilities/trpc"

const CreateCommunityProfile: React.FC = () => {
	const user = useUser()

	const [isSubmitting, setSubmitting] = useState(false)

	const handleCreateProfile = async () => {
		setSubmitting(true)
		try {
			await trpc.community.createProfile.mutate()
			await user.reload()
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Container>
			<p>In order to participate in the community you need to make your profile visible to the community.</p>
			<Button variant="primary" onClick={handleCreateProfile} isLoading={isSubmitting}>
				Make profile visible
			</Button>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`

export default CreateCommunityProfile
