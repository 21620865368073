import { useEffect } from "react"
import { Navigate } from "react-router"
import styled from "styled-components"

import Layout, { PageTitle } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

const CompletingStripePage: React.FC = () => {
	const user = useUser()
	const platform = user.user!.platform!

	useEffect(() => {
		if (platform.paymentGateway?.isEnabled) return

		async function check() {
			const response = await trpc.payment.stripe.checkOnboardingStatus.mutate()
			if (!response.isLinked) {
				await new Promise(resolve => setTimeout(resolve, 2000))
				check()
				return
			}

			await user.reload()
		}

		check()
	}, [platform.paymentGateway?.isEnabled, user])

	if (platform.paymentGateway?.isEnabled) return <Navigate to={routes.payments.index()} />

	return (
		<Layout>
			<PageTitle>Payments</PageTitle>
			<Content>
				<PartialLoadingPage />
				Completing connection...
			</Content>
		</Layout>
	)
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
`

export default CompletingStripePage
