import { type FC } from "react"
import styled from "styled-components"

import { CheckmarkIcon, CloseIcon } from "~/components/Icon"
import { lightTextColor, primaryColor } from "~/utilities/styles"

import Button from "./Button"

interface Props {
	tags: { id: number; label: string }[]
	selectedTags: number[]
	onChange(tagId: number): void
}

const SelectTags: FC<Props> = ({ tags, selectedTags, onChange }) => (
	<Container>
		{tags.map(tag => {
			const isSelected = selectedTags.includes(tag.id)
			return (
				<Tag key={tag.id} $isSelected={isSelected} onClick={() => onChange(tag.id)}>
					<TagIcon as={isSelected ? CheckmarkIcon : CloseIcon} /> {tag.label}
				</Tag>
			)
		})}
	</Container>
)

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
`

const Tag = styled(Button)<{ $isSelected: boolean }>`
	padding: 8px 16px;
	border-radius: 36px;
	line-height: 1;
	color: white;
	background-color: ${props => (props.$isSelected ? primaryColor : lightTextColor)};
	display: flex;
	align-items: center;
	gap: 8px;
`

const TagIcon = styled.div`
	width: 20px;
	height: 20px;
`

export default SelectTags
