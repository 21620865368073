import type { OutputData } from "@editorjs/editorjs"
import { type FC, useCallback, useState, useEffect, useMemo } from "react"
import { Navigate, useNavigate, useParams } from "react-router"
import styled from "styled-components"

import { toPriceString } from "@forento/shared/utilities/currency"
import { getDataUrlByFile } from "@forento/shared/utilities/file"
import { parseNumber } from "@forento/shared/utilities/number"

import Button from "~/components/Button"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import ProductPricing from "~/components/ProductPricing"
import { ControlledTabs } from "~/components/Tabs"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import usePrice from "~/hooks/usePrice"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

import CourseInformation, { type CourseInformationData } from "./CourseInformation"
import CourseLongDescriptionEditor from "./CourseLongDescriptionEditor"
import Testers from "./Testers"
import { Description, Title } from "./styles"

const CourseSettingsPage: FC = () => {
	const courseId = parseNumber(useParams().courseId) ?? -1
	const navigate = useNavigate()
	const alert = useAlert()
	const platform = useUser().user!.platform!

	const course = swr.course.get.useSWR(courseId)
	const tags = swr.course.listTags.useSWR()

	const [informationData, setInformationData] = useState<CourseInformationData>()
	const { price, priceValue, setPrice } = usePrice()
	const [membershipIds, setMembershipIds] = useState<number[]>([])
	const [longDescription, setLongDescription] = useState<OutputData | null>()
	const [isLoaded, setLoaded] = useState(false)
	const [isSaving, setSaving] = useState(false)

	useEffect(() => {
		if (course.data) {
			setInformationData({
				title: course.data.title,
				shortDescription: course.data.shortDescription,
				chapterDripInterval: course.data.chapterDripInterval,
				issueCertificates: course.data.issueCertificates,
				commentsEnabled: course.data.commentsEnabled,
				autoApproveComments: course.data.autoApproveComments,
				tags: course.data.tags.map(tag => tag.id),
			})
			setLongDescription(course.data.longDescription !== null ? JSON.parse(course.data.longDescription) : null)
			setPrice(course.data.price?.amount.toString() ?? "")
			setMembershipIds(course.data.membershipIds)
			setLoaded(true)
		}
	}, [course.data, setPrice])

	const initialLongDescription = useMemo(
		() => (course.data?.longDescription != null ? JSON.parse(course.data.longDescription) : null),
		[course.data?.longDescription],
	)

	const setCourseThumbnail = useCallback(
		async (thumbnailFile: File | null) => {
			if (thumbnailFile !== null) {
				await trpc.course.setThumbnail.mutate({ courseId, dataUrl: await getDataUrlByFile(thumbnailFile) })
			} else {
				await trpc.course.deleteThumbnail.mutate(courseId)
			}
		},
		[courseId],
	)

	if (course.data === null) return <Navigate to={routes.course.index()} />

	const handleSave = async () => {
		if (!course.data || informationData === undefined) return
		if (!platform.paymentGateway?.isEnabled && priceValue !== null) {
			alert.show("Error", "You need to enable payments if you want to have paid courses.")
			return
		}
		if (priceValue !== null && priceValue < 1) {
			alert.show(
				"Error",
				`The price must be at least ${toPriceString({ amount: 1, currency: platform.currency })}.`,
			)
			return
		}

		setSaving(true)
		try {
			await trpc.course.update.mutate({
				id: course.data.id,
				data: {
					title: informationData.title,
					shortDescription: informationData.shortDescription,
					price: priceValue,
					membershipIds,
					commentsEnabled: informationData.commentsEnabled,
					autoApproveComments: informationData.autoApproveComments,
					longDescription: longDescription !== null ? JSON.stringify(longDescription) : null,
					chapterDripInterval: informationData.chapterDripInterval,
					tags: informationData.tags,
				},
			})
			await course.mutate()
			navigate(routes.course.index())
		} catch (error) {
			console.error(error)
			setSaving(false)
			await alert.show("Error", "Failed to save course.")
		}
	}

	return (
		<Layout>
			<PageBreadcrumb title="Settings" path={[{ title: "Courses", link: routes.course.index() }]} />
			{course.error || tags.error ? (
				<p>Failed to load course.</p>
			) : !isLoaded ||
			  !course.data ||
			  !tags.data ||
			  !informationData ||
			  longDescription === undefined ||
			  isSaving ? (
				<PartialLoadingPage />
			) : (
				<ControlledTabs
					tabs={[
						{
							title: "Course information",
							content: (
								<CourseInformation
									data={informationData}
									setData={setInformationData}
									tags={tags.data}
									reloadTags={tags.mutate}
									thumbnailFilePath={course.data.thumbnailFilePath}
									onThumbnailChange={setCourseThumbnail}
								/>
							),
						},
						{
							title: "Long description",
							content: (
								<CourseLongDescriptionEditor
									initialValue={initialLongDescription}
									setValue={setLongDescription}
								/>
							),
						},
						{
							title: "Price & availability",
							content: (
								<>
									<Title>Course access</Title>
									<Description>Select how people access your course, and set a price.</Description>
									<ProductPricing
										type="course"
										price={price}
										setPrice={setPrice}
										membershipIds={membershipIds}
										setMembershipIds={setMembershipIds}
									/>
								</>
							),
						},
						{
							title: "Testers",
							content: <Testers courseId={course.data.id} />,
						},
					]}
				/>
			)}
			<SaveButton onClick={handleSave}>Save</SaveButton>
		</Layout>
	)
}

const SaveButton = styled(Button).attrs({ variant: "primary" })`
	align-self: flex-end;
	margin-top: 16px;
`

export default CourseSettingsPage
