import styled, { css } from "styled-components"

import Button from "~/components/Button"
import { primaryColor, primaryLightColor } from "~/utilities/styles"

export type Period =
	| "today"
	| "this-week"
	| "one-week"
	| "this-month"
	| "one-month"
	| "this-year"
	| "one-year"
	| "all-time"

export const periodOptions: { id: Period; label: string }[] = [
	{ id: "today", label: "Today" },
	{ id: "this-week", label: "This week" },
	{ id: "one-week", label: "One week" },
	{ id: "this-month", label: "This month" },
	{ id: "one-month", label: "One month" },
	{ id: "this-year", label: "This year" },
	{ id: "one-year", label: "One year" },
	{ id: "all-time", label: "All time" },
]

const PeriodSelector: React.FC<{ selectedValue: Period; onChange: (newValue: Period) => void }> = ({
	selectedValue,
	onChange,
}) => (
	<Container>
		{periodOptions.map(({ id, label }, index) => (
			<Option key={index} isActive={id === selectedValue} onClick={() => onChange(id)}>
				{label}
			</Option>
		))}
	</Container>
)

const Container = styled.div`
	display: flex;
	background-color: ${primaryLightColor};
	padding: 6px;
	border-radius: 8px;
`

const Option = styled(Button)<{ isActive: boolean }>`
	font-weight: 600;
	text-decoration: none;
	border-radius: 6px;
	padding: 10px 14px;
	transition: 0.2s;
	display: flex;
	align-items: center;
	gap: 8px;

	${props =>
		props.isActive &&
		css`
			color: ${primaryColor};
			background-color: white;
			box-shadow:
				0 1px 3px rgba(16, 24, 40, 0.1),
				0 1px 2px rgba(16, 24, 40, 0.06);
		`}
`

export default PeriodSelector
