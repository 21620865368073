import { type ReactNode, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled, { css } from "styled-components"
import tinycolor from "tinycolor2"

import Button from "~/components/Button"
import { primaryColor, primaryLightColor } from "~/utilities/styles"

type Variant = "primary" | "secondary"

interface BaseTabsProps {
	tabs: {
		title: string
		sticker?: string
		content: ReactNode
	}[]
	variant?: Variant
	centerBar?: boolean
	className?: string
	asideComponent?: ReactNode
}

interface TabsProps extends BaseTabsProps {
	selectedTabIndex: number
	setSelectedTabIndex: (value: number) => void
}

interface RouteTabsProps {
	tabs: {
		title: string
		count?: number
		route: string
		content: ReactNode
	}[]
	className?: string
}

const Tabs: React.FC<TabsProps> = ({
	selectedTabIndex,
	setSelectedTabIndex,
	tabs,
	variant = "primary",
	centerBar = false,
	className,
	asideComponent,
}) => {
	return (
		<Container className={className}>
			<TabBar $variant={variant} $centerBar={centerBar}>
				{tabs.map(({ title, sticker }, index) => {
					const isActive = selectedTabIndex === index
					return (
						<Tab
							key={index}
							$variant={variant}
							$isActive={isActive}
							onClick={() => setSelectedTabIndex(index)}
						>
							{title}
							{sticker !== undefined && (
								<TabSticker $isActive={isActive} $variant={variant}>
									{sticker}
								</TabSticker>
							)}
						</Tab>
					)
				})}
				{asideComponent && (
					<>
						<TabsSpacer />
						{asideComponent}
					</>
				)}
			</TabBar>
			{tabs.map(({ content }, index) => (
				<TabContent key={index} $isActive={selectedTabIndex === index}>
					{content}
				</TabContent>
			))}
		</Container>
	)
}

export const ControlledTabs: React.FC<BaseTabsProps> = props => {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0)

	return <Tabs selectedTabIndex={selectedTabIndex} setSelectedTabIndex={setSelectedTabIndex} {...props} />
}

export const RouteTabs: React.FC<RouteTabsProps> = ({ tabs, ...props }) => {
	const location = useLocation()
	const navigate = useNavigate()

	const routeIndex = tabs.findIndex(x => x.route === location.pathname)

	const setSelectedTabIndex = (index: number) => {
		navigate(tabs[index].route)
	}

	return (
		<Tabs
			tabs={tabs}
			selectedTabIndex={routeIndex >= 0 ? routeIndex : 0}
			setSelectedTabIndex={setSelectedTabIndex}
			{...props}
		/>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
`

const TabBar = styled.div<{ $variant: Variant; $centerBar: boolean }>`
	background-color: ${props => (props.$variant === "primary" ? primaryLightColor : "#F9FAFB")};
	padding: 6px;
	border-radius: 8px;
	display: flex;
	align-items: center;

	${props =>
		props.$centerBar &&
		css`
			align-self: center;
		`}

	${props =>
		props.$variant === "secondary" &&
		css`
			gap: 8px;
			border: 1px solid #f2f4f7;
		`}
`

const Tab = styled(Button)<{ $variant: Variant; $isActive: boolean }>`
	font-weight: 600;
	text-decoration: none;
	border-radius: 6px;
	padding: 10px 14px;
	transition: 0.2s;
	display: flex;
	align-items: center;
	gap: 8px;

	${props =>
		props.$variant === "secondary" &&
		css`
			color: #667085;
			background-color: white;
		`}

	${props =>
		props.$isActive &&
		(props.$variant === "primary"
			? css`
					box-shadow:
						0 1px 3px rgba(16, 24, 40, 0.1),
						0 1px 2px rgba(16, 24, 40, 0.06);
					color: ${primaryColor};
					background-color: white;
				`
			: props.$variant === "secondary"
				? css`
						box-shadow:
							0 1px 3px rgba(16, 24, 40, 0.1),
							0 1px 2px rgba(16, 24, 40, 0.06);
						color: white;
						background-color: ${primaryColor};
					`
				: "")}
`

const TabSticker = styled.p<{ $isActive: boolean; $variant: Variant }>`
	font-weight: 600;
	padding: 2px 10px;
	border-radius: 16px;
	transition: 0.2s;

	${props =>
		props.$variant === "primary"
			? css`
					color: black;
					background-color: #efefef;
				`
			: props.$variant === "secondary"
				? css`
						color: #344054;
						background-color: #f2f4f7;
					`
				: ""}

	${props =>
		props.$isActive &&
		(props.$variant === "primary"
			? css`
					color: ${primaryColor};
					background-color: ${tinycolor(primaryColor).setAlpha(0.17).toString()};
				`
			: props.$variant === "secondary"
				? css`
						color: ${tinycolor("white").darken(10).toString()};
						background-color: ${tinycolor(primaryColor).lighten(7).toString()};
					`
				: "")}
`

const TabsSpacer = styled.div`
	flex: 1 0 0;
`

const TabContent = styled.div<{ $isActive?: boolean }>`
	${props =>
		!props.$isActive &&
		css`
			display: none;
		`}
`

export default Tabs
