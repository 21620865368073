import { type FC, useState, type Dispatch, type SetStateAction } from "react"
import styled from "styled-components"

import { type CommunityPrivilege, communityPrivileges } from "@forento/shared/models/community"
import { type Course } from "@forento/shared/models/course"
import { type Downloadable } from "@forento/shared/models/downloadable"
import { type ThinPrivateSession } from "@forento/shared/models/privateSession"
import { type Video } from "@forento/shared/models/video"
import { toggleArrayItem } from "@forento/shared/utilities/array"
import { getCommunityPrivilegeDisplayName } from "@forento/shared/utilities/community"
import { getDataUrlByFile } from "@forento/shared/utilities/file"

import Button, { SubmitButton } from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import Dropdown from "~/components/Dropdown"
import FileChooser from "~/components/FileChooser"
import InputField, { useRichTextArea } from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import { DefaultModal, ModalButtons, ModalInputs, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import { usePlatform, useUser } from "~/contexts/UserContext"
import trpc, { swr } from "~/utilities/trpc"

type Data = {
	title: string
	shortDescription: string
	thumbnail: File | null
	communityPrivilege: CommunityPrivilege | "none"
	courseIds: number[]
	videoIds: number[]
	downloadableIds: number[]
	monthlyPricing: { isEnabled: boolean; value: number }
	annuallyPricing: { isEnabled: boolean; value: number }
}

type Props = { isOpen: boolean; onClose(): void; onSubmitted(): Promise<unknown> }
const CreateModal: FC<Props> = ({ isOpen, onClose, onSubmitted }) => {
	const alert = useAlert()
	const platform = useUser().user!.platform!

	const isDisabled = !isOpen

	const courses = swr.course.list.useSWR(undefined, { isDisabled })
	const videos = swr.video.list.useSWR(undefined, { isDisabled })
	const downloadables = swr.downloadable.list.useSWR(undefined, { isDisabled })
	const sessions = swr.privateSession.list.useSWR(undefined, { isDisabled })

	const [data, setData] = useState<Data>({
		title: "",
		shortDescription: "",
		thumbnail: null,
		communityPrivilege: "none",
		courseIds: [],
		videoIds: [],
		downloadableIds: [],
		monthlyPricing: { isEnabled: false, value: 0 },
		annuallyPricing: { isEnabled: false, value: 0 },
	})
	const longDescription = useRichTextArea({ label: "Description" })
	const [isSubmitting, setSubmitting] = useState(false)

	async function handleSubmit() {
		if (data.title.length === 0) {
			alert.show("Error", "Please fill in the title.")
			return
		}
		if (!data.monthlyPricing.isEnabled && !data.annuallyPricing.isEnabled) {
			alert.show("Error", "You need at least one pricing plan.")
			return
		}
		if (platform.paymentGateway === null) {
			alert.show("Error", "You need to enable payments if you want to have memberships.")
			return
		}
		if (
			(data.monthlyPricing.isEnabled && data.monthlyPricing.value <= 0) ||
			(data.annuallyPricing.isEnabled && data.annuallyPricing.value <= 0)
		) {
			alert.show("Error", "You can't set an active pricing plan to be free.")
			return
		}
		if (
			data.monthlyPricing.isEnabled &&
			data.annuallyPricing.isEnabled &&
			data.annuallyPricing.value > data.monthlyPricing.value * 12
		) {
			const response = confirm(
				"Your annual plan is more expensive than your monthly plan. Are you sure this is intentional?",
			)
			if (response === false) return
		}

		setSubmitting(true)

		try {
			const thumbnailDataUrl = data.thumbnail ? await getDataUrlByFile(data.thumbnail) : null
			await trpc.membership.create.mutate({
				title: data.title,
				shortDescription: data.shortDescription,
				longDescription: longDescription.exportEditorState(),
				thumbnailDataUrl,
				communityPrivilege: data.communityPrivilege !== "none" ? data.communityPrivilege : null,
				content: { courses: data.courseIds, videos: data.videoIds, downloadables: data.downloadableIds },
				price: {
					month: data.monthlyPricing.isEnabled ? data.monthlyPricing.value : null,
					year: data.annuallyPricing.isEnabled ? data.annuallyPricing.value : null,
				},
			})

			await onSubmitted()
			onClose()
			setData({
				title: "",
				shortDescription: "",
				thumbnail: null,
				communityPrivilege: "none",
				courseIds: [],
				videoIds: [],
				downloadableIds: [],
				monthlyPricing: { isEnabled: false, value: 0 },
				annuallyPricing: { isEnabled: false, value: 0 },
			})
			longDescription.set(null)
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={isOpen} onSubmit={handleSubmit}>
			{courses.error || videos.error || downloadables.error || sessions.error ? (
				<p>Failed to load membership.</p>
			) : !courses.data || !videos.data || !downloadables.data || !sessions.data ? (
				<PartialLoadingPage />
			) : (
				<Content
					data={data}
					setData={setData}
					longDescription={longDescription}
					paidContent={{
						courses: courses.data.filter(x => x.price),
						videos: videos.data.filter(x => x.price),
						downloadables: downloadables.data.filter(x => x.price),
						sessions: sessions.data.filter(x => x.price),
					}}
					isSubmitting={isSubmitting}
					onClose={onClose}
				/>
			)}
		</DefaultModal>
	)
}

export default CreateModal

type ContentProps = {
	data: Data
	setData: Dispatch<SetStateAction<Data>>
	longDescription: ReturnType<typeof useRichTextArea>
	paidContent: {
		courses: Course[]
		videos: Video[]
		downloadables: Downloadable[]
		sessions: ThinPrivateSession[]
	}
	isSubmitting: boolean
	onClose(): void
}
const Content: FC<ContentProps> = ({ data, setData, longDescription, paidContent, isSubmitting, onClose }) => {
	const platform = usePlatform()!

	function setField<T>(field: keyof Data): (value: T) => void {
		return value => setData(current => ({ ...current, [field]: value }))
	}

	return (
		<>
			<ModalTitle>Create a new tier</ModalTitle>
			<ModalInputs>
				<InputField label="Name" value={data.title} onChange={setField("title")} />
				<InputField
					label="Short description"
					value={data.shortDescription}
					onChange={setField("shortDescription")}
				/>
				{longDescription.element}
				<FileChooser type="image" value={data.thumbnail} onChange={setField("thumbnail")} />
				{platform.community && (
					<div>
						<InputLabel>Community privileges</InputLabel>
						<Dropdown
							items={[
								{ id: "none", title: "None" },
								...communityPrivileges.map(privilege => ({
									id: privilege,
									title: getCommunityPrivilegeDisplayName(privilege),
								})),
							]}
							selectedItemId={data.communityPrivilege}
							onChange={value => setField("communityPrivilege")(value as CommunityPrivilege & "none")}
						/>
					</div>
				)}
				{(["course", "video", "downloadable"] as const).map(type => {
					const content = paidContent[`${type}s`]
					if (content.length === 0) return null

					const selectedIds = data[`${type}Ids`]
					return (
						<div key={type}>
							<InputLabel>Included {type}s</InputLabel>
							{content.map(item => (
								<StyledCheckbox
									key={item.id}
									label={item.title}
									isChecked={selectedIds.includes(item.id)}
									onChange={() => setField(`${type}Ids`)(toggleArrayItem(selectedIds, item.id))}
								/>
							))}
						</div>
					)
				})}
				<div>
					<InputLabel>Pricing</InputLabel>
					<Plans>
						<Plan>
							<StyledCheckbox
								label="Offer monthly plan"
								isChecked={data.monthlyPricing.isEnabled}
								onChange={value =>
									setField("monthlyPricing")({ ...data.monthlyPricing, isEnabled: value })
								}
							/>
							<InputField
								label={`Price per month (${platform.currency})`}
								inputType="number"
								value={data.monthlyPricing.value.toString()}
								onChange={value =>
									setField("monthlyPricing")({ ...data.monthlyPricing, value: Number(value) })
								}
								disabled={!data.monthlyPricing.isEnabled}
							/>
						</Plan>
						<Plan>
							<StyledCheckbox
								label="Offer annual plan"
								isChecked={data.annuallyPricing.isEnabled}
								onChange={value =>
									setField("annuallyPricing")({ ...data.annuallyPricing, isEnabled: value })
								}
							/>
							<InputField
								label={`Price per year (${platform.currency})`}
								inputType="number"
								value={data.annuallyPricing.value.toString()}
								onChange={value =>
									setField("annuallyPricing")({ ...data.annuallyPricing, value: Number(value) })
								}
								disabled={!data.annuallyPricing.isEnabled}
							/>
						</Plan>
					</Plans>
				</div>
			</ModalInputs>
			<ModalButtons>
				<Button variant="secondary" onClick={onClose}>
					Cancel
				</Button>
				<SubmitButton variant="primary" isLoading={isSubmitting}>
					Create tier
				</SubmitButton>
			</ModalButtons>
		</>
	)
}

const Plans = styled.div`
	display: flex;
	gap: 16px;
`

const Plan = styled.div`
	flex: 1 0 0;
	min-width: 0;
`

const StyledCheckbox = styled(Checkbox)`
	margin-right: 8px;
	margin-bottom: 8px;
`
