import type React from "react"
import styled from "styled-components"

import { type CourseInputsPage } from "@forento/shared/models/course"

const PageInput: React.FC<{ page: CourseInputsPage; chapterTitle: string }> = ({ page, chapterTitle }) => (
	<Container>
		<Title>
			{chapterTitle} / {page.title}
		</Title>
		{page.users.map(user => (
			<Content>
				<Inputs>
					{user.inputs.map(input => (
						<Input key={input.id}>{input.text}</Input>
					))}
				</Inputs>
			</Content>
		))}
	</Container>
)

const Container = styled.div`
	display: flex;
	flex-direction: column;
`

const Title = styled.div`
	font-size: 14px;
	width: 256px;
	background-color: #f9f5ff;
	border: 1px solid #9b89d0;
	border-radius: 8px;
	padding: 8px;
`

const Content = styled.div`
	display: flex;
	gap: 12px;
	padding-top: 12px;
`

const Inputs = styled.div`
	min-width: 256px;
	display: flex;
	flex-direction: column;
	gap: 12px;
`

const Input = styled.div`
	font-size: 14px;
	border: 1px solid #d1d5dc;
	border-radius: 8px;
	padding: 8px;
`

export default PageInput
