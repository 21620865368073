import Layout, { PageBreadcrumb } from "~/components/Layout"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"

import Dashboard from "./Dashboard"
import Setup from "./Setup"

const ReferralPage: React.FC = () => {
	const platform = useUser().user!.platform!

	return (
		<Layout>
			<PageBreadcrumb path={[{ title: "Settings", link: routes.settings.index() }]} title="Referrals" />
			{platform.isReferralEnabled ? <Dashboard /> : <Setup />}
		</Layout>
	)
}

export default ReferralPage
