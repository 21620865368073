import { type FC } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import { OpenExternalIcon, SalesIcon } from "~/components/Icon"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import trpc from "~/utilities/trpc"

const PayPalMerchant: FC<{ reload(): Promise<unknown> }> = ({ reload }) => {
	const user = useUser()
	const platform = user.user!.platform!
	const alert = useAlert()
	if (platform.paymentGateway?.type !== "paypal") return null

	const handleDisconnect = async () => {
		const dialog = await alert.confirm(
			"Disconnect account",
			"Disconnecting your PayPal account will prevent you from offering PayPal services and products on your website. Do you wish to continue?",
		)
		if (!dialog.result) return

		await trpc.payment.paypal.disconnect.mutate()
		await user.reload()
		await reload()
		dialog.close()
	}

	return (
		<Container>
			<Icon />
			<Label>
				PayPal merchant ID
				<br />
				{platform.paymentGateway.id}
			</Label>
			<Button newTab variant="primary" onClick="https://www.paypal.com/signin">
				<StyledOpenExternalIcon /> PayPal Dashboard
			</Button>
			<Button variant="primary-danger" onClick={handleDisconnect}>
				Disconnect account
			</Button>
		</Container>
	)
}

const Container = styled.div`
	flex: 0 0 auto;
	background-color: #f4f4f4;
	padding: 16px;
	display: flex;
	align-items: center;
	gap: 16px;
`

const Icon = styled(SalesIcon)`
	width: 32px;
	height: 32px;
`

const Label = styled.p`
	font-size: 16px;
	display: flex;
	align-items: center;
`

const StyledOpenExternalIcon = styled(OpenExternalIcon)`
	width: 18px;
	height: 18px;
`

export default PayPalMerchant
