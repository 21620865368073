import dayjs from "dayjs"
import { Upload } from "tus-js-client"

import trpc from "./trpc"

interface Video {
	bunnyVideoId: string
	title: string
}

export type VideoUploadProgress = { status: "uploading"; progress: number } | { status: "failed" | "completed" }

export async function startVideoUpload(
	file: File,
	video: Video,
	onProgress: (progress: VideoUploadProgress) => void,
): Promise<void> {
	const { libraryId, signature } = await trpc.video.initializeUpload.query({ bunnyVideoId: video.bunnyVideoId })

	const upload = new Upload(file, {
		endpoint: "https://video.bunnycdn.com/tusupload",
		retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
		headers: {
			AuthorizationSignature: signature.value,
			AuthorizationExpire: dayjs(signature.expirationDate).unix().toString(),
			VideoId: video.bunnyVideoId,
			LibraryId: libraryId,
		},
		metadata: {
			filetype: file.type,
			title: video.title,
		},
		onError: error => {
			console.error(error)
			onProgress({ status: "failed" })
		},
		onProgress: (bytesUploaded, bytesTotal) => {
			onProgress({ status: "uploading", progress: bytesUploaded / bytesTotal })
		},
		onSuccess: () => {
			onProgress({ status: "completed" })
		},
	})

	const previousUploads = await upload.findPreviousUploads()
	if (previousUploads.length > 0) {
		upload.resumeFromPreviousUpload(previousUploads[0])
	}
	upload.start()
}
