import styled from "styled-components"

const InputLabel = styled.label`
	display: block;
	font-weight: 600;
	font-size: 14px;
	color: #444444;
	margin-bottom: 6px;
`

export default InputLabel
