import { type FC, useLayoutEffect, useState } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import { DefaultModal, ModalButtons, ModalContentPadding, ModalText, ModalTitle } from "~/components/Modal"
import { usePlatform, useUser } from "~/contexts/UserContext"
import trpc from "~/utilities/trpc"

import oldImage from "~/assets/themes/legacy.png"
import newImage from "~/assets/themes/modern.png"

const NewThemeModal: FC = () => {
	const user = useUser()
	const platform = usePlatform()

	const [isOpen, setOpen] = useState(!platform?.theme.hasChosen)
	const [submittingValue, setSubmittingValue] = useState<boolean>()

	useLayoutEffect(() => {
		if (platform === null) return
		if (platform.theme.hasChosen) {
			if (isOpen) setOpen(false)
		} else {
			if (!isOpen) setOpen(true)
		}
	}, [platform?.theme, platform, isOpen])

	async function handleSubmit(switchTheme: boolean) {
		setSubmittingValue(switchTheme)
		await trpc.platform.setTheme.mutate(switchTheme ? 2 : 1)
		await user.reload()
	}

	return (
		<DefaultModal width={1000} isOpen={isOpen}>
			<ModalTitle>New platform theme! 🎉</ModalTitle>
			<ModalText>
				We've launched a new platform theme, and we really like it! Would you like to switch to it? You can
				switch back at any time in your settings!
			</ModalText>
			<Themes>
				<Theme>
					<ThemeLabel>Current theme</ThemeLabel>
					<ThemeImage alt="Current theme" src={oldImage} />
				</Theme>
				<Theme>
					<ThemeLabel>New theme</ThemeLabel>
					<ThemeImage alt="New theme" src={newImage} />
				</Theme>
			</Themes>
			<ModalButtons>
				<Button
					variant="secondary"
					onClick={() => handleSubmit(false)}
					isDisabled={submittingValue !== undefined}
					isLoading={submittingValue === false}
				>
					No thanks
				</Button>
				<Button
					variant="primary"
					onClick={() => handleSubmit(true)}
					isDisabled={submittingValue !== undefined}
					isLoading={submittingValue === true}
				>
					Yes, switch! 🚀
				</Button>
			</ModalButtons>
		</DefaultModal>
	)
}

const Themes = styled(ModalContentPadding)`
	display: flex;
	gap: 16px;
`

const Theme = styled.div`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	gap: 8px;
`

const ThemeLabel = styled.div`
	font-size: 14px;
	color: #757575;
	text-align: center;
`

const ThemeImage = styled.img`
	width: 100%;
	border-radius: 8px;
`

export default NewThemeModal
