import { type FC, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"
import styled from "styled-components"

import { type PayPayOnboardingError } from "@forento/shared/models/payment"

import Button from "~/components/Button"
import Layout, { PageTitle } from "~/components/Layout"
import LoadingIndicator from "~/components/LoadingIndicator"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

const CompletingPayPalPage: FC = () => {
	const user = useUser()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const [error, setError] = useState<PayPayOnboardingError>()

	useEffect(() => {
		const paypalMerchantId = searchParams.get("merchantIdInPayPal")
		const controller = new AbortController()
		async function complete() {
			if (paypalMerchantId === null || paypalMerchantId.trim().length === 0) return
			setError(undefined)
			const response = await trpc.payment.paypal.completeOnboarding.mutate(
				{ paypalMerchantId },
				{ signal: controller.signal },
			)
			if (controller.signal.aborted) return
			if (response.status !== "success") {
				setError(response.status)
				return
			}
			await user.reload()
			if (controller.signal.aborted) return
			navigate(routes.payments.index())
		}
		complete()
		return () => {
			controller.abort()
		}
	}, [navigate, searchParams, user])

	return (
		<Layout>
			<PageTitle>Payments</PageTitle>
			{error ? (
				<ErrorContent>
					{error === "not-onboarded"
						? "You currently cannot receive payments due to restriction on your PayPal account. Please reach out to PayPal Customer Support or connect to https://www.paypal.com for more information."
						: error === "unconfirmed-email"
							? "PayPal email is not verified. Please check your inbox."
							: null}
					<Button variant="primary" onClick={routes.payments.index()}>
						Payments
					</Button>
				</ErrorContent>
			) : (
				<LoadingContent>
					<LoadingIndicator />
					Completing PayPal connection...
				</LoadingContent>
			)}
		</Layout>
	)
}

const ErrorContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`

const LoadingContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
`

export default CompletingPayPalPage
