import { type FC, useState } from "react"
import styled, { css } from "styled-components"

import PartialLoadingPage from "~/components/PartialLoadingPage"
import { swr } from "~/utilities/trpc"

import CreatePost from "./CreatePost"
import Post from "./Post"
import PostDetail from "./PostDetail"

const CommunityPosts: FC = () => {
	const { data: posts, error, mutate } = swr.community.listPosts.useSWR()

	const [selectedPostId, setSelectedPostId] = useState<number>()

	if (error) return <p>Failed to load posts.</p>
	if (posts === undefined) return <PartialLoadingPage />

	return (
		<Container $includeCreatePost>
			<CreatePost onPostCreated={mutate} />
			<Posts>
				{posts
					.toSorted((a, b) => b.createDate.getTime() - a.createDate.getTime())
					.map(post => (
						<Post
							key={post.id}
							post={post}
							isSelected={selectedPostId === post.id}
							onSelect={() => setSelectedPostId(post.id)}
							onChange={mutate}
						/>
					))}
			</Posts>
			<PostDetail postId={selectedPostId ?? null} />
		</Container>
	)
}

export const ProfileCommunityPosts: FC<{ profileId: number; gridArea: string }> = ({ profileId, gridArea }) => {
	const { data: posts, error, mutate } = swr.community.listPostsByProfile.useSWR(profileId)

	const [selectedPostId, setSelectedPostId] = useState<number>()

	if (error) return <p>Failed to load posts.</p>
	if (posts === undefined) return <PartialLoadingPage />

	return (
		<Container $gridArea={gridArea}>
			<Posts>
				{posts
					.toSorted((a, b) => b.createDate.getTime() - a.createDate.getTime())
					.map(post => (
						<Post
							key={post.id}
							post={post}
							isSelected={selectedPostId === post.id}
							onSelect={() => setSelectedPostId(post.id)}
							onChange={mutate}
						/>
					))}
			</Posts>
			<PostDetail postId={selectedPostId ?? null} />
		</Container>
	)
}

const Container = styled.div<{ $gridArea?: string; $includeCreatePost?: boolean }>`
	${props =>
		props.$gridArea !== undefined &&
		css`
			grid-area: ${props.$gridArea};
		`}
	display: grid;
	${props =>
		props.$includeCreatePost
			? css`
					grid-template-areas: "create-post post-detail" "posts post-detail";
					grid-template-rows: auto 1fr;
				`
			: css`
					grid-template-areas: "posts post-detail";
					grid-template-rows: 1fr;
				`}
	grid-template-columns: 1fr 400px;
	gap: 24px;
`

const Posts = styled.div`
	grid-area: posts;
	flex: 2 0 0;
	display: flex;
	flex-direction: column;
	gap: 20px;
`

export default CommunityPosts
