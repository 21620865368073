import { type FC } from "react"
import styled, { css } from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"

import { DeleteIcon, EditIcon } from "~/components/Icon"
import { useAlert } from "~/contexts/AlertContext"
import { dangerColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

import Button from "./Button"
import { DefaultModal, ModalButtons, ModalContentPadding, ModalTitle } from "./Modal"
import Table from "./Table"

interface Props {
	type: "course" | "video"
	isOpen: boolean
	tags: { id: number; label: string }[]
	onClose(): void
	onChange(): void
}

const ManageTagsModal: FC<Props> = ({ type, isOpen, tags, onClose, onChange }) => {
	const alert = useAlert()

	const handleCreate = async () => {
		const dialog = await alert.input("Create tag", "Enter a label for the new tag.", "Tag label")
		if (dialog.result === null) return

		switch (type) {
			case "course":
				await trpc.course.createTag.mutate(dialog.result)
				break
			case "video":
				await trpc.video.createTag.mutate({ label: dialog.result })
				break
		}

		dialog.close()

		onChange()
	}

	const handleRename = async (tag: { id: number; label: string }) => {
		const dialog = await alert.input("Rename tag", "Enter a new label for this tag.", "Tag label", tag.label)
		if (dialog.result === null) return

		switch (type) {
			case "course":
				await trpc.course.updateTag.mutate({ id: tag.id, label: dialog.result })
				break
			case "video":
				await trpc.video.updateTag.mutate({ tagId: tag.id, label: dialog.result })
				break
		}
		dialog.close()

		onChange()
	}

	const handleDelete = async (tag: { id: number; label: string }) => {
		const dialog = await alert.confirm(
			"Delete tag",
			`Are you sure you want to delete the tag **${tag.label}**? The tag will also be removed from the courses.`,
		)
		if (!dialog.result) return

		switch (type) {
			case "course":
				await trpc.course.deleteTag.mutate(tag.id)
				break
			case "video":
				await trpc.video.deleteTag.mutate({ tagId: tag.id })
				break
		}
		dialog.close()

		onChange()
	}

	return (
		<DefaultModal isOpen={isOpen}>
			<ModalTitle>Manage tags</ModalTitle>
			<ModalContentPadding>
				<Table
					columns={["Label", "Actions"]}
					data={tags.map(tag => [
						tag.label,
						<Actions>
							<Tooltip tooltip="Rename">
								<ActionButton onClick={() => handleRename(tag)}>
									<EditIcon />
								</ActionButton>
							</Tooltip>
							<Tooltip tooltip="Delete">
								<ActionButton isDanger onClick={() => handleDelete(tag)}>
									<DeleteIcon />
								</ActionButton>
							</Tooltip>
						</Actions>,
					])}
				/>
			</ModalContentPadding>
			<ModalButtons>
				<Button variant="secondary" onClick={handleCreate}>
					Create new tag
				</Button>
				<Button variant="primary" onClick={onClose}>
					Close
				</Button>
			</ModalButtons>
		</DefaultModal>
	)
}

const Actions = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

const ActionButton = styled(Button)<{ isDanger?: boolean }>`
	width: 24px;
	height: 24px;

	${props =>
		props.isDanger &&
		css`
			color: ${dangerColor};
		`}
`

export default ManageTagsModal
