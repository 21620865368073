import { type FC, useState } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import { SourceIcon } from "~/components/Icon"
import { DefaultModal, ModalButtons, ModalTitle } from "~/components/Modal"

const maxSources = 8

const Sources: FC<{ sources: { label: string; visitsShare: number }[] }> = ({ sources }) => {
	const [isShowAllModalOpen, setShowAllModalOpen] = useState(false)

	return (
		<>
			<DefaultModal isOpen={isShowAllModalOpen}>
				<ModalTitle>Sources</ModalTitle>
				<ModalList>
					{sources.map(source => (
						<Source key={source.label}>
							<SourceTitle>{source.label}</SourceTitle>
							<SourceValue>{(source.visitsShare * 100).toFixed(2)}%</SourceValue>
						</Source>
					))}
				</ModalList>
				<ModalButtons>
					<Button variant="primary" onClick={() => setShowAllModalOpen(false)}>
						Close
					</Button>
				</ModalButtons>
			</DefaultModal>
			<Container>
				<Row>
					<Icon as={SourceIcon} />
					<Title>Sources</Title>
				</Row>
				{sources.length > 0 ? (
					<List>
						{sources.slice(0, maxSources).map(source => (
							<Source key={source.label}>
								<SourceTitle>{source.label}</SourceTitle>
								<SourceValue>{(source.visitsShare * 100).toFixed(2)}%</SourceValue>
							</Source>
						))}
					</List>
				) : (
					<NoSourcesText>No recorded sources</NoSourcesText>
				)}
				{sources.length > maxSources && (
					<StyledButton onClick={() => setShowAllModalOpen(true)}>Show all</StyledButton>
				)}
			</Container>
		</>
	)
}

const Container = styled.div`
	grid-area: sources;
	background-color: #f4f4f4;
	padding: 32px;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	gap: 32px;
`

const Row = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

const Icon = styled.div`
	width: 32px;
	height: 32px;
`

const Title = styled.h2`
	font-size: 16px;
	color: black;
`

const List = styled.ol`
	list-style: none;
`

const ModalList = styled(List)`
	max-height: 500px;
	overflow-y: auto;
	margin: 0 32px 20px;
`

const Source = styled.li`
	padding: 10px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&:not(:last-child) {
		border-bottom: 1px solid #e8e8e8;
	}
`

const SourceTitle = styled.p`
	font-size: 16px;
	color: black;
`

const SourceValue = styled.p`
	font-size: 16px;
	font-weight: 600;
	color: black;
`

const StyledButton = styled(Button).attrs({ variant: "primary" })`
	align-self: center;
`

const NoSourcesText = styled.p`
	font-size: 14px;
`

export default Sources
