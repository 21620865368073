import { useParams } from "react-router"
import styled from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

const CourseInputsPage: React.FC = () => {
	const courseId = parseNumber(useParams().courseId) ?? -1

	const { data: inputs, error } = swr.course.listInputs.useSWR(courseId)

	return (
		<Layout>
			<PageBreadcrumb title="Inputs" path={[{ title: "Courses", link: routes.course.index() }]} />
			{error ? (
				<p>Failed to load inputs.</p>
			) : !inputs ? (
				<PartialLoadingPage />
			) : (
				<Pages>
					{inputs.chapters.map(chapter =>
						chapter.pages.map(page => (
							<Page.Container key={page.id}>
								<Page.Title>
									{chapter.title} / {page.title}
								</Page.Title>
								{page.users.map(user => (
									<Page.Content>
										<Page.Path />
										<Page.UserContent>
											<Page.User>
												{user.firstName} {user.lastName}
											</Page.User>
											<Page.Inputs>
												{user.inputs.map(input => (
													<Page.Input key={input.id}>{input.text}</Page.Input>
												))}
											</Page.Inputs>
										</Page.UserContent>
									</Page.Content>
								))}
							</Page.Container>
						)),
					)}
				</Pages>
			)}
		</Layout>
	)
}

const Pages = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`

const Page = {
	Container: styled.div`
		display: flex;
		flex-direction: column;
	`,
	Title: styled.div`
		font-size: 14px;
		width: 256px;
		background-color: #f9f5ff;
		border: 1px solid #9b89d0;
		border-radius: 8px;
		padding: 8px;
	`,
	Content: styled.div`
		display: flex;
		gap: 12px;
		padding-top: 12px;

		&:not(:last-child) {
			border-left: 2px dashed #c7c4bd;
		}
	`,
	Path: styled.div`
		flex: 0 0 ${256 + 12}px;
		height: 20px;
		border-left: 2px dashed #c7c4bd;
		border-bottom: 2px dashed #c7c4bd;
		border-radius: 0 0 0 8px;
		margin-left: -2px;
	`,
	UserContent: styled.div`
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: 12px;
	`,
	User: styled.div`
		font-size: 14px;
		background-color: #fbfaf8;
		border: 1px solid #eeeeed;
		border-radius: 8px;
		padding: 8px;
	`,
	Inputs: styled.div`
		display: flex;
		flex-direction: column;
		gap: 12px;
	`,
	Input: styled.div`
		font-size: 14px;
		border: 1px solid #d1d5dc;
		border-radius: 8px;
		padding: 8px;
	`,
}

export default CourseInputsPage
