import { useState } from "react"
import { Navigate } from "react-router"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"

import Button, { SubmitButton } from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import InputField from "~/components/InputField"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc, { getInputErrors } from "~/utilities/trpc"

import Layout, { Content, FieldInfoText, Logo, Title } from "./Layout"

const CreatePlatformPage: React.FC = () => {
	const user = useUser()
	const alert = useAlert()

	const [name, setName] = useState("")
	const [isAccountEmailChecked, setAccountEmailChecked] = useState(true)
	const [contactEmail, setContactEmail] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const handleSubmit = async () => {
		if (name.trim().length === 0 || (!isAccountEmailChecked && contactEmail.trim().length === 0)) return
		setSubmitting(true)
		try {
			await trpc.platform.create.mutate({
				name,
				contactEmail: isAccountEmailChecked ? user.user!.email : contactEmail,
			})
			await user.reload()
		} catch (error) {
			const errors = getInputErrors(error)
			if ("contactEmail" in errors) {
				await alert.show("Error", "The contact email address is invalid.")
			} else {
				console.error(error)
			}
		} finally {
			setSubmitting(false)
		}
	}

	if (user.user!.platform !== null) return <Navigate to={routes.account.selectFindSource()} />

	return (
		<Layout>
			<Logo />
			<Content as={Form} onSubmit={handleSubmit}>
				<Title>Create platform</Title>
				<div>
					<InputField
						label="Platform name"
						placeholder="e.g. Dave's Courses"
						value={name}
						onChange={setName}
					/>
					<FieldInfoText>The platform name can be changed at any time.</FieldInfoText>
				</div>
				<div>
					<StyledCheckbox
						label="Use my account's email address"
						isChecked={isAccountEmailChecked}
						onChange={setAccountEmailChecked}
					/>
					{isAccountEmailChecked ? (
						<InputField
							label="Contact email address"
							value={user.user!.email}
							disabled={isAccountEmailChecked}
						/>
					) : (
						<InputField
							label="Contact email address"
							placeholder="e.g. contact@davescourses.com"
							value={contactEmail}
							onChange={setContactEmail}
							disabled={isAccountEmailChecked}
						/>
					)}
					<FieldInfoText>
						The contact email be public to your users, and will also be used in your terms of service.
					</FieldInfoText>
				</div>

				<SubmitButton variant="primary" isLoading={isSubmitting}>
					Create platform
				</SubmitButton>

				<Button variant="secondary" onClick={user.signOut}>
					Sign out
				</Button>
			</Content>
		</Layout>
	)
}

const StyledCheckbox = styled(Checkbox)`
	margin-bottom: 8px;
`

export default CreatePlatformPage
