import { type FC, useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"
import { getDataUrlByFile, getFileNameFromPath } from "@forento/shared/utilities/file"
import { parseNumber } from "@forento/shared/utilities/number"

import { SubmitButton } from "~/components/Button"
import FileChooser from "~/components/FileChooser"
import InputField, { useRichTextArea } from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import Layout, { PageBreadcrumb, PageHeader } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import ProductPricing from "~/components/ProductPricing"
import usePrice from "~/hooks/usePrice"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

const EditDownloadablePage: FC = () => {
	const downloadableId = parseNumber(useParams().downloadableId) ?? -1
	const navigate = useNavigate()

	const downloadable = swr.downloadable.get.useSWR(downloadableId)

	const [name, setName] = useState("")
	const [shortDescription, setShortDescription] = useState("")
	const longDescription = useRichTextArea({ label: "Description" })
	const [isSignInRequired, setSignInRequired] = useState(false)
	const { price, priceValue, setPrice } = usePrice()
	const [membershipIds, setMembershipIds] = useState<number[]>([])
	const [thumbnail, setThumbnail] = useState<File | null>(null)
	const [isLoaded, setLoaded] = useState(false)

	const [isSubmitting, setSubmitting] = useState(false)

	useEffect(() => {
		const abortController = new AbortController()

		setName(downloadable.data?.title ?? "")
		setShortDescription(downloadable.data?.shortDescription ?? "")
		longDescription.set(downloadable.data?.longDescription ?? null)
		setSignInRequired(downloadable.data?.isSignInRequired ?? false)
		setPrice(downloadable.data?.price?.amount.toString() ?? "")
		setMembershipIds(downloadable.data?.membershipIds ?? [])
		if (downloadable.data?.thumbnailFilePath != null) {
			fetch(downloadable.data.thumbnailFilePath, { signal: abortController.signal })
				.then(async x => ({
					name: getFileNameFromPath(x.url),
					blob: await x.blob(),
				}))
				.then(({ name, blob }) => {
					if (abortController.signal.aborted) return
					setThumbnail(new File([blob], name ?? "Thumbnail", { type: blob.type }))
				})
		} else {
			setThumbnail(null)
		}
		if (downloadable.data) {
			setLoaded(true)
		}

		return () => {
			abortController.abort()
		}
	}, [downloadable.data, longDescription, setPrice])

	const isValid = name.trim().length > 0

	const handleSubmit = async () => {
		if (!isValid) return

		setSubmitting(true)
		try {
			await trpc.downloadable.update.mutate({
				id: downloadableId,
				data: {
					name,
					shortDescription,
					longDescription: longDescription.exportEditorState(),
					isSignInRequired,
					price: priceValue,
					membershipIds,
					thumbnailDataUrl: thumbnail ? await getDataUrlByFile(thumbnail) : null,
				},
			})
			await downloadable.mutate()
			navigate(routes.downloadable.index())
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout>
			<PageHeader>
				<PageBreadcrumb
					path={[{ title: "Downloadables", link: routes.downloadable.index() }]}
					title={downloadable.data?.title ?? "..."}
				/>
			</PageHeader>
			{downloadable.error ? (
				<p>Failed to fetch downloadable.</p>
			) : !isLoaded || !downloadable.data ? (
				<PartialLoadingPage />
			) : (
				<Content onSubmit={handleSubmit}>
					<Section>
						<InputField label="Name" value={name} onChange={setName} />
						<InputField label="Short description" value={shortDescription} onChange={setShortDescription} />
						{longDescription.element}
						<div>
							<InputLabel>Thumbnail (optional)</InputLabel>
							<FileChooser type="file" value={thumbnail} onChange={setThumbnail} />
						</div>
					</Section>
					<Section>
						<Title>Pricing</Title>
						<ProductPricing
							type="downloadable"
							isSignInRequired={isSignInRequired}
							setSignInRequired={setSignInRequired}
							price={price}
							setPrice={setPrice}
							membershipIds={membershipIds}
							setMembershipIds={setMembershipIds}
						/>
					</Section>
					<StyledSubmitButton variant="primary" isDisabled={!isValid} isLoading={isSubmitting}>
						Save downloadable
					</StyledSubmitButton>
				</Content>
			)}
		</Layout>
	)
}

const Content = styled(Form)`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 24px;
	color: black;
`

const StyledSubmitButton = styled(SubmitButton)`
	align-self: flex-end;
`

export default EditDownloadablePage
