import { type FC, type ReactNode, useState, useCallback, useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"

import { getCookieByName } from "@forento/shared/utilities/cookie"

import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import trpc from "~/utilities/trpc"

import GoogleSignInContext, { type GoogleSignInContextValue } from "."

const GoogleSignInContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const user = useUser()
	const [searchParams] = useSearchParams()
	const alert = useAlert()

	const [isEnabled, setEnabled] = useState(true)
	const [isLoaded, setLoaded] = useState(false)
	const [isPrompted, setPrompted] = useState(false)

	const load = useCallback(() => {
		window.google.accounts.id.initialize({
			use_fedcm_for_prompt: true,
			client_id: "865250728390-rnqcl33tik70lm18r9vruj41791m2l1j.apps.googleusercontent.com",
			cancel_on_tap_outside: false,
			callback: async ({ credential }) => {
				if (user.user !== undefined) {
					const response = await trpc.user.connectGoogle.mutate({ credential })
					if (response.status === "not-same-email") {
						alert.show("Error", "You can't connect a Google account with a different email address.")
					} else if (response.status === "already-connected") {
						alert.show("Error", "That Google account is already associated with an account.")
					} else if (response.status === "success") {
						await user.reload()
					}
				} else {
					const customName = await (async () => {
						const verification = await trpc.user.verifyGoogleCredential.query(credential)
						if (verification.status === "incomplete-name") {
							const dialog = await alert.multipleInputs(
								"Complete your account",
								"Please complete your account.",
								[
									{ label: "First name", defaultValue: verification.firstName },
									{ label: "Last name", defaultValue: verification.lastName },
								],
							)
							if (!dialog.result) return undefined
							dialog.close()
							return { first: dialog.result[0], last: dialog.result[1] }
						}
						return null
					})()
					if (customName === undefined) return

					const response = await trpc.user.loginGoogle.mutate({
						credential,
						source: searchParams.get("s") ?? getCookieByName("s"),
						referralCode: searchParams.get("r"),
						name: customName ?? undefined,
					})

					if (response.status === "social-not-connected") {
						alert.show(
							"Error",
							"Your Google account is not connected to your Forento account. Please connect Google in your Forento account settings.",
						)
					} else if (response.status === "invalid-referral-code") {
						alert.show("Error", "That referral code is invalid.")
					} else if (response.status === "success") {
						await user.reload()
					}
				}
			},
		})

		setLoaded(true)
	}, [alert, searchParams, user])

	useEffect(() => {
		if (isLoaded || !isEnabled) return

		if (window.google?.accounts?.id !== undefined) {
			load()
			window.google.accounts.id.cancel()
		} else {
			const interval = setInterval(() => {
				if (window.google?.accounts?.id === undefined) return
				clearInterval(interval)
				load()
			}, 1000)

			return () => {
				clearInterval(interval)
				window.google?.accounts?.id.cancel()
			}
		}
	}, [isEnabled, isLoaded, load])

	useEffect(() => {
		if (window.google?.accounts?.id === undefined) return
		if (user.isLoading || user.user !== undefined || !isLoaded || isPrompted) return

		window.google.accounts.id.prompt()
		setPrompted(true)
	}, [user, isLoaded, isPrompted])

	const value = useMemo<GoogleSignInContextValue>(
		() => ({
			isInitialized: isLoaded,
			disable() {
				setEnabled(false)
			},
		}),
		[isLoaded],
	)

	return <GoogleSignInContext.Provider value={value}>{children}</GoogleSignInContext.Provider>
}

export default GoogleSignInContextProvider
