import { type FC, useState } from "react"

import Button, { SubmitButton } from "~/components/Button"
import InputField, { InputTextArea, useRichTextArea } from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalTitle } from "~/components/Modal"
import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/UserContext"
import usePrice from "~/hooks/usePrice"
import trpc from "~/utilities/trpc"

type Props = { isOpen: boolean; onClose(): void; onCreate(id: number): void }

const CreateModal: FC<Props> = ({ isOpen, onClose, onCreate }) => {
	const platform = usePlatform()
	const alert = useAlert()

	const [title, setTitle] = useState("")
	const [shortDescription, setShortDescription] = useState("")
	const longDescription = useRichTextArea({ label: "Description" })
	const { price, priceValue, setPrice } = usePrice()
	const [redemptionInstructions, setRedemptionInstructions] = useState("")
	const [redemptionLink, setRedemptionLink] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	async function handleSubmit() {
		setSubmitting(true)
		try {
			if (title.trim().length === 0 || priceValue === null) {
				return alert.show("Error", "You must fill out all required fields.")
			}
			if (redemptionInstructions.trim().length === 0 && redemptionLink.trim().length === 0) {
				return alert.show("Error", "You must provide either redemption instructions or a redemption link.")
			}
			const created = await trpc.privateSession.create.mutate({
				title,
				shortDescription,
				longDescription: longDescription.exportEditorState(),
				price: priceValue > 0 ? priceValue : null,
				redemptionInstructions: redemptionInstructions.trim().length > 0 ? redemptionInstructions : null,
				redemptionLink: redemptionLink.trim().length > 0 ? redemptionLink : null,
			})
			onCreate(created.id)
			setTitle("")
			setShortDescription("")
			longDescription.set(null)
			setPrice("")
			setRedemptionInstructions("")
			setRedemptionLink("")
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={isOpen} onSubmit={handleSubmit}>
			<ModalTitle>Create session</ModalTitle>
			<ModalInputs>
				<InputField label="Title *" value={title} onChange={setTitle} />
				<InputField label="Short description" value={shortDescription} onChange={setShortDescription} />
				{longDescription.element}
				<InputField
					inputType="number"
					label={`Price ${platform!.currency} *`}
					value={price}
					onChange={setPrice}
				/>
				<InputTextArea
					label="Redemption instructions"
					value={redemptionInstructions}
					onChange={setRedemptionInstructions}
				/>
				<InputField
					label="Redemption link"
					value={redemptionLink}
					onChange={setRedemptionLink}
					placeholder="Calendar booking, etc."
				/>
			</ModalInputs>
			<ModalButtons>
				<Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
					Cancel
				</Button>
				<SubmitButton variant="primary" isLoading={isSubmitting}>
					Create
				</SubmitButton>
			</ModalButtons>
		</DefaultModal>
	)
}

export default CreateModal
