import { type FC, type ReactNode, useMemo } from "react"

import trpc, { swr } from "~/utilities/trpc"

import UserContext, { type UserContextValue } from "."

const UserContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const { data: user, mutate } = swr.user.getCreator.useSWR()

	const value = useMemo<UserContextValue>(
		() => ({
			isLoading: user === undefined,
			user: user ?? undefined,
			apiVersion: user?.apiVersion ?? null,
			async reload() {
				await mutate()
			},
			async signOut() {
				await trpc.user.signOut.mutate()
				await mutate()
			},
		}),
		[user, mutate],
	)

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContextProvider
