import { type FC } from "react"
import styled from "styled-components"

import { type SearchedDomain } from "@forento/shared/models/domain"

import Button from "~/components/Button"

interface Props {
	domain: SearchedDomain
	onSelect(): void
}

const DomainOption: FC<Props> = ({ domain, onSelect }) => (
	<Container>
		{domain.domain}
		<Details>
			{domain.status === "taken" ? (
				"Taken"
			) : domain.status === "premium" ? (
				"We currently don't sell premium domains"
			) : domain.status === "available" ? (
				<>
					<Price>${domain.price}/year</Price>
					<Button variant="primary" onClick={onSelect}>
						Select
					</Button>
				</>
			) : null}
		</Details>
	</Container>
)

const Container = styled.div`
	display: flex;
	gap: 16px;
	justify-content: space-between;
	align-items: center;
	background-color: #fbfaf8;
	border: 1px solid #eaeaea;
	border-radius: 8px;
	padding: 16px;
`

const Details = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
`

const Price = styled.div`
	font-size: 16px;
`

export default DomainOption
