import { useState, type FC } from "react"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import { cancelReasonIds, type CancelReasonId } from "@forento/shared/models/payment"
import { exhaustiveGuard } from "@forento/shared/utilities/switch"

import Button from "~/components/Button"
import { InputTextArea } from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalPadding, ModalText, ModalTitle } from "~/components/Modal"
import { useAlert } from "~/contexts/AlertContext"
import trpc from "~/utilities/trpc"

const reasons: Record<CancelReasonId, { label: string; elaboration: string }> = {
	switched: { label: "I found another service", elaboration: "Which service are you switching to, and why?" },
	features: { label: "I don't use the features", elaboration: "Which features are you missing?" },
	ltd: { label: "I'm buying a lifetime deal", elaboration: "What made you pick the lifetime deal?" },
	expectations: {
		label: "It didn't meet my expectations",
		elaboration: "Do you miss any specific feature? Any additional feedback?",
	},
	price: { label: "The price is too high", elaboration: "What price do you think is fair?" },
	other: { label: "Other reason", elaboration: "Do you have any additional feedback?" },
}

type Props = { type: "plan" | "addon"; isOpen: boolean; onClose(): void; onSubmitted(): Promise<void> }
const CancelSubscriptionModal: FC<Props> = ({ type, isOpen, onClose, onSubmitted }) => {
	const alert = useAlert()

	const [selectedReasonId, setSelectedReasonId] = useState<CancelReasonId>()
	const [elaboration, setElaboration] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	async function handleSubmit() {
		if (!selectedReasonId) return
		setSubmitting(true)
		try {
			if (type === "plan") {
				await trpc.payment.creator.cancelPlan.mutate({ reason: selectedReasonId, elaboration })
			} else if (type === "addon") {
				await trpc.payment.creator.cancelAddon.mutate({ reason: selectedReasonId, elaboration })
			} else {
				exhaustiveGuard(type)
			}
			await onSubmitted()
			setSelectedReasonId(undefined)
			setElaboration("")
		} catch (error) {
			console.error(error)
			await alert.show("Error", "Failed to cancel subscription. Please try again.")
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={isOpen}>
			<ModalTitle>Cancel subscription</ModalTitle>
			{!selectedReasonId ? (
				<>
					<ModalText>
						We are sad to hear you want to cancel your subscription. Please let us know why before canceling
						so we can improve the product in the future.
					</ModalText>
					<Reasons>
						{Object.entries(reasons).map(([id, reason]) =>
							!(type === "addon" && id === "ltd") ? (
								<Reason
									key={id}
									onClick={() => setSelectedReasonId(cancelReasonIds.find(x => x === id))}
								>
									{reason.label}
								</Reason>
							) : null,
						)}
						<Button variant="primary" onClick={onClose}>
							Keep subscription
						</Button>
					</Reasons>
				</>
			) : (
				<>
					<ModalInputs>
						<InputTextArea
							label={reasons[selectedReasonId].elaboration}
							value={elaboration}
							onChange={setElaboration}
						/>
					</ModalInputs>
					<ModalButtons>
						<Button variant="secondary" onClick={() => setSelectedReasonId(undefined)}>
							Previous
						</Button>
						<Button
							variant="secondary"
							onClick={() => {
								onClose()
								setSelectedReasonId(undefined)
							}}
						>
							Keep subscription
						</Button>
						{elaboration.trim().length > 0 ? (
							<Button variant="primary-danger" onClick={handleSubmit} isLoading={isSubmitting}>
								Cancel subscription
							</Button>
						) : (
							<Tooltip tooltip="Please let us know why first">
								<Button variant="primary-danger" isDisabled>
									Cancel subscription
								</Button>
							</Tooltip>
						)}
					</ModalButtons>
				</>
			)}
		</DefaultModal>
	)
}

const Reasons = styled(ModalPadding)`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

const Reason = styled(Button).attrs({ variant: "secondary" })`
	width: 100%;
`

export default CancelSubscriptionModal
