import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { type CSSProperties, type FC } from "react"
import styled, { css } from "styled-components"
import tinycolor from "tinycolor2"

import Button from "~/components/Button"
import { DragIndicatorIcon } from "~/components/Icon"
import { primaryLightColor } from "~/utilities/styles"

interface Props {
	chapter: { id: number; title: string; pageIds: number[] }
	isSelected: boolean
	onClick(): void
}

const Chapter: FC<Props> = ({ chapter, isSelected, onClick }) => {
	const { setNodeRef, attributes, listeners, transform, transition } = useSortable({ id: chapter.id })

	const style: CSSProperties = { transform: CSS.Transform.toString(transform), transition }

	return (
		<Container $isSelected={isSelected} style={style} ref={setNodeRef}>
			<ChapterDragIndicator {...attributes} {...listeners} />
			<Clickable onClick={onClick}>
				<ChapterTitle>{chapter.title}</ChapterTitle>
				<ChapterPages>{chapter.pageIds.length}</ChapterPages>
			</Clickable>
		</Container>
	)
}

const Container = styled.div<{ $isSelected: boolean }>`
	transition: 0.15s background-color;
	display: flex;
	align-items: center;
	gap: 4px;
	padding: 8px 8px;
	border-radius: 8px;
	margin: 0 12px;

	${props =>
		props.$isSelected &&
		css`
			background-color: white;
		`}

	${props =>
		!props.$isSelected &&
		css`
			&:hover {
				background-color: ${tinycolor(primaryLightColor).lighten(1.5).toHexString()};
			}
		`}
`

const Clickable = styled(Button)`
	flex: 1;
	line-height: 1.4;
	font-size: 16px;
	letter-spacing: 0.75px;
	text-align: left;
	display: flex;
	align-items: center;
	gap: 16px;
`

const ChapterTitle = styled.h3`
	font-weight: 500;
	font-size: 16px;
	flex: 1;
`

const ChapterDragIndicator = styled(DragIndicatorIcon)`
	width: 24px;
	height: 24px;
	cursor: grab;
`

const ChapterPages = styled.div`
	height: 24px;
	min-width: 24px;
	font-weight: 500;
	font-size: 14px;
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 8px;
`

export default Chapter
