import { type FC, useLayoutEffect, useState } from "react"
import styled from "styled-components"

import { type SubscriptionPaymentInterval } from "@forento/shared/models/payment"
import { type FreePlatformPlan, type RegularPlatformPlan } from "@forento/shared/models/platform"
import { getPlansAnnualDiscount } from "@forento/shared/utilities/payment"

import PartialLoadingPage from "~/components/PartialLoadingPage"
import PaymentIntervalSelector from "~/components/PaymentIntervalSelector"
import { useUser } from "~/contexts/UserContext"
import CancelSubscriptionModal from "~/pages/settings/BillingSettingsPage/Plans/CancelSubscriptionModal"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

import Plan from "./Plan"

type Props = { platformPlan: FreePlatformPlan | RegularPlatformPlan }
const Regular: FC<Props> = ({ platformPlan }) => {
	const user = useUser()

	const [isMonthlySelected, setMonthlySelected] = useState(true)

	const plans = swr.payment.creator.getPlans.useSWR()
	const currentPlan = swr.payment.creator.getCurrentPlan.useSWR()

	const [isCancelModalOpen, setCancelModalOpen] = useState(false)
	const [submittingPlanId, setSubmittingPlanId] = useState<number | null>()

	useLayoutEffect(() => {
		setMonthlySelected(currentPlan.data?.price?.interval === "month")
	}, [currentPlan.data?.price?.interval])

	const reloadPlans = async () => {
		await Promise.all([plans.mutate(), currentPlan.mutate(), user.reload()])
	}

	const handleResumeSubscription = async () => {
		if (currentPlan == null) return
		setSubmittingPlanId(platformPlan.id)
		try {
			await trpc.payment.creator.resumePlan.mutate()
		} catch (error) {
			console.error(error)
		} finally {
			await reloadPlans()
			setSubmittingPlanId(undefined)
		}
	}

	const handleChoosePlan = async (planId: number, paymentInterval: SubscriptionPaymentInterval) => {
		setSubmittingPlanId(planId)
		try {
			const { redirectUrl } = await trpc.payment.creator.setPlan.mutate({
				planId,
				paymentInterval,
				redirect: { cancel: routes.settings.billing.plans(), success: routes.settings.billing.plans() },
				source: "settings",
			})
			if (redirectUrl !== null) {
				window.location.href = redirectUrl
			}
		} catch (error) {
			console.error(error)
		} finally {
			await reloadPlans()
			setSubmittingPlanId(undefined)
		}
	}

	if (plans.error || currentPlan.error) return <p>Failed to load plans.</p>
	if (plans.data === undefined || currentPlan.data === undefined) return <PartialLoadingPage />

	return (
		<>
			<CancelSubscriptionModal
				type="plan"
				isOpen={isCancelModalOpen}
				onClose={() => setCancelModalOpen(false)}
				onSubmitted={async () => {
					await reloadPlans()
					setCancelModalOpen(false)
				}}
			/>
			<Container>
				<PaymentIntervalSelector
					annualDiscount={getPlansAnnualDiscount(plans.data)}
					isMonthlySelected={isMonthlySelected}
					setMonthlySelected={setMonthlySelected}
				/>
				{plans.data.some(x => x.prices !== null && x.prices.platform !== null) && (
					<NoticeText>As an early customer you have access to lower rates.</NoticeText>
				)}
				<PlansList>
					{plans.data.map(plan => (
						<Plan
							key={plan.id}
							plan={plan}
							currentPlan={currentPlan.data!}
							platformPlan={platformPlan}
							isMonthly={isMonthlySelected}
							isSubmitting={submittingPlanId === plan.id}
							isDisabled={submittingPlanId !== undefined}
							onCancelSubscription={() => setCancelModalOpen(true)}
							onResumeSubscription={handleResumeSubscription}
							onSelectPlan={paymentInterval =>
								plan.id !== null && handleChoosePlan(plan.id, paymentInterval)
							}
						/>
					))}
				</PlansList>
			</Container>
		</>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const NoticeText = styled.p`
	text-align: center;
`

const PlansList = styled.div`
	display: flex;
	gap: 16px;
`

export default Regular
