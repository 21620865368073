import { Navigate } from "react-router"
import styled from "styled-components"

import PartialLoadingPage from "~/components/PartialLoadingPage"
import { ControlledTabs } from "~/components/Tabs"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

import StatCard from "./StatCard"

const Dashboard: React.FC = () => {
	const platform = useUser().user!.platform!

	const { data: referral, error } = swr.referral.get.useSWR()

	if (!platform.isReferralEnabled || referral === null) return <Navigate to={routes.main.index()} />
	if (error) return <div>Failed to load referral data.</div>
	if (!referral) return <PartialLoadingPage />

	return (
		<Container>
			<p>Your referral code: {referral.code}</p>
			<p>
				Your referral link:{" "}
				<a href={referral.link} target="_blank" rel="noreferrer">
					{referral.link}
				</a>
			</p>
			<p>We will reach out to you when you've reached the threshold of $20 in referral earnings.</p>
			<ControlledTabs
				tabs={[
					{
						title: "Month to date",
						content: (
							<TabContent>
								<Cards>
									<StatCard
										label="Referred customers"
										values={[{ value: referral.referredPlatforms.monthToDate }]}
										color="#E5F6FF"
									/>
								</Cards>
							</TabContent>
						),
					},
					{
						title: "Total",
						content: (
							<TabContent>
								<Cards>
									<StatCard
										label="Referred customers"
										values={[{ value: referral.referredPlatforms.total }]}
										color="#E5F6FF"
									/>
								</Cards>
							</TabContent>
						),
					},
				]}
			/>
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	max-width: 820px;
	display: flex;
	flex-direction: column;
	gap: 20px;
`

const Cards = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
`

const TabContent = styled.div`
	margin-top: 20px;
`

export default Dashboard
