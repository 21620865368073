import styled, { css } from "styled-components"

import Button from "~/components/Button"
import LoadingIndicator from "~/components/LoadingIndicator"
import { primaryColor } from "~/utilities/styles"

const CheckSlider: React.FC<{ isChecked: boolean; isLoading: boolean; onClick: () => void }> = ({
	isChecked,
	isLoading,
	onClick,
}) => (
	<Container as={!isLoading ? Button : "div"} onClick={!isLoading ? onClick : undefined}>
		{isLoading ? (
			<StyledLoadingIndicator />
		) : (
			<>
				<Slider $isChecked={isChecked}>
					<Handle />
				</Slider>
				<Label>{isChecked ? "On" : "Off"}</Label>
			</>
		)}
	</Container>
)

const Container = styled.div`
	display: flex;
	gap: 8px;
`

const StyledLoadingIndicator = styled(LoadingIndicator)`
	width: 20px;
	height: 20px;
`

const Slider = styled.div<{ $isChecked: boolean }>`
	width: 36px;
	height: 20px;
	border-radius: 10px;
	padding: 2px;
	display: flex;

	${props =>
		props.$isChecked
			? css`
					background-color: ${primaryColor};
					justify-content: flex-end;
				`
			: css`
					background-color: #f2f4f7;
					justify-content: flex-start;
				`}
`

const Handle = styled.div`
	width: 16px;
	height: 16px;
	border-radius: 8px;
	background-color: white;
	box-shadow:
		0px 1px 3px rgba(16, 24, 40, 0.1),
		0px 1px 2px rgba(16, 24, 40, 0.06);
`

const Label = styled.div`
	font-weight: 600;
	font-size: 14px;
`

export default CheckSlider
