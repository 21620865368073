import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { type CSSProperties, type FC } from "react"
import styled, { css } from "styled-components"
import tinycolor from "tinycolor2"

import Tooltip from "@forento/shared/components/Tooltip"
import { type ThinPrivateSession } from "@forento/shared/models/privateSession"
import { toPriceString } from "@forento/shared/utilities/currency"

import Button from "~/components/Button"
import { DetailedListItem, DetailedListItemTitle } from "~/components/DetailedList"
import { EditIcon, DeleteIcon, DragIndicatorIcon } from "~/components/Icon"
import { useAlert } from "~/contexts/AlertContext"
import { dangerColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

type Props = { session: ThinPrivateSession; setEditSessionId(id: number): void; reload(): Promise<unknown> }
const Session: FC<Props> = ({ session, setEditSessionId, reload }) => {
	const alert = useAlert()
	const { setNodeRef, transform, transition, attributes, listeners } = useSortable({ id: session.id })

	const style: CSSProperties = { transform: CSS.Transform.toString(transform), transition }

	return (
		<Container key={session.id} ref={setNodeRef} style={style}>
			<SessionDetails>
				<DetailedListItemTitle>{session.title}</DetailedListItemTitle>
			</SessionDetails>
			<div>{session.price !== null ? toPriceString(session.price) : "Free"}</div>
			<Actions>
				<Tooltip tooltip="Edit">
					<Action onClick={() => setEditSessionId(session.id)}>
						<EditIcon />
					</Action>
				</Tooltip>
				<Tooltip tooltip={session.hasSales ? "You can't delete a session that has sales" : "Delete"}>
					<Action
						isDanger
						isDisabled={session.hasSales}
						onClick={async () => {
							const dialog = await alert.confirm(
								"Delete session",
								`Are you sure you want to delete the session **${session.title}**?`,
							)
							if (!dialog.result) return
							await trpc.privateSession.delete.mutate(session.id)
							await reload()
							dialog.close()
						}}
					>
						<DeleteIcon />
					</Action>
				</Tooltip>
				<DragButton {...attributes} {...listeners}>
					<DragIndicatorIcon />
				</DragButton>
			</Actions>
		</Container>
	)
}

const Container = styled(DetailedListItem)`
	display: flex;
	align-items: center;
	gap: 16px;
`

const SessionDetails = styled.div`
	flex: 1;
`

const Actions = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

const Action = styled(Button)<{ isDanger?: boolean }>`
	width: 24px;
	height: 24px;

	${props =>
		props.isDanger &&
		css`
			color: ${dangerColor};
		`}

	${props =>
		props.isDisabled &&
		css`
			color: ${tinycolor("white").darken(30).toString()};
		`}
`

const DragButton = styled.div`
	width: 24px;
	height: 24px;
	cursor: grab;
`

export default Session
