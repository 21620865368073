import mime from "mime"
import type React from "react"
import { useState } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"

import { supportedFileExtensions } from "@forento/shared/utilities/file"

import { UploadFileIcon } from "~/components/Icon"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { primaryColor } from "~/utilities/styles"

type Type = "image" | "spreadsheet" | "file"

const FileUploader: React.FC<{
	type: Type
	onUpload: (file: File) => Promise<void>
}> = ({ type, onUpload }) => {
	const [isUploading, setUploading] = useState(false)

	const dropzone = useDropzone({
		accept:
			type === "image"
				? Object.fromEntries(supportedFileExtensions.image.map(extension => [mime.getType(extension), []]))
				: type === "spreadsheet"
					? Object.fromEntries(
							supportedFileExtensions.spreadsheet.map(extension => [mime.getType(extension), []]),
						)
					: undefined,
		async onDrop(acceptedFiles: File[]) {
			if (acceptedFiles.length === 0) return
			setUploading(true)
			await onUpload(acceptedFiles[0])
			setUploading(false)
		},
	})

	return (
		<Dropzone {...dropzone.getRootProps()}>
			{!isUploading && <input {...dropzone.getInputProps()} />}
			{getContent({
				type,
				isUploading,
				isDragActive: dropzone.isDragActive,
				isDragReject: dropzone.isDragReject,
			})}
		</Dropzone>
	)
}

const getContent = ({
	type,
	isUploading,
	isDragActive,
	isDragReject,
}: {
	type: Type
	isUploading: boolean
	isDragActive: boolean
	isDragReject: boolean
}) => {
	if (isUploading) return <PartialLoadingPage />
	if (isDragActive)
		return (
			<InfoText>
				{isDragReject
					? "That file type is not supported"
					: type === "file"
						? "Drop file here"
						: `Drop ${type} file here`}
			</InfoText>
		)

	return (
		<>
			<Icon
				as={
					type === "image"
						? ImageIcon
						: type === "spreadsheet"
							? UploadFileIcon
							: type === "file"
								? UploadFileIcon
								: undefined
				}
			/>
			<InfoText>
				{type === "spreadsheet"
					? "Click here or drag and drop to upload a spreadsheet"
					: "Click here or drag and drop to upload"}
			</InfoText>
		</>
	)
}

const Dropzone = styled.div`
	border: 1px dashed ${primaryColor};
	border-radius: 8px;
	padding: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 32px;
	cursor: pointer;
	position: relative;
`

const Icon = styled.div`
	width: 64px;
	height: 64px;
	color: ${primaryColor};
`

const ImageIcon: React.FC<{ className?: string }> = ({ className }) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className={className}>
		<path
			fill="currentColor"
			d="M29.45 6V9H9Q9 9 9 9Q9 9 9 9V39Q9 39 9 39Q9 39 9 39H39Q39 39 39 39Q39 39 39 39V18.6H42V39Q42 40.2 41.1 41.1Q40.2 42 39 42H9Q7.8 42 6.9 41.1Q6 40.2 6 39V9Q6 7.8 6.9 6.9Q7.8 6 9 6ZM38 6V10.05H42.05V13.05H38V17.1H35V13.05H30.95V10.05H35V6ZM12 33.9H36L28.8 24.3L22.45 32.65L17.75 26.45ZM9 9V14.55V18.6V39Q9 39 9 39Q9 39 9 39Q9 39 9 39Q9 39 9 39V9Q9 9 9 9Q9 9 9 9Z"
		/>
	</svg>
)

const InfoText = styled.p`
	font-weight: 500;
	font-size: 16px;
	text-align: center;
`

export default FileUploader
