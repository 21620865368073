import { type FC, useState } from "react"
import { useParams } from "react-router"
import styled from "styled-components"

import { type DnsRecordType, dnsRecordTypes } from "@forento/shared/models/domain"
import { parseNumber } from "@forento/shared/utilities/number"

import Button from "~/components/Button"
import Dropdown from "~/components/Dropdown"
import InputField from "~/components/InputField"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Table from "~/components/Table"
import { useAlert } from "~/contexts/AlertContext"
import withAccessRequirement from "~/hocs/withAccessRequirement"
import routes from "~/utilities/routes"
import { dangerColor, primaryColor } from "~/utilities/styles"
import trpc, { swr } from "~/utilities/trpc"

const title = (
	<PageBreadcrumb
		title="Manage DNS"
		path={[
			{ title: "Settings", link: routes.settings.index() },
			{ title: "Custom Domain", link: routes.settings.domain.index() },
		]}
	/>
)

const ManageDnsPage: FC = () => {
	const alert = useAlert()

	const domainPurchaseId = parseNumber(useParams().domainPurchaseId) ?? -1

	const [editingId, setEditingId] = useState<number>()
	const [editingType, setEditingType] = useState<DnsRecordType>("A")
	const [editingHost, setEditingHost] = useState("")
	const [editingValue, setEditingValue] = useState("")
	const [isSubmittingEdited, setSubmittingEdited] = useState(false)

	const [newType, setNewType] = useState<DnsRecordType>("A")
	const [newHost, setNewHost] = useState("")
	const [newValue, setNewValue] = useState("")
	const [isSubmittingNew, setSubmittingNew] = useState(false)

	const [deletingRecordId, setDeletingRecordId] = useState<number>()

	const { data: dnsRecords, error, mutate } = swr.domain.listDnsRecords.useSWR(domainPurchaseId)

	async function handleUpdateRecord() {
		if (editingId === undefined || editingHost.trim().length === 0 || editingValue.trim().length === 0) return
		setSubmittingEdited(true)
		try {
			const { status } = await trpc.domain.updateDnsRecord.mutate({
				id: editingId,
				data: { type: editingType, host: editingHost, value: editingValue },
			})
			if (status === "conflict") {
				await alert.show(
					"Error",
					"This record conflicts with another record. Please change it or remove the conflicting one.",
				)
				return
			}
			if (status === "invalid") {
				await alert.show("Error", "This record is invalid. Make sure you chose the right type.")
				return
			}
			await mutate()
			setEditingId(undefined)
		} catch (error) {
			console.error(error)
		} finally {
			setSubmittingEdited(false)
		}
	}

	async function handleAddRecord() {
		if (newHost.trim().length === 0 || newValue.trim().length === 0) return

		setSubmittingNew(true)
		try {
			const { status } = await trpc.domain.addDnsRecord.mutate({
				domainPurchaseId,
				record: { type: newType, host: newHost, value: newValue },
			})
			if (status === "conflict") {
				await alert.show(
					"Error",
					"This record conflicts with another record. Please change it or remove the conflicting one.",
				)
				return
			}
			if (status === "invalid") {
				await alert.show("Error", "This record is invalid. Make sure you chose the right type.")
				return
			}
			await mutate()
			setNewType("A")
			setNewHost("")
			setNewValue("")
		} catch (error) {
			console.error(error)
		} finally {
			setSubmittingNew(false)
		}
	}

	async function handleDeleteRecord(id: number) {
		setDeletingRecordId(id)
		try {
			await trpc.domain.deleteDnsRecord.mutate(id)
			await mutate()
		} catch (error) {
			console.error(error)
		} finally {
			setDeletingRecordId(undefined)
		}
	}

	return (
		<Layout>
			{title}
			{error ? (
				<p>Failed to load DNS records.</p>
			) : !dnsRecords ? (
				<PartialLoadingPage />
			) : (
				<Table
					columns={["Type", "Host", "Value", { label: "Manage", flex: { basis: 130, grow: 0 } }]}
					data={[
						...dnsRecords.map(record =>
							record.isCustom && record.id === editingId
								? [
										<Dropdown
											items={dnsRecordTypes.map(x => ({ id: x, title: x }))}
											selectedItemId={editingType}
											onChange={type =>
												setEditingType(dnsRecordTypes.find(x => x === type) ?? "A")
											}
										/>,
										<InputField
											compact
											placeholder="@"
											value={editingHost}
											onChange={setEditingHost}
										/>,
										<InputField
											compact
											placeholder="192.168.1.1"
											value={editingValue}
											onChange={setEditingValue}
										/>,
										<Actions>
											<ActionButton
												onClick={() => setEditingId(undefined)}
												isDisabled={isSubmittingEdited}
											>
												Cancel
											</ActionButton>
											<ActionButton onClick={handleUpdateRecord} isLoading={isSubmittingEdited}>
												Save
											</ActionButton>
										</Actions>,
									]
								: [
										record.type,
										record.host,
										record.value,
										record.isCustom && (
											<Actions>
												<ActionButton
													onClick={() => {
														setEditingId(record.id)
														setEditingType(record.type)
														setEditingHost(record.host)
														setEditingValue(record.value)
													}}
												>
													Edit
												</ActionButton>
												<ActionButton
													$isDanger
													onClick={() => handleDeleteRecord(record.id)}
													isLoading={deletingRecordId === record.id}
													isDisabled={deletingRecordId !== undefined}
												>
													Delete
												</ActionButton>
											</Actions>
										),
									],
						),
						[
							<Dropdown
								items={dnsRecordTypes.map(x => ({ id: x, title: x }))}
								selectedItemId={newType}
								onChange={value => setNewType(dnsRecordTypes.find(x => x === value) ?? "A")}
							/>,
							<InputField compact placeholder="@" value={newHost} onChange={setNewHost} />,
							<InputField compact placeholder="192.168.1.1" value={newValue} onChange={setNewValue} />,
							<ActionButton onClick={handleAddRecord} isLoading={isSubmittingNew}>
								Add record
							</ActionButton>,
						],
					]}
				/>
			)}
		</Layout>
	)
}

const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 16px;
`

const ActionButton = styled(Button)<{ $isDanger?: boolean }>`
	font-weight: 600;
	font-size: 14px;
	color: ${props => (props.$isDanger ? dangerColor : primaryColor)};
	display: flex;
	align-items: center;
	gap: 6px;
`

export default withAccessRequirement("customDomain", title, ManageDnsPage)
