import { type FC, useCallback, useEffect, useState } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import FeatureIntroduction from "~/components/FeatureIntroduction"
import Layout, { PageTitle } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useUser } from "~/contexts/UserContext"
import withAccessRequirement from "~/hocs/withAccessRequirement"
import trpc, { swr } from "~/utilities/trpc"

import illustration from "./illustration.svg"

const header = <PageTitle>Website</PageTitle>

const WebsiteEditorPage: FC = () => {
	const user = useUser()

	return (
		<Layout>
			{header}
			{user.user!.platform!.domain.landingPage ? (
				<Opening />
			) : (
				<FeatureIntroduction
					illustrationUrl={illustration}
					title="Website editor"
					text="Get access to a drag & drop website editor tool to create an own for your brand."
					callToAction={{
						label: "Enable website",
						onClick: async () => {
							const { redirectUrl } = await trpc.landing.editor.query()
							window.open(redirectUrl, "_blank")
						},
					}}
				/>
			)}
		</Layout>
	)
}

const Opening: FC = () => {
	const [isOpened, setOpened] = useState(false)

	const { data: editor, error } = swr.landing.editor.useSWR()

	const openEditor = useCallback(async () => {
		if (editor) {
			window.open(editor.redirectUrl, "_blank")
			setOpened(true)
		}
	}, [editor])

	useEffect(() => {
		openEditor()
	}, [openEditor])

	if (error) return <p>Failed to open the editor.</p>

	if (!isOpened) return <PartialLoadingPage />

	return (
		<Container>
			<p>The website editor has been opened in a separate tab.</p>
			<Button variant="primary" onClick={openEditor}>
				Open again
			</Button>
		</Container>
	)
}

const Container = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eeeeed;
	border-radius: 8px;
	padding: 42px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
`

export default withAccessRequirement("landingPage", header, WebsiteEditorPage)
