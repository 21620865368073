import styled from "styled-components"

import Layout, { PageTitle } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useUser } from "~/contexts/UserContext"
import { swr } from "~/utilities/trpc"

import DemoVideoCard from "./DemoVideoCard"
import DomainCard from "./DomainCard"
import Onboarding from "./Onboarding"
import StatCard from "./StatCard"

const HomePage: React.FC = () => {
	const user = useUser().user!

	const { data, error } = swr.dashboard.get.useSWR()

	return (
		<Layout asideContent={data?.onboarding.some(x => !x.isCompleted) && <Onboarding tasks={data.onboarding} />}>
			<PageTitle>Dashboard</PageTitle>
			<Greeting>Welcome back, {user.firstName}</Greeting>
			{error ? (
				<p>Failed to load dashboard data.</p>
			) : data === undefined ? (
				<PartialLoadingPage />
			) : (
				<Cards>
					<StatCard
						color="#E5F6FF"
						label="Sales (last 30 days)"
						value={data.sales.value}
						unit={data.sales.currency}
						trend={data.sales.differenceFromLast}
					/>
					{data.visits && (
						<StatCard
							color="#FFF0DD"
							label="Visits (last 30 days)"
							value={data.visits.value}
							trend={data.visits.differenceFromLast}
						/>
					)}
					<DomainCard />
					<DemoVideoCard />
				</Cards>
			)}
		</Layout>
	)
}

const Greeting = styled.p`
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 16px;
`

const Cards = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
`

export default HomePage
