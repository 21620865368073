import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { type CSSProperties, type FC } from "react"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import VideoPlayer from "@forento/shared/components/VideoPlayer"
import { type Video as VideoType } from "@forento/shared/models/video"
import { toDateTimeString } from "@forento/shared/utilities/date"

import Button from "~/components/Button"
import { DetailedListItem } from "~/components/DetailedList"
import { DragIndicatorIcon, EditIcon, UploadIcon } from "~/components/Icon"
import routes from "~/utilities/routes"
import { lightTextColor } from "~/utilities/styles"

const Video: FC<{ video: VideoType }> = ({ video }) => {
	const { setNodeRef, transform, transition, attributes, listeners } = useSortable({ id: video.id })

	const style: CSSProperties = { transform: CSS.Transform.toString(transform), transition }

	return (
		<Container ref={setNodeRef} style={style}>
			<VideoPlayerContainer>
				{video.uploadCompleteDate !== null ? (
					<VideoPlayer video={video} />
				) : (
					<UploadButton onClick={routes.video.upload(video.id)}>
						<UploadButtonIcon />
						Upload
					</UploadButton>
				)}
			</VideoPlayerContainer>
			<Details>
				<Title>{video.title}</Title>
				<Description>{video.shortDescription}</Description>
				<Footer>
					{video.playableDate === null && <FooterText>Processing...</FooterText>}
					<FooterText>{toDateTimeString(video.createDate)}</FooterText>
				</Footer>
			</Details>
			<ActionsContainer>
				<Tooltip tooltip="Edit video">
					<EditButton onClick={routes.video.edit(video.id)}>
						<EditIcon />
					</EditButton>
				</Tooltip>
				<DragButton {...attributes} {...listeners}>
					<DragIndicatorIcon />
				</DragButton>
			</ActionsContainer>
		</Container>
	)
}

const Container = styled(DetailedListItem)`
	display: flex;
	gap: 16px;
`

const VideoPlayerContainer = styled.div`
	flex: 0 0 250px;
`

const UploadButton = styled(Button).attrs({ variant: "primary" })`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	justify-content: center;
	align-items: center;
`

const UploadButtonIcon = styled(UploadIcon)`
	width: 24px;
	height: 24px;
`

const Details = styled.div`
	flex: 1 0 0;
	display: flex;
	flex-direction: column;
	gap: 4px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 20px;
	text-align: left;
`

const Description = styled.p`
	flex: 1;
`

const Footer = styled.div`
	display: flex;
	gap: 16px;
	align-items: flex-end;
`

const FooterText = styled.p`
	font-size: 14px;
	color: ${lightTextColor};
`

const ActionsContainer = styled.div`
	flex: 0 0 auto;
	display: flex;
	align-items: center;
`

const EditButton = styled(Button)`
	width: 24px;
	height: 24px;
`

const DragButton = styled.div`
	width: 24px;
	height: 24px;
	cursor: grab;
`

export default Video
