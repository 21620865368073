import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { type Conversation as ConversationType } from "@forento/shared/models/community"

import Button from "~/components/Button"
import { toRelativeDateString } from "~/utilities/date"
import routes from "~/utilities/routes"

const Conversation: React.FC<{ conversation: ConversationType }> = ({ conversation }) => (
	<Container key={conversation.otherProfile.id} onClick={routes.community.message(conversation.otherProfile.id)}>
		<CommunityProfileAvatar profile={conversation.otherProfile} />
		<Details>
			<Receiver>
				{conversation.otherProfile.firstName} {conversation.otherProfile.lastName}
			</Receiver>
			<Message>
				{toRelativeDateString(conversation.lastMessage.sendDate)}: {conversation.lastMessage.text}
			</Message>
		</Details>
	</Container>
)

const Container = styled(Button)`
	display: flex;
	align-items: center;
	gap: 16px;
	text-align: left;
`

const Details = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2px;
`

const Receiver = styled.h2`
	font-weight: 600;
	font-size: 16px;
`

const Message = styled.p`
	font-size: 14px;
	white-space: nowrap;
	text-overflow: ellipsis;
`

export default Conversation
