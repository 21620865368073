import styled from "styled-components"

import InputField from "~/components/InputField"

export const Section = styled.div`
	width: 100%;
	max-width: 550px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`

export const SectionTitle = styled.h3`
	font-weight: 600;
	font-size: 24px;
`

export const StyledInputField = styled(InputField)`
	width: 100%;
`
