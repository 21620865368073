import { type FC, useState, useMemo, useEffect, type ComponentType } from "react"
import { Portal } from "react-portal"
import styled, { css, keyframes } from "styled-components"

import Button from "~/components/Button"
import { dangerColor } from "~/utilities/styles"

const width = 200

type Option = { label: string; onClick(): void; icon?: ComponentType; isDanger?: boolean }
type State = "idle" | "open" | "closed"
type ButtonRef = HTMLButtonElement & HTMLDivElement & HTMLAnchorElement

export default function useDropdownMenu(options: Option[]) {
	const [state, setState] = useState<State>("idle")
	const [buttonElement, setButtonElement] = useState<ButtonRef | null>(null)

	useEffect(() => {
		if (state !== "open") return

		function handleClick(event: MouseEvent) {
			if (event.target instanceof Node && buttonElement?.contains(event.target)) return
			setState("closed")
		}

		document.addEventListener("click", handleClick)

		return () => {
			document.removeEventListener("click", handleClick)
		}
	}, [state, buttonElement])

	return {
		button: { onClick: () => setState(state => (state === "open" ? "closed" : "open")), ref: setButtonElement },
		component:
			state !== "idle" ? <Component state={state} options={options} buttonElement={buttonElement} /> : null,
	}
}

type Props = { state: State; options: Option[]; buttonElement: ButtonRef | null }
const Component: FC<Props> = ({ state, options, buttonElement }) => {
	const position = useMemo(() => {
		if (buttonElement === null || state === "idle") return { x: 0, y: 0 }
		const bounds = buttonElement.getBoundingClientRect()
		return {
			x: bounds.x + bounds.width / 2 - width / 2,
			y: window.scrollY + bounds.y + bounds.height + 8,
		}
	}, [state, buttonElement])

	if (buttonElement === null) return null

	return (
		<Portal>
			<Container $state={state} $x={position.x} $y={position.y}>
				{options.map((option, index) => (
					<Option key={index} isDanger={option.isDanger} onClick={option.onClick}>
						{option.icon && <OptionIcon as={option.icon} />}
						{option.label}
					</Option>
				))}
			</Container>
		</Portal>
	)
}

const openAnimation = keyframes`
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
`

const closeAnimation = keyframes`
	from {
		transform: scale(1);
	}
	to {
		transform: scale(0);
	}
`

const Container = styled.div<{ $state: State; $x: number; $y: number }>`
	position: absolute;
	width: ${width}px;
	top: ${props => props.$y}px;
	left: ${props => props.$x}px;
	transform: scale(0);
	transform-origin: top center;
	box-shadow: 0 20px 40px -2px rgba(0, 0, 0, 0.16);
	border-radius: 8px;
	overflow: hidden;

	${props =>
		props.$state === "open"
			? css`
					animation: ${openAnimation} 0.15s forwards;
				`
			: props.$state === "closed"
				? css`
						animation: ${closeAnimation} 0.15s forwards;
					`
				: ""}
`

const Option = styled(Button)<{ isDanger?: boolean }>`
	width: 100%;
	padding: 12px 24px;
	background-color: white;
	transition: 0.1s background-color;
	display: flex;
	gap: 8px;
	align-items: center;

	${props =>
		props.isDanger &&
		css`
			color: ${dangerColor};
		`}

	&:hover {
		background-color: #f2f2f2;
	}

	&:active {
		background-color: #e6e6e6;
	}
`

const OptionIcon = styled.div`
	width: 24px;
	height: 24px;
`
