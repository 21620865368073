import { useState } from "react"

import Layout, { PageTitle } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useUser } from "~/contexts/UserContext"
import { swr } from "~/utilities/trpc"

import CreateModal from "./CreateModal"
import EditModal from "./EditModal"
import Memberships from "./Memberships"
import NoMemberships from "./NoMemberships"

const MembershipPage: React.FC = () => {
	const user = useUser()

	const { data: memberships, error, mutate } = swr.membership.list.useSWR()

	const [isCreateModalOpen, setCreateModalOpen] = useState(false)
	const [editModal, setEditModal] = useState<{ isOpen: boolean; membershipId: number | null }>({
		isOpen: false,
		membershipId: null,
	})

	async function handleSubmitted() {
		if (memberships?.length === 0) await user.reload()
		await mutate()
	}

	return (
		<Layout>
			<CreateModal
				isOpen={isCreateModalOpen}
				onClose={() => setCreateModalOpen(false)}
				onSubmitted={handleSubmitted}
			/>
			<EditModal
				membershipId={editModal.membershipId}
				isOpen={editModal.isOpen}
				onClose={() => setEditModal(current => ({ ...current, isOpen: false }))}
				onSubmitted={handleSubmitted}
			/>
			<PageTitle>Membership</PageTitle>
			{error ? (
				<p>Failed to fetch memberships.</p>
			) : memberships === undefined ? (
				<PartialLoadingPage />
			) : memberships.length > 0 ? (
				<Memberships
					memberships={memberships}
					onCreateTier={() => setCreateModalOpen(true)}
					onEditMembership={membershipId => setEditModal({ isOpen: true, membershipId })}
				/>
			) : (
				<NoMemberships onCreateTier={() => setCreateModalOpen(true)} />
			)}
		</Layout>
	)
}

export default MembershipPage
