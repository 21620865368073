import { type FC } from "react"
import styled from "styled-components"

import { type SubscriptionPaymentInterval } from "@forento/shared/models/payment"

import Button from "~/components/Button"
import { CheckIcon } from "~/components/Icon"
import { primaryColor } from "~/utilities/styles"

type Props = {
	id: number | null
	title: string
	description: string
	usps: string[]
	price: { amount: number; interval: SubscriptionPaymentInterval } | null
	onSelect(): void
	submittingPlanId?: number
}

const Plan: FC<Props> = ({ id, title, description, usps, price, onSelect, submittingPlanId }) => {
	return (
		<Container>
			<Title>{title}</Title>
			<Price>
				{price !== null ? (
					<>
						${price.interval === "year" ? price.amount / 12 : price.amount}
						<Interval>/month</Interval>
					</>
				) : (
					"Free"
				)}
			</Price>
			<SalesCommission>{price === null ? 20 : 0}% sales commission</SalesCommission>
			<Description>{description}</Description>
			<Divider />
			<Usps>
				{usps.map((usp, index) => (
					<Usp key={index}>
						<UspIcon />
						<UspLabel>{usp}</UspLabel>
					</Usp>
				))}
			</Usps>
			{price !== null ? (
				<Button
					variant="primary"
					onClick={onSelect}
					isDisabled={submittingPlanId !== undefined}
					isLoading={submittingPlanId === id}
				>
					Start 30-day free trial
				</Button>
			) : (
				<Button variant="primary" isDisabled>
					Currently active
				</Button>
			)}
		</Container>
	)
}

const Container = styled.div`
	flex: 0 0 ${64 * 5}px;
	background-color: white;
	border: 1px solid #e4e7ec;
	box-shadow:
		0 12px 16px -4px rgba(16, 24, 40, 0.1),
		0 4px 6px -2px rgba(16, 24, 40, 0.05);
	border-radius: 12px;
	padding: 24px;
	display: flex;
	flex-direction: column;
`

const Title = styled.h2`
	font-size: 24px;
	font-weight: 700;
	text-align: center;
	color: ${primaryColor};
	margin-bottom: 16px;
`

const Price = styled.p`
	font-weight: 600;
	font-size: 38px;
	line-height: 1;
	text-align: center;
	margin-bottom: 4px;
`

const SalesCommission = styled.p`
	color: #333333;
	text-align: center;
	margin-bottom: 16px;
`

const Interval = styled.span`
	font-size: 18px;
	font-weight: normal;
`

const Description = styled.p`
	font-size: 14px;
	text-align: center;
	margin-bottom: 16px;
`

const Divider = styled.hr`
	width: 100%;
	border: none;
	border-bottom: 1px solid #dedede;
	margin-bottom: 16px;
`

const Usps = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-bottom: 16px;
`

const Usp = styled.div`
	display: flex;
	gap: 8px;
`

const UspIcon = styled(CheckIcon).attrs({ light: true })`
	flex: 0 0 auto;
	width: 18px;
	height: 18px;
	color: ${primaryColor};
`

const UspLabel = styled.p`
	font-size: 14px;
`

export default Plan
