import { type FC, type ReactNode } from "react"
import styled, { css } from "styled-components"

import Button from "~/components/Button"
import GoogleSigninButtonBase from "~/components/GoogleSigninButton"
import { dangerColor, primaryLightColor } from "~/utilities/styles"

import logoImage from "~/assets/logo.svg"

const Layout: FC<{ children: ReactNode }> = ({ children }) => <Container>{children}</Container>

const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	background-color: ${primaryLightColor};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 16px;
`

export const Logo = styled.img.attrs({ src: logoImage })`
	width: 150px;
	margin-bottom: 46px;
`

export const Title = styled.h1`
	font-weight: 600;
	font-size: 26px;
	text-align: center;
`

export const Text = styled.p`
	font-weight: 500;
	font-size: 16px;
	text-align: center;
`

export const FieldInfoText = styled.p`
	font-weight: 500;
	font-size: 14px;
	color: #828282;
	margin-top: 8px;
`

export const Content = styled.div<{ $uncappedWidth?: boolean }>`
	width: 100%;
	${props =>
		!props.$uncappedWidth &&
		css`
			max-width: 360px;
		`}
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 24px;
`

export const Inputs = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`

export const InputsRow = styled.div`
	display: flex;
	gap: 20px;
`

export const NavigationText = styled.p`
	font-size: 14px;
	color: #667085;
	text-align: center;
`

export const NavigationButton = styled(Button)`
	display: inline;
	font-size: 14px;
	color: #6941c6;
	align-self: flex-end;
`

export const GoogleSigninButton = styled(GoogleSigninButtonBase)`
	align-self: center;
`

export const ErrorText = styled(Text)`
	color: ${dangerColor};
`

export { default as BackButton } from "./BackButton"

export default Layout
