import { useState } from "react"

import Form from "@forento/shared/components/Form"

import { SubmitButton } from "~/components/Button"
import { useAlert } from "~/contexts/AlertContext"
import trpc from "~/utilities/trpc"

import { SectionTitle, Section, StyledInputField } from "./styles"

const ChangePassword: React.FC = () => {
	const alert = useAlert()

	const [currentPassword, setCurrentPassword] = useState("")
	const [newPassword, setNewPassword] = useState("")
	const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")

	const [isSubmitting, setSubmitting] = useState(false)

	const handleSubmit = async () => {
		if (currentPassword.length === 0 || newPassword.length === 0) {
			alert.show("Error", "Please fill in all fields.")
			return
		}

		if (newPassword !== newPasswordConfirmation) {
			alert.show("Error", "Passwords don't match.")
			return
		}

		setSubmitting(true)
		try {
			const { status } = await trpc.user.setPassword.mutate({ current: currentPassword, new: newPassword })
			if (status === "invalid-current") {
				alert.show("Error", "Incorrect current password.")
			} else {
				alert.show("Password changed", "Password has been changed successfully.")
			}
		} catch (error) {
			console.error(error)
			await alert.show("Error", "Failed to change password. Please try again later.")
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Section as={Form} onSubmit={handleSubmit}>
			<SectionTitle>Change password</SectionTitle>
			<StyledInputField
				inputType="password"
				label="Your current password"
				value={currentPassword}
				onChange={setCurrentPassword}
				autoComplete="current-password"
			/>
			<StyledInputField
				inputType="password"
				label="New password"
				value={newPassword}
				onChange={setNewPassword}
				autoComplete="new-password"
			/>
			<StyledInputField
				inputType="password"
				label="New password confirmation"
				value={newPasswordConfirmation}
				onChange={setNewPasswordConfirmation}
				autoComplete="new-password"
			/>
			<SubmitButton variant="primary" isLoading={isSubmitting}>
				Change password
			</SubmitButton>
		</Section>
	)
}

export default ChangePassword
