import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { toCountFormat } from "@forento/shared/utilities/number"

import Button from "~/components/Button"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Table from "~/components/Table"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

const Leaderboard: React.FC = () => {
	const { data: profiles, error } = swr.community.listLeaderboard.useSWR()

	return (
		<>
			{error ? (
				<p>Failed to load leaderboard.</p>
			) : profiles === undefined ? (
				<PartialLoadingPage />
			) : (
				<Table
					isCompact
					columns={[{ label: "#", flex: { basis: 20, grow: 0 } }, "Name", "Level", "Total points"]}
					data={profiles.map((profile, index) => [
						<Cell>{index + 1}</Cell>,
						<Cell as={Button} onClick={routes.community.profile(profile.id)}>
							<CommunityProfileAvatar profile={profile} />
							<ProfileName>
								{profile.firstName} {profile.lastName}
							</ProfileName>
						</Cell>,
						<Cell>{profile.gamification.currentLevel?.label ?? "None"}</Cell>,
						<Cell>{toCountFormat(profile.gamification.points)}</Cell>,
					])}
				/>
			)}
		</>
	)
}

const Cell = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	gap: 12px;
`

const ProfileName = styled.div`
	font-weight: 600;
	font-size: 14px;
`

export default Leaderboard
