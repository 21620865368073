import { type FC } from "react"
import styled from "styled-components"

import { primaryLightColor } from "~/utilities/styles"

import Task from "./Task"

type Props = {
	tasks: {
		title: string
		callToAction: string
		onClick: string
		isCompleted: boolean
	}[]
}

const Onboarding: FC<Props> = ({ tasks }) => (
	<Container>
		<Title>Onboarding</Title>
		<Content>
			<ContentTitle>
				<TodoIcon />
				To-do List
			</ContentTitle>
			<List>
				{tasks.map((task, index) => (
					<Task key={index} order={index + 1} {...task} />
				))}
			</List>
		</Content>
	</Container>
)

const Container = styled.div`
	flex: 0.5;
	background-color: ${primaryLightColor};
	padding: 34px 30px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 32px;
	margin-bottom: 20px;
`

const Content = styled.div`
	background-color: white;
	border-radius: 12px;
	padding: 16px 20px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const ContentTitle = styled.h3`
	font-weight: 600;
	font-size: 14px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	gap: 8px;
`

const TodoIcon: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="20" height="20">
		<path d="M26 17.5V14.5H44V17.5ZM26 33.5V30.5H44V33.5ZM11.1 21.3 4 14.2 6.1 12.1 11.1 17.05 20.05 8.1 22.15 10.25ZM11.1 37.3 4 30.2 6.1 28.1 11.1 33.05 20.05 24.1 22.15 26.25Z" />
	</svg>
)

const List = styled.div``

export default Onboarding
