import { useEffect, useState } from "react"
import styled from "styled-components"

import { type CoursePageAttachment } from "@forento/shared/models/course"

import Button from "~/components/Button"
import { AttachmentIcon, DeleteIcon } from "~/components/Icon"
import { dangerColor, primaryColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

import AddAttachment from "./AddAttachment"

const Attachments: React.FC<{ pageId: number; attachments: CoursePageAttachment[] }> = ({
	pageId,
	attachments: initialAttachments,
}) => {
	const [attachments, setAttachments] = useState(initialAttachments)

	useEffect(() => {
		setAttachments(initialAttachments)
	}, [pageId, initialAttachments])

	const handleDeleteAttachmentById = async (pageAttachmentId: number) => {
		await trpc.course.deletePageAttachment.mutate(pageAttachmentId)
		setAttachments(current => current.filter(attachment => attachment.id !== pageAttachmentId))
	}

	return (
		<Container>
			{attachments.map(attachment => (
				<Attachment key={attachment.id}>
					<StyledAttachmentIcon />
					<AttachmentLabel>
						{attachment.label} ({attachment.fileType})
					</AttachmentLabel>
					<DeleteAttachment onClick={() => handleDeleteAttachmentById(attachment.id)}>
						<DeleteIcon />
					</DeleteAttachment>
				</Attachment>
			))}
			<AddAttachment pageId={pageId} onAdd={attachment => setAttachments(current => [...current, attachment])} />
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Attachment = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	border: 1px dashed ${primaryColor};
	border-radius: 8px;
	padding: 8px;
`

const StyledAttachmentIcon = styled(AttachmentIcon)`
	flex: 0 0 auto;
	width: 24px;
	height: 24px;
`

const AttachmentLabel = styled.p`
	flex: 1;
	font-weight: 500;
	font-size: 16px;
`

const DeleteAttachment = styled(Button)`
	flex: 0 0 auto;
	width: 24px;
	height: 24px;
	color: ${dangerColor};
`

export default Attachments
