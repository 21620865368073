import { type FC, useState } from "react"
import { Navigate, useNavigate } from "react-router"
import styled from "styled-components"

import { type Plan as PlanType } from "@forento/shared/models/payment"
import { getPlansAnnualDiscount } from "@forento/shared/utilities/payment"

import Button from "~/components/Button"
import { ArrowIcon } from "~/components/Icon"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import PaymentIntervalSelector from "~/components/PaymentIntervalSelector"
import { usePlatform } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { primaryLightColor } from "~/utilities/styles"
import trpc, { swr } from "~/utilities/trpc"

import Plan from "./Plan"

const SelectPlanPage: FC = () => {
	const platform = usePlatform()!
	const navigate = useNavigate()

	const [isMonthlySelected, setMonthlySelected] = useState(false)
	const [submittingPlanId, setSubmittingPlanId] = useState<number>()

	const plans = swr.payment.creator.getPlans.useSWR()

	const nextStepUrl = routes.main.index()

	if (platform.plan.type === "appsumo" || platform.plan.type === "viededingue") {
		return <Navigate to={nextStepUrl} />
	}

	async function handleSelectPlan(plan: PlanType) {
		if (plan.id === null) throw new Error("Chosen plan's ID is null!")

		setSubmittingPlanId(plan.id)
		try {
			const response = await trpc.payment.creator.setPlan.mutate({
				planId: plan.id,
				paymentInterval: isMonthlySelected ? "month" : "year",
				redirect: {
					cancel: routes.account.selectPlan(),
					success: nextStepUrl,
				},
				source: "onboarding",
			})
			if (response.redirectUrl === null) {
				return navigate(nextStepUrl)
			}

			window.location.href = response.redirectUrl
		} catch (error) {
			console.error(error)
			setSubmittingPlanId(undefined)
		}
	}

	return (
		<Wrapper>
			<Container>
				<Title>Select Plan</Title>
				{plans.error ? (
					<p>Failed to load plans.</p>
				) : plans.data === undefined ? (
					<PartialLoadingPage />
				) : (
					<>
						<StyledIntervalSelector
							annualDiscount={getPlansAnnualDiscount(plans.data)}
							isMonthlySelected={isMonthlySelected}
							setMonthlySelected={setMonthlySelected}
						/>
						<Plans>
							{plans.data.map(plan => (
								<Plan
									key={plan.id}
									id={plan.id}
									title={plan.title}
									description={plan.description}
									usps={plan.usps}
									price={(() => {
										const price = isMonthlySelected
											? (plan.prices?.platform?.month ?? plan.prices?.current.month)
											: (plan.prices?.platform?.year ?? plan.prices?.current.year)

										if (price === undefined) return null

										return { amount: price.amount, interval: isMonthlySelected ? "month" : "year" }
									})()}
									onSelect={() => handleSelectPlan(plan)}
									submittingPlanId={submittingPlanId}
								/>
							))}
						</Plans>
						<ScrollText>
							Scroll to explore more plans <ScrollIcon />
						</ScrollText>
						<SkipButton variant="link" onClick={nextStepUrl}>
							I want to skip free trial and continue with the free plan
						</SkipButton>
					</>
				)}
			</Container>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	width: 100%;
	min-height: 100vh;
	background-color: ${primaryLightColor};
	display: flex;
	justify-content: center;
	align-items: center;
`

const Container = styled.div`
	width: 100%;
	max-width: ${64 * 5 * 4 + 16 * 3}px;
	display: flex;
	flex-direction: column;
`

const Title = styled.h1`
	align-self: center;
	font-weight: 600;
	font-size: 26px;
	text-align: center;
	margin-bottom: 16px;
`

const StyledIntervalSelector = styled(PaymentIntervalSelector)`
	margin-bottom: 16px;
`

const Plans = styled.div`
	display: flex;
	gap: 16px;
	overflow-x: auto;
	margin-bottom: 16px;

	@media (min-width: 600px) {
		margin-bottom: 32px;
	}
`

const ScrollText = styled.p`
	align-self: center;
	display: flex;
	align-items: center;
	gap: 8px;
	text-align: center;
	margin-bottom: 32px;

	@media (min-width: 600px) {
		display: none;
	}
`

const ScrollIcon = styled(ArrowIcon)`
	width: 20px;
	height: 20px;
`

const SkipButton = styled(Button)`
	align-self: center;
	text-align: center;
	margin: 0 16px;
`

export default SelectPlanPage
