import type React from "react"
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import styled from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"

import { primaryColor } from "~/utilities/styles"

import ChartCategorySelector, { type ChartCategory } from "./ChartCategorySelector"

const Chart: React.FC<{
	label: string
	unit?: string
	values: { name: string; value: number }[]
	periodTitle: string
	selectedChartCategory: ChartCategory
	setSelectedChartCategory: (newValue: ChartCategory) => void
}> = ({ label, unit, values, periodTitle, selectedChartCategory, setSelectedChartCategory }) => (
	<Container>
		<Header>
			<ChartCategorySelector selectedValue={selectedChartCategory} onChange={setSelectedChartCategory} />
			<HeaderGroup>
				{values.length > 0 && (
					<LegendContainer>
						<LegendTitle>
							<LegendColor />
							{periodTitle}
						</LegendTitle>
						<LegendTotalValue>
							{values.reduce((value, current) => Math.floor(value + current.value * 100), 0) / 100}
							{unit !== undefined ? ` ${unit}` : ""}
						</LegendTotalValue>
					</LegendContainer>
				)}
			</HeaderGroup>
		</Header>
		<AspectRatio aspectRatio={3} width={{ unit: "percent", value: 100 }}>
			<ResponsiveContainer>
				<LineChart data={values}>
					<XAxis dataKey="name" tickMargin={10} />
					<YAxis dataKey="value" width={20} tickMargin={10} />
					<Tooltip />
					<CartesianGrid stroke="#EBEBEB" vertical={false} />
					<Line
						type="monotone"
						dataKey="value"
						name={label}
						unit={unit !== undefined ? ` ${unit}` : undefined}
						stroke={primaryColor}
						yAxisId={0}
						dot={false}
					/>
				</LineChart>
			</ResponsiveContainer>
		</AspectRatio>
	</Container>
)

const Container = styled.div`
	grid-area: chart;
	background-color: #f9f5ff;
	border-radius: 12px;
	padding: 32px;
`

const Header = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	margin-bottom: 16px;
`

const HeaderGroup = styled.div`
	display: flex;
	align-items: flex-start;
`

const LegendContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 48px;
`

const LegendTitle = styled.div`
	font-size: 14px;
	display: flex;
	align-items: center;
	margin-bottom: 8px;
`

const LegendColor = styled.div`
	width: 20px;
	height: 6px;
	background-color: ${primaryColor};
	border-radius: 6px;
	margin-right: 10px;
`

const LegendTotalValue = styled.p`
	font-size: 16px;
	font-weight: 700;
`

export default Chart
