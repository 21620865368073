import { type DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { useLayoutEffect, useState, type FC } from "react"
import styled from "styled-components"

import { type ThinMembership } from "@forento/shared/models/membership"

import Button from "~/components/Button"
import SortableListContext from "~/contexts/SortableListContext"
import trpc from "~/utilities/trpc"

import Membership from "./Membership"
import illustration from "./illustration.svg"

type Props = { memberships: ThinMembership[]; onCreateTier(): void; onEditMembership(priceId: number): void }
const Memberships: FC<Props> = ({ memberships, onCreateTier, onEditMembership }) => {
	const [sortOrder, setSortOrder] = useState<number[]>([])

	useLayoutEffect(() => {
		setSortOrder(memberships.map(session => session.id))
	}, [memberships])

	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event
		if (!over || active.id === over.id) return

		const newItems = arrayMove(sortOrder, sortOrder.indexOf(Number(active.id)), sortOrder.indexOf(Number(over.id)))
		setSortOrder(newItems)

		for (const membership of memberships) {
			trpc.membership.update.mutate({ id: membership.id, data: { sortOrder: newItems.indexOf(membership.id) } })
		}
	}

	return (
		<Container>
			<SortableListContext type="grid" items={sortOrder} onDragEnd={handleDragEnd}>
				{memberships
					.toSorted((a, b) => sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id))
					.map(membership => (
						<Membership
							key={membership.id}
							membership={membership}
							onEdit={() => onEditMembership(membership.id)}
						/>
					))}
			</SortableListContext>
			<CreateTier.Container>
				<CreateTier.Illustration src={illustration} alt="Illustration" />
				<CreateTier.Title>More options</CreateTier.Title>
				<CreateTier.Text>Give your users more options by creating a new tier.</CreateTier.Text>
				<Button variant="primary" onClick={onCreateTier}>
					Create new tier
				</Button>
			</CreateTier.Container>
		</Container>
	)
}

const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, 300px);
	grid-auto-rows: 1fr;
	gap: 16px;
`

const CreateTier = {
	Container: styled.div`
		background-color: #f9f5ff;
		border-radius: 8px;
		padding: 42px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 12px;
	`,
	Illustration: styled.img`
		width: 42px;
	`,
	Title: styled.h2`
		font-weight: 600;
		font-size: 16px;
	`,
	Text: styled.p`
		text-align: center;
	`,
}

export default Memberships
