import { useState } from "react"

import Form from "@forento/shared/components/Form"

import { SubmitButton } from "~/components/Button"
import InputField from "~/components/InputField"
import { useAlert } from "~/contexts/AlertContext"
import routes from "~/utilities/routes"
import trpc, { getInputErrors } from "~/utilities/trpc"

import Layout, { BackButton, Content, Logo, Text, Title } from "./Layout"

const ResetPasswordPage: React.FC = () => {
	const alert = useAlert()

	const [email, setEmail] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)
	const [isEmailSent, setEmailSent] = useState(false)

	const handleResetPassword = async () => {
		if (email.trim().length === 0) return

		setSubmitting(true)
		try {
			const { status } = await trpc.user.resetCreatorPassword.mutate(email.trim())
			if (status !== "success") {
				await alert.show("Invalid email", "That email address isn't registered with Forento.")
				return
			}
			setEmailSent(true)
		} catch (error) {
			const errors = getInputErrors(error)
			if ("root" in errors) {
				await alert.show("Invalid email", "That email address isn't registered with Forento.")
			}
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout>
			<Logo />
			<Content as={Form} onSubmit={handleResetPassword}>
				<Title>Forgot password?</Title>
				<Text>Don't worry, we'll send you reset instructions.</Text>
				{isEmailSent ? (
					<Text>
						We've sent a password reset email to your email address <strong>{email.trim()}</strong>.
					</Text>
				) : (
					<>
						<InputField
							inputType="email"
							label="Email"
							placeholder="Enter your email"
							value={email}
							onChange={setEmail}
							autoComplete="current-password"
						/>
						<SubmitButton variant="primary" isLoading={isSubmitting}>
							Reset password
						</SubmitButton>
					</>
				)}
				<BackButton onClick={routes.account.signin()}>Back to sign in</BackButton>
			</Content>
		</Layout>
	)
}

export default ResetPasswordPage
