import { type Payout, type PayoutStatus } from "@forento/shared/models/payment"
import { toPriceString } from "@forento/shared/utilities/currency"
import { toDateTimeString } from "@forento/shared/utilities/date"

import Table from "~/components/Table"

const PayoutsTab: React.FC<{ payouts: Payout[] }> = ({ payouts }) => (
	<Table
		columns={["Date", "Status", "Total"]}
		data={payouts.map(payout => [
			toDateTimeString(payout.date),
			<PayoutStatusText status={payout.status} />,
			toPriceString(payout),
		])}
	/>
)

const PayoutStatusText: React.FC<{ status: PayoutStatus }> = ({ status }) => {
	if (status === "paid") return <>Paid</>
	if (status === "in_transit") return <>In transit</>
	if (status === "canceled") return <>Canceled</>
	if (status === "failed") return <>Failed</>
	else if (status === "pending") return <>Pending payment</>
	else return <>Unknown</>
}

export default PayoutsTab
