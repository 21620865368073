import styled, { keyframes } from "styled-components"
import tinycolor from "tinycolor2"

import { primaryColor } from "~/utilities/styles"

const LoadingBar: React.FC = () => (
	<Container>
		<Value />
	</Container>
)

const animation = keyframes`
	0% {
		transform: translateX(0) scaleX(0);
	}
	40% {
		transform: translateX(0) scaleX(0.4);
	}
	100% {
		transform: translateX(100%) scaleX(0.5);
	}
`

const Container = styled.div`
	width: 100%;
	height: 8px;
	border-radius: 4px;
	background-color: ${tinycolor(primaryColor).lighten(35).toString()};
	overflow: hidden;
`

const Value = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${primaryColor};
	animation: ${animation} 1s infinite linear;
	transform-origin: 0% 50%;
`

export default LoadingBar
