import { useState } from "react"
import { useNavigate } from "react-router"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"

import { SubmitButton } from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import InputField from "~/components/InputField"
import { useDisableGoogleSignIn } from "~/contexts/GoogleSignInContext"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { dangerColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

import Layout, {
	Content,
	FieldInfoText,
	Inputs,
	InputsRow,
	Logo,
	NavigationButton,
	NavigationText,
	Text,
	Title,
} from "../Layout"
import vieDeDingueLogo from "./vieDeDingueLogo.png"

const VieDeDingueSignupPage: React.FC = () => {
	const user = useUser()
	const navigate = useNavigate()
	useDisableGoogleSignIn()

	const [key, setKey] = useState<string>("")
	const [firstName, setFirstName] = useState("")
	const [lastName, setLastName] = useState("")
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [passwordConfirmation, setPasswordConfirmation] = useState("")
	const [platformName, setPlatformName] = useState("")
	const [isAccountEmailChecked, setAccountEmailChecked] = useState(true)
	const [contactEmail, setContactEmail] = useState("")

	const [error, setError] = useState<string>()
	const [isSubmitting, setSubmitting] = useState(false)

	const handleSignup = async () => {
		setSubmitting(true)
		setError(undefined)

		if (password !== passwordConfirmation) {
			setError("Passwords don't match.")
			setSubmitting(false)
			return
		}

		const body = {
			firstName: firstName.trim(),
			lastName: lastName.trim(),
			email: email.trim(),
			password,
			platformName: platformName.trim(),
			contactEmail: isAccountEmailChecked ? email.trim() : contactEmail.trim(),
			vieDeDingueKey: key,
		}

		if ([body.firstName, body.lastName, body.password, body.email, body.platformName].some(x => x.length === 0)) {
			setError("Please fill in all fields.")
			setSubmitting(false)
			return
		}

		try {
			const response = await trpc.user.registerCreatorVieDeDingue.mutate(body)
			if (response.status === "email-taken") {
				setError("That e-mail address is already associated with an account.")
				setSubmitting(false)
				return
			}
			if (response.status === "invalid-key") {
				setError("That VieDeDingue key is invalid or already in use.")
				setSubmitting(false)
				return
			}

			await user.reload()

			navigate(routes.account.createPlatform())
		} catch {
			setError("Something went wrong. Please try again later.")
			setSubmitting(false)
		}
	}

	return (
		<Layout>
			<LogoContainer>
				<StyledLogo />
				x
				<VieDeDingueLogo />
			</LogoContainer>
			<Content>
				<Title>
					Register a new account
					<br />
					via VieDeDingue
				</Title>
				{error !== undefined && <ErrorText>{error}</ErrorText>}
				<Content as={Form} onSubmit={handleSignup}>
					<Inputs>
						<InputField label="VieDeDingue key" value={key} onChange={setKey} />
						<InputsRow>
							<InputField
								label="First name"
								value={firstName}
								onChange={setFirstName}
								autoComplete="given-name"
							/>

							<InputField
								label="Last name"
								value={lastName}
								onChange={setLastName}
								autoComplete="family-name"
							/>
						</InputsRow>

						<InputField
							inputType="email"
							label="Email"
							value={email}
							onChange={setEmail}
							autoComplete="email"
						/>

						<InputField
							inputType="password"
							label="Password"
							value={password}
							onChange={setPassword}
							autoComplete="new-password"
						/>

						<InputField
							inputType="password"
							label="Confirm password"
							value={passwordConfirmation}
							onChange={setPasswordConfirmation}
							autoComplete="new-password"
						/>

						<InputField label="Platform name" value={platformName} onChange={setPlatformName} />

						<div>
							<StyledCheckbox
								label="Use my account's email address"
								isChecked={isAccountEmailChecked}
								onChange={setAccountEmailChecked}
							/>
							{isAccountEmailChecked ? (
								<InputField label="Contact email address" value={email} disabled />
							) : (
								<InputField
									label="Contact email address"
									placeholder="e.g. contact@davescourses.com"
									value={contactEmail}
									onChange={setContactEmail}
								/>
							)}
							<FieldInfoText>
								The contact email be public to your users, and will also be used in your terms of
								service.
							</FieldInfoText>
						</div>
					</Inputs>

					<NavigationText>
						By signing up you agree to our{" "}
						<NavigationButton onClick="https://forento.io/policy/terms-of-service" newTab>
							Terms of Service
						</NavigationButton>
						.
					</NavigationText>

					<SubmitButton variant="primary" isLoading={isSubmitting}>
						Sign up
					</SubmitButton>

					<NavigationText>
						Already have an account?{" "}
						<NavigationButton onClick={routes.account.signin()}>Sign in</NavigationButton>
					</NavigationText>
				</Content>
			</Content>
		</Layout>
	)
}

const LogoContainer = styled.div`
	display: flex;
	gap: 32px;
	align-items: center;
	margin-bottom: 46px;
	font-weight: 600;
	font-size: 32px;
`

const StyledLogo = styled(Logo)`
	margin-bottom: 0;
`

const VieDeDingueLogo = styled.img.attrs({ src: vieDeDingueLogo })`
	width: 180px;
`

const ErrorText = styled(Text)`
	color: ${dangerColor};
`

const StyledCheckbox = styled(Checkbox)`
	margin-bottom: 8px;
`

export default VieDeDingueSignupPage
