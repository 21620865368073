import { useEffect, useState } from "react"
import styled from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"
import { getDataUrlByFile } from "@forento/shared/utilities/file"

import Button from "~/components/Button"
import Dropdown from "~/components/Dropdown"
import FileUploader from "~/components/FileUploader"
import { DeleteIcon } from "~/components/Icon"
import LoadingIndicator from "~/components/LoadingIndicator"
import trpc from "~/utilities/trpc"

interface Props {
	pageId: number
	filePath: string | null
	containImage: boolean
	setContainImage: (value: boolean) => void
}

const Image: React.FC<Props> = ({ pageId, filePath: initialFilePath, containImage, setContainImage }) => {
	const [filePath, setFilePath] = useState(initialFilePath)
	const [isDeletingImage, setDeletingImage] = useState(false)

	useEffect(() => {
		setFilePath(initialFilePath)
	}, [initialFilePath, pageId])

	const handleImageDrop = async (file: File) => {
		const { filePath } = await trpc.course.setPageImage.mutate({ pageId, dataUrl: await getDataUrlByFile(file) })
		setFilePath(filePath)
	}

	const handleDeleteImage = async () => {
		setDeletingImage(true)
		try {
			await trpc.course.deletePageImage.mutate(pageId)
			setFilePath(null)
		} finally {
			setDeletingImage(false)
		}
	}

	return (
		<>
			<AspectRatio aspectRatio={2.2}>
				{filePath !== null ? (
					<Container>
						<ImageDisplay src={filePath} alt="Image" />
						<DeleteMediaButton onClick={!isDeletingImage ? handleDeleteImage : () => {}}>
							{!isDeletingImage ? (
								<DeleteMediaIcon />
							) : (
								<DeleteMediaIcon as={LoadingIndicator} color="white" />
							)}
						</DeleteMediaButton>
					</Container>
				) : (
					<FileUploader type="image" onUpload={handleImageDrop} />
				)}
			</AspectRatio>
			{filePath !== null && (
				<Dropdown
					items={[
						{ id: "cover", title: "Show as banner" },
						{ id: "contain", title: "Show full image" },
					]}
					selectedItemId={containImage ? "contain" : "cover"}
					onChange={id => setContainImage(id === "contain")}
				/>
			)}
		</>
	)
}

const Container = styled.div`
	position: relative;
`

const ImageDisplay = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`

const DeleteMediaButton = styled(Button)`
	position: absolute;
	top: 16px;
	left: 16px;
	width: 40px;
	height: 40px;
	padding: 8px;
	background-color: black;
	border-radius: 50%;
	color: white;
`

const DeleteMediaIcon = styled(DeleteIcon)`
	width: 100%;
	height: 100%;
`

export default Image
