import { useState, type FC } from "react"
import styled from "styled-components"

import Checkbox from "~/components/Checkbox"

type Props = { option: { id: string; label: string }; isChecked: boolean; setChecked(value: boolean): Promise<void> }
const Notification: FC<Props> = ({ option, isChecked, setChecked }) => {
	const [isSubmitting, setSubmitting] = useState(false)

	return (
		<Container key={option.id}>
			<NotificationLabel>{option.label}</NotificationLabel>
			<Actions>
				<Checkbox
					isChecked={isChecked}
					isLoading={isSubmitting}
					onChange={async value => {
						setSubmitting(true)
						try {
							await setChecked(value)
						} catch (error) {
							console.error(error)
						} finally {
							setSubmitting(false)
						}
					}}
				/>
			</Actions>
		</Container>
	)
}

const Container = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eaeaea;
	border-radius: 8px;
	padding: 22px;
	display: flex;
	justify-content: space-between;
	gap: 16px;
`

const NotificationLabel = styled.h2`
	font-weight: 600;
	font-size: 20px;
	text-align: left;
`

const Actions = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`

export default Notification
