import { useState } from "react"
import { useNavigate } from "react-router"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"

import { SubmitButton } from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import InputField from "~/components/InputField"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

import Layout, { Content, FieldInfoText, Logo, Title } from "./Layout"

const SetContactEmailPage: React.FC = () => {
	const user = useUser()
	const navigate = useNavigate()

	const [isAccountEmailChecked, setAccountEmailChecked] = useState(true)
	const [contactEmail, setContactEmail] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const handleSubmit = async () => {
		if (!isAccountEmailChecked && contactEmail.trim().length === 0) return

		setSubmitting(true)
		try {
			await trpc.platform.update.mutate({ contactEmail: isAccountEmailChecked ? user.user!.email : contactEmail })
			await user.reload()

			navigate(routes.main.index())
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout>
			<Logo />
			<Content as={Form} onSubmit={handleSubmit}>
				<Title>Set contact email</Title>
				<div>
					<StyledCheckbox
						label="Use my account's email address"
						isChecked={isAccountEmailChecked}
						onChange={setAccountEmailChecked}
					/>
					{isAccountEmailChecked ? (
						<InputField
							label="Contact email address"
							value={user.user!.email}
							disabled={isAccountEmailChecked}
						/>
					) : (
						<InputField
							label="Contact email address"
							placeholder="e.g. contact@davescourses.com"
							value={contactEmail}
							onChange={setContactEmail}
							disabled={isAccountEmailChecked}
						/>
					)}
					<FieldInfoText>
						The contact email be public to your users, and will also be used in your terms of service.
					</FieldInfoText>
				</div>

				<SubmitButton variant="primary" isLoading={isSubmitting}>
					Save contact email
				</SubmitButton>
			</Content>
		</Layout>
	)
}

const StyledCheckbox = styled(Checkbox)`
	margin-bottom: 8px;
`

export default SetContactEmailPage
