import mime from "mime"
import { type FC } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"
import { supportedFileExtensions } from "@forento/shared/utilities/file"

import { DeleteIcon, ImageIcon, SubtitlesIcon, UploadFileIcon, VideoFileIcon } from "~/components/Icon"
import { primaryColor } from "~/utilities/styles"

import Button from "./Button"

type Type = "image" | "video" | "video-captions" | "spreadsheet" | "file"

interface Props {
	type: Type
	value: File | null
	onChange: (value: File | null) => void
}

const acceptByType = {
	image: Object.fromEntries(supportedFileExtensions.image.map(extension => [mime.getType(extension), []])),
	video: { "video/*": [] },
	"video-captions": { ".vtt": [], ".srt": [] },
	spreadsheet: Object.fromEntries(
		supportedFileExtensions.spreadsheet.map(extension => [mime.getType(extension), []]),
	),
	file: undefined,
} as const

const textByType = {
	image: "Click here or drag and drop to upload an image",
	video: "Click here or drag and drop to upload a video",
	"video-captions": "Click here or drag and drop to upload captions",
	spreadsheet: "Click here or drag and drop to upload a spreadsheet",
	file: "Click here or drag and drop to upload",
} as const

const iconByType = {
	image: ImageIcon,
	video: VideoFileIcon,
	"video-captions": SubtitlesIcon,
	spreadsheet: UploadFileIcon,
	file: UploadFileIcon,
} as const

const FileChooser: FC<Props> = ({ type, value, onChange }) => {
	const handleImageDrop = async (acceptedFiles: File[]) => {
		if (acceptedFiles.length === 0) return
		const file = acceptedFiles[0]
		onChange(file)
	}

	const dropzone = useDropzone({ onDrop: handleImageDrop, accept: acceptByType[type] })

	return (
		<Dropzone {...dropzone.getRootProps()}>
			<input {...dropzone.getInputProps()} />
			{dropzone.isDragActive ? (
				<InfoText>
					{dropzone.isDragReject
						? "That file type is not supported"
						: type === "file"
							? "Drop file here"
							: `Drop ${type} file here`}
				</InfoText>
			) : value !== null ? (
				<>
					<DeleteButton onClick={() => onChange(null)}>
						<DeleteButtonIcon />
					</DeleteButton>
					{value.type.startsWith("image/") ? (
						<AspectRatio aspectRatio={16 / 9} width={{ value: 100, unit: "percent" }}>
							<ImagePreview alt="Uploaded image" src={URL.createObjectURL(value)} />
						</AspectRatio>
					) : (
						<p>{value.name}</p>
					)}
				</>
			) : (
				<>
					<Icon as={iconByType[type]} />
					<InfoText>{textByType[type]}</InfoText>
				</>
			)}
		</Dropzone>
	)
}

const Dropzone = styled.div`
	border: 1px dashed ${primaryColor};
	border-radius: 8px;
	padding: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 32px;
	cursor: pointer;
	position: relative;
`

const Icon = styled.div`
	width: 64px;
	height: 64px;
	color: ${primaryColor};
`

const InfoText = styled.p`
	font-weight: 500;
	font-size: 16px;
	text-align: center;
`

const ImagePreview = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`

const DeleteButton = styled(Button)`
	position: absolute;
	top: 16px;
	left: 16px;
	width: 40px;
	height: 40px;
	padding: 8px;
	background-color: black;
	border-radius: 50%;
	color: white;
`

const DeleteButtonIcon = styled(DeleteIcon)`
	width: 100%;
	height: 100%;
`

export default FileChooser
