import Editor from "@monaco-editor/react"
import { type FC, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"
import { checkMjml } from "@forento/shared/utilities/mjml"
import { replacePlaceholders } from "@forento/shared/utilities/string"

import { SubmitButton } from "~/components/Button"
import EmailPreview from "~/components/EmailPreview"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import RadioButtons from "~/components/RadioButtons"
import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

const mjml2html = import("mjml-browser")

const options = [
	{ id: "default", label: "Use default content" },
	{ id: "custom", label: "Use custom content" },
] as const
type OptionId = (typeof options)[number]["id"]

const StudentEmailLayoutSettingsPage: FC = () => {
	const platform = usePlatform()!
	const navigate = useNavigate()
	const alert = useAlert()

	const [selectedOptionId, setSelectedOptionId] = useState<OptionId>("default")
	const [customContent, setCustomContent] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const { data: layout, error, mutate } = swr.studentEmails.getLayout.useSWR()

	useEffect(() => {
		setSelectedOptionId(layout?.isCustom ? "custom" : "default")
		setCustomContent(layout?.content ?? "")
	}, [layout])

	const contentWithPlaceholders = layout
		? replacePlaceholders({
				text: selectedOptionId === "default" ? layout.content : customContent,
				placeholders: [
					{ key: "platformName", value: platform.name },
					{ key: "primaryColor", value: platform.primaryColor.value },
				],
			})
		: null

	async function handleSave() {
		if (selectedOptionId === "custom") {
			if (!contentWithPlaceholders) return

			if (!customContent.includes("{content}")) {
				await alert.show(
					"Content not included",
					"The custom content must include the **{content}** placeholder where the email content should be put.",
				)
			}

			const mjmlStatus = checkMjml(contentWithPlaceholders, (await mjml2html).default)
			if (mjmlStatus.status === "invalid") {
				if (mjmlStatus.errors.length > 0) {
					await alert.show(
						"Invalid content",
						`The custom content is not a valid MJML.\n\n${mjmlStatus.errors.map(x => `- ${x}`).join("\n")}`,
					)
				} else {
					await alert.show("Invalid content", `The custom content is not a valid MJML.`)
				}
				return
			}
		}
		setSubmitting(true)
		try {
			await trpc.studentEmails.updateLayout.mutate(selectedOptionId === "custom" ? customContent : null)
			await mutate()
			navigate(routes.settings.studentEmail.index())
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout
			asideContent={
				contentWithPlaceholders && (
					<EmailPreview
						content={{
							type: "mjml",
							value: contentWithPlaceholders.replace(
								"{content}",
								"<mj-text>This is where the content will be placed.</mj-text>",
							),
						}}
					/>
				)
			}
		>
			<PageBreadcrumb
				title="Layout"
				path={[
					{ title: "Settings", link: routes.settings.index() },
					{ title: "Student Emails", link: routes.settings.studentEmail.index() },
				]}
			/>
			<Content onSubmit={handleSave}>
				{error ? (
					<p>Failed to load student email layout.</p>
				) : !layout ? (
					<PartialLoadingPage />
				) : (
					<>
						<RadioButtons
							options={options.map(x => ({ value: x.id, label: x.label }))}
							value={selectedOptionId}
							onChange={value => setSelectedOptionId(options.find(x => x.id === value)?.id ?? "default")}
						/>
						{selectedOptionId === "custom" && (
							<StyledEditor
								height={500}
								defaultLanguage="html"
								defaultValue={customContent}
								onChange={content => setCustomContent(content ?? "")}
								options={{ scrollBeyondLastLine: false, wordWrap: "on" }}
							/>
						)}
						<SubmitButton variant="primary" isLoading={isSubmitting}>
							Save
						</SubmitButton>
					</>
				)}
			</Content>
		</Layout>
	)
}

const Content = styled(Form)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`

const StyledEditor = styled(Editor)`
	border: 1px solid #d0d5dd;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
`

export default StudentEmailLayoutSettingsPage
