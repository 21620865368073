import dayjs from "dayjs"
import { type FC } from "react"

import {
	type Plan as PlanType,
	type SubscriptionPaymentInterval,
	type SubscriptionPlan,
} from "@forento/shared/models/payment"
import { type FreePlatformPlan, type RegularPlatformPlan } from "@forento/shared/models/platform"

import Button, { type ButtonVariant } from "~/components/Button"
import { useUser } from "~/contexts/UserContext"

import {
	PlanBillingInterval,
	default as PlanContainer,
	PlanDescription,
	PlanDivider,
	PlanInterval,
	PlanPrice,
	PlanTitle,
	PlanUspContainer,
	PlanUspIcon,
	PlanUspText,
	PlanUsps,
} from "../Plan"

type Props = {
	plan: PlanType
	currentPlan: SubscriptionPlan
	platformPlan: FreePlatformPlan | RegularPlatformPlan
	isMonthly: boolean
	isSubmitting: boolean
	isDisabled: boolean
	onSelectPlan: (paymentInterval: SubscriptionPaymentInterval) => void
	onCancelSubscription: () => void
	onResumeSubscription: () => void
}

const Plan: FC<Props> = ({
	plan,
	currentPlan,
	platformPlan,
	isMonthly,
	isSubmitting,
	isDisabled,
	onSelectPlan,
	onCancelSubscription,
	onResumeSubscription,
}) => {
	const platform = useUser().user!.platform!

	const price = getPrice({ plan, currentPlan, isMonthly, hasStripeSubscription: platformPlan.type === "regular" })
	const billingInterval: SubscriptionPaymentInterval = isMonthly ? "month" : "year"
	const callToAction = getCallToAction({
		plan,
		currentPlan,
		platformPlan,
		trialEndDate: platform.trialEndDate,
		isMonthly,
		isSubmitting,
		isDisabled,
		onSelectPlan,
		onCancelSubscription,
		onResumeSubscription,
	})

	return (
		<PlanContainer>
			<PlanTitle>{plan.title}</PlanTitle>
			{price ? (
				<PlanPrice>
					${price[billingInterval]}
					<PlanInterval>/month</PlanInterval>
				</PlanPrice>
			) : (
				<PlanPrice>Free</PlanPrice>
			)}
			<PlanBillingInterval>
				{price && <>Billed {billingInterval === "month" ? "monthly" : "annually"}</>}
			</PlanBillingInterval>
			<PlanDescription>{plan.description}</PlanDescription>
			<PlanDivider />
			<PlanUsps>
				{plan.usps.map((usp, index) => (
					<PlanUspContainer key={index}>
						<PlanUspIcon />
						<PlanUspText>{usp}</PlanUspText>
					</PlanUspContainer>
				))}
			</PlanUsps>
			<PlanDivider />
			<Button
				key={callToAction.key}
				variant={callToAction.variant}
				onClick={callToAction.onClick}
				isLoading={callToAction.isLoading}
				isDisabled={callToAction.isDisabled}
			>
				{callToAction.label}
			</Button>
		</PlanContainer>
	)
}

function getPrice({
	plan,
	currentPlan,
	isMonthly,
	hasStripeSubscription,
}: {
	plan: PlanType
	currentPlan: SubscriptionPlan
	isMonthly: boolean
	hasStripeSubscription: boolean
}): { month: number; year: number } | null {
	if (currentPlan.id === plan.id) {
		if (!hasStripeSubscription) {
			return plan.prices
				? {
						month: plan.prices.platform?.month.amount ?? plan.prices.current.month.amount,
						year: (plan.prices.platform?.year.amount ?? plan.prices.current.year.amount) / 12,
					}
				: null
		}
		if ((currentPlan.price?.interval === "month") === isMonthly) {
			return currentPlan.price ? { month: currentPlan.price.amount, year: currentPlan.price.amount / 12 } : null
		}
		return plan.prices
			? {
					month: plan.prices.platform?.month.amount ?? plan.prices.current.month.amount,
					year: (plan.prices.platform?.year.amount ?? plan.prices.current.year.amount) / 12,
				}
			: null
	}
	if (plan.prices === null) return null
	return {
		month: plan.prices.platform?.month.amount ?? plan.prices.current.month.amount,
		year: (plan.prices.platform?.year.amount ?? plan.prices.current.year.amount) / 12,
	}
}

function getCallToAction({
	plan,
	currentPlan,
	platformPlan,
	trialEndDate,
	isMonthly,
	isSubmitting,
	isDisabled,
	onSelectPlan,
	onCancelSubscription,
	onResumeSubscription,
}: {
	plan: PlanType
	currentPlan: SubscriptionPlan
	platformPlan: FreePlatformPlan | RegularPlatformPlan
	trialEndDate: Date | null
	isMonthly: boolean
	isSubmitting: boolean
	isDisabled: boolean
	onSelectPlan: (paymentInterval: SubscriptionPaymentInterval) => void
	onCancelSubscription: () => void
	onResumeSubscription: () => void
}): {
	key: string
	variant: ButtonVariant
	label: string
	onClick?: () => void
	isLoading?: boolean
	isDisabled?: boolean
} {
	const currentInterval: SubscriptionPaymentInterval = isMonthly ? "month" : "year"

	if (plan.id === currentPlan.id) {
		if (plan.prices === null) {
			return {
				key: "current",
				variant: "primary",
				label: "Current plan",
				isDisabled: true,
			}
		}

		if (currentPlan.price?.interval !== currentInterval) {
			return {
				key: "switch-interval",
				variant: isMonthly ? "secondary" : "primary",
				label: `Switch to ${isMonthly ? "monthly" : "annual"} payment`,
				onClick: () => onSelectPlan(currentInterval),
				isLoading: isSubmitting,
				isDisabled: isDisabled || plan.prices === null,
			}
		}

		if (platformPlan.type === "regular" && platformPlan.endDate) {
			return {
				key: "resume",
				variant: "secondary",
				label: "Resume subscription",
				onClick: onResumeSubscription,
				isDisabled,
				isLoading: isSubmitting,
			}
		}

		return {
			key: "cancel",
			variant: "secondary-danger",
			label: "Cancel subscription",
			onClick: onCancelSubscription,
			isDisabled: isDisabled || plan.prices === null,
			isLoading: isSubmitting,
		}
	}

	if (
		plan.prices !== null &&
		(trialEndDate === null || dayjs(trialEndDate).subtract(1, "day").subtract(1, "hour").unix() > dayjs().unix())
	) {
		return {
			key: "trial",
			variant: "primary",
			label: trialEndDate === null ? "Start free trial" : "Continue free trial",
			onClick: () => onSelectPlan(currentInterval),
			isLoading: isSubmitting,
			isDisabled,
		}
	}

	const isMoreProfitable =
		currentPlan.price === null ||
		(plan.prices?.platform?.[currentPlan.price.interval].amount ??
			plan.prices?.current[currentPlan.price.interval].amount ??
			0) > currentPlan.price.amount

	return {
		key: "switch",
		variant: isMoreProfitable ? "primary" : "secondary",
		label: "Switch to plan",
		onClick: plan.prices ? () => onSelectPlan(currentInterval) : onCancelSubscription,
		isDisabled,
		isLoading: isSubmitting,
	}
}

export default Plan
