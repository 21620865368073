import { lazy, type FC, Suspense } from "react"
import styled from "styled-components"

import { primaryLightColor } from "~/utilities/styles"

const MjmlContent = lazy(() => import("./MjmlContent"))

type Props = { title?: string; content: { type: "mjml" | "html"; value: string } }

const EmailPreview: FC<Props> = ({ title, content }) => (
	<Container>
		<Title>Preview</Title>
		<Text>Quick preview about how it looks when the user receives it with the details you entered.</Text>
		<Frame>
			<Header>
				<HeaderButtons>
					<HeaderButton $color="#FF5353" />
					<HeaderButton $color="#EFDD3F" />
					<HeaderButton $color="#62EC91" />
				</HeaderButtons>
			</Header>
			<Content>
				{title && <EmailTitle>{title}</EmailTitle>}
				<EmailBodyContainer>
					<EmailBody>
						{content.type === "mjml" && (
							<Suspense>
								<MjmlContent content={content.value} />
							</Suspense>
						)}
						{content.type === "html" && (
							<HtmlContent src={`data:text/html;charset=utf-8,${encodeURIComponent(content.value)}`} />
						)}
					</EmailBody>
				</EmailBodyContainer>
			</Content>
		</Frame>
	</Container>
)

const Container = styled.div`
	flex: 0.5;
	background-color: ${primaryLightColor};
	padding: 34px 30px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 32px;
	margin-bottom: 20px;
`

const Text = styled.p`
	margin-bottom: 28px;
`

const Frame = styled.div`
	background-color: white;
	border: 1px solid #eeeeee;
	box-shadow: 0 4px 19px rgba(0, 0, 0, 0.04);
	border-radius: 6px;
	overflow: hidden;
`

const Header = styled.div`
	background-color: #262626;
	padding: 8px 12px;
`

const HeaderButtons = styled.div`
	display: flex;
	gap: 6px;
`

const HeaderButton = styled.div<{ $color: string }>`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: ${props => props.$color};
`

const Content = styled.div`
	padding: 12px;
`

const EmailTitle = styled.h3`
	font-weight: 600;
	font-size: 18px;
	color: #151515;
	margin-top: 12px;
	margin-bottom: 6px;
`

const EmailBodyContainer = styled.div`
	width: 100%;
	height: 250px;
`

const EmailBody = styled.div`
	width: 200%;
	height: 500px;
	font-size: 13px;
	scale: 0.5;
	transform-origin: 0 0;
`

const HtmlContent = styled.iframe`
	width: 100%;
	height: 100%;
	border: none;
`

export default EmailPreview
