import { createContext, useContext } from "react"

import { type ExtendedCreator } from "@forento/shared/models/user"

export type UserContextValue = {
	user?: Omit<ExtendedCreator, "apiVersion">
	isLoading: boolean
	apiVersion: string | null
	reload(): Promise<void>
	signOut(): Promise<void>
}

const UserContext = createContext<UserContextValue>(undefined!)

export default UserContext

export const useUser = () => useContext(UserContext)

/**
 * Shortcut for `useUser().user.platform`
 */
export const usePlatform = () => useUser().user?.platform ?? null
