import styled from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"

const DemoVideoCard: React.FC = () => (
	<Container>
		<Header>
			<Label>Platform demo video</Label>
		</Header>
		<Content>
			<AspectRatio aspectRatio={16 / 9} width={{ unit: "percent", value: 100 }}>
				<iframe
					width="100%"
					height="100%"
					style={{ border: "none" }}
					src="https://www.youtube-nocookie.com/embed/f1Jq9z8T5Jc"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				/>
			</AspectRatio>
		</Content>
	</Container>
)

const Container = styled.div`
	flex: 1 1 400px;
	background-color: #f4f4f4;
	border-radius: 12px;
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

const Label = styled.p`
	font-weight: 500;
	font-size: 16px;
`

export default DemoVideoCard
