import { type FC } from "react"
import styled, { css } from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import routes from "@forento/shared/routes"

import Button from "~/components/Button"
import { DeleteIcon, EditIcon, LinkIcon, PreviewIcon, SettingsIcon, ShowIcon } from "~/components/Icon"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import useProductPublishedAlert from "~/hooks/useProductPublishedAlert"
import { dangerColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

type Props = {
	course: {
		id: number
		title: string
	}
	reload(): Promise<unknown>
}

const DraftCourse: FC<Props> = ({ course: { id, title }, reload }) => {
	const user = useUser()
	const alert = useAlert()
	const publishedAlert = useProductPublishedAlert("course")

	return (
		<ListItem>
			<div>
				<Button onClick={routes.app.course.detail(id)}>
					<Title>{title}</Title>
				</Button>
				<Subtitle>Not published</Subtitle>
			</div>
			<Actions>
				<Tooltip tooltip="Edit">
					<ActionButton onClick={routes.app.course.detail(id)}>
						<EditIcon />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Settings">
					<ActionButton onClick={routes.app.course.settings(id)}>
						<SettingsIcon />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Open link">
					<ActionButton
						onClick={`//${user.user!.platform!.domain.platform}${routes.platform.course.detail(id)}`}
						newTab
					>
						<LinkIcon />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Preview">
					<ActionButton
						onClick={`//${user.user!.platform!.domain.platform}${routes.platform.course.player(
							id,
						)}?email=${encodeURIComponent(user.user!.email)}`}
						newTab
					>
						<PreviewIcon />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Publish">
					<ActionButton
						onClick={async () => {
							const result = await alert.confirm(
								"Publish course",
								`Are you sure you want to publish the course **${title}**?`,
							)
							if (!result.result) return
							await trpc.course.publish.mutate(id)
							await reload()
							result.close()
							await publishedAlert.show({ itemId: id })
						}}
					>
						<ShowIcon />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Delete">
					<ActionButton
						isDanger
						onClick={async () => {
							const result = await alert.confirm(
								"Delete course",
								`Are you sure you want to delete the course **${title}**? This action is irreversible.`,
							)
							if (!result.result) return
							await trpc.course.deleteById.mutate(id)
							await reload()
							result.close()
						}}
					>
						<DeleteIcon />
					</ActionButton>
				</Tooltip>
			</Actions>
		</ListItem>
	)
}

const ListItem = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 0;

	&:last-child {
		padding-bottom: 0;
	}

	&:not(:last-child) {
		border-bottom: 1px solid #d6d6d6;
	}
`

const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 16px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 4px;
`

const Subtitle = styled.p`
	font-size: 14px;
	color: #6f6f6f;
`

const ActionButton = styled(Button)<{ isDanger?: boolean }>`
	width: 24px;
	height: 24px;
	position: relative;

	${props =>
		props.isDanger &&
		!props.isDisabled &&
		css`
			color: ${dangerColor};
		`}
`

export default DraftCourse
