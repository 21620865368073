import { type ReactNode } from "react"
import { createContext, useContext } from "react"

export type ConfirmResult = { result: true; close: () => void } | { result: false; close?: never }
export type InputResult = { result: string; close: () => void } | { result: null; close?: never }
export type MultipleInputsResult = { result: string[]; close: () => void } | { result: null; close?: never }

export type AlertContextValue = {
	show(title: string, message: string): Promise<void>
	confirm(title: string, message: string): Promise<ConfirmResult>
	input(title: string, message: string, label: string, defaultValue?: string): Promise<InputResult>
	multipleInputs(
		title: string,
		message: string,
		inputs: { label: string; defaultValue?: string }[],
	): Promise<MultipleInputsResult>
	custom(content: (onClose: () => void) => ReactNode): Promise<void>
}

const AlertContext = createContext<AlertContextValue>(undefined!)

export default AlertContext

export const useAlert = () => useContext(AlertContext)

export function useCustomAlert<T>(contentFactory: (args: T) => (onClose: () => void) => ReactNode) {
	const alert = useAlert()

	return {
		async show(factoryArgs: T) {
			await alert.custom(contentFactory(factoryArgs))
		},
	}
}
