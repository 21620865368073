import { type FC } from "react"
import styled, { css } from "styled-components"

import Button from "~/components/Button"
import { primaryColor } from "~/utilities/styles"

type Props = {
	annualDiscount: number
	isMonthlySelected: boolean
	setMonthlySelected: (value: boolean) => void
	className?: string
}

const PaymentIntervalSelector: FC<Props> = ({ annualDiscount, isMonthlySelected, setMonthlySelected, className }) => (
	<Container className={className}>
		<Sticker $isHidden>{Math.floor(annualDiscount * 100)}% off</Sticker>
		Monthly
		<Slider isMonthlySelected={isMonthlySelected} onClick={() => setMonthlySelected(!isMonthlySelected)}>
			<SliderDotContainer>
				<SliderDot $isActive={!isMonthlySelected} />
			</SliderDotContainer>
		</Slider>
		Annually
		<Sticker>{Math.floor(annualDiscount * 100)}% off</Sticker>
	</Container>
)

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
`

const Slider = styled(Button)<{ isMonthlySelected: boolean }>`
	width: 72px;
	background-color: #bfc6d8;
	display: flex;
	border-radius: 16px;
	padding: 4px;
`

const SliderDotContainer = styled.div`
	width: 100%;
	height: 24px;
	position: relative;
`

const SliderDot = styled.div<{ $isActive: boolean }>`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 24px;
	height: 24px;
	border-radius: 12px;
	background-color: white;
	transition: 0.15s;

	${props =>
		props.$isActive &&
		css`
			left: calc(100% - 24px);
		`}
`

const Sticker = styled.p<{ $isHidden?: boolean }>`
	font-weight: 600;
	color: white;
	background-color: ${primaryColor};
	padding: 4px 16px;
	border-radius: 16px;
	transition: 0.2s;

	${props =>
		props.$isHidden &&
		css`
			visibility: hidden;
		`}

	@media (max-width: 499px) {
		display: none;
	}
`

export default PaymentIntervalSelector
