import { type FC } from "react"
import styled from "styled-components"

import { type Event as EventType } from "@forento/shared/models/event"

import { useAlert } from "~/contexts/AlertContext"
import trpc from "~/utilities/trpc"

import Event from "./Event"

interface Props {
	events: EventType[]
	setEditEventId(id: number): void
	reloadEvents(): void
}

const EventsList: FC<Props> = ({ events, setEditEventId, reloadEvents }) => {
	const alert = useAlert()

	async function handleDeleteEvent(event: EventType) {
		const dialog = await alert.confirm(
			"Delete event",
			`Are you sure you want to delete the event **${event.title}**?`,
		)
		if (!dialog.result) return

		await trpc.event.delete.mutate(event.id)
		dialog.close()
		reloadEvents()
	}

	return (
		<Container>
			{events.map(event => (
				<Event
					key={event.id}
					event={event}
					onEdit={() => setEditEventId(event.id)}
					onDelete={() => handleDeleteEvent(event)}
				/>
			))}
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 16px;
`

export default EventsList
