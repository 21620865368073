import { useState } from "react"
import styled from "styled-components"

import { type CoursePageLink } from "@forento/shared/models/course"

import Button, { SubmitButton } from "~/components/Button"
import { AddIcon } from "~/components/Icon"
import InputField from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalTitle } from "~/components/Modal"
import { primaryColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

const AddLink: React.FC<{ pageId: number; onAdd: (value: CoursePageLink) => void }> = ({ pageId, onAdd }) => {
	const [isModalOpen, setModalOpen] = useState(false)
	const [label, setLabel] = useState("")
	const [url, setUrl] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const handleSubmit = async () => {
		const data = { label: label.trim(), url: url.trim().toLowerCase() }
		if (Object.values(data).some(x => x.length === 0)) return

		setSubmitting(true)
		try {
			const link = await trpc.course.createPageLink.mutate({ pageId, label, url })
			onAdd(link)
			setModalOpen(false)
			setLabel("")
			setUrl("")
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<>
			<DefaultModal isOpen={isModalOpen} onSubmit={handleSubmit}>
				<ModalTitle>Add link</ModalTitle>
				<ModalInputs>
					<InputField label="Label" value={label} onChange={setLabel} />
					<InputField label="Link" value={url} onChange={setUrl} />
				</ModalInputs>
				<ModalButtons>
					<Button variant="secondary" onClick={() => setModalOpen(false)} isDisabled={isSubmitting}>
						Cancel
					</Button>
					<SubmitButton variant="primary" isLoading={isSubmitting}>
						Add
					</SubmitButton>
				</ModalButtons>
			</DefaultModal>
			<Container onClick={() => setModalOpen(true)}>
				<StyledAddIcon />
				<InfoText>Add link</InfoText>
			</Container>
		</>
	)
}

const Container = styled(Button)`
	display: flex;
	align-items: center;
	border: 1px dashed ${primaryColor};
	border-radius: 8px;
	padding: 8px;
	font-weight: 500;
	font-size: 16px;
	cursor: pointer;
`

const StyledAddIcon = styled(AddIcon)`
	width: 24px;
	height: 24px;
`

const InfoText = styled.p`
	font-weight: 500;
	font-size: 16px;
`

export default AddLink
