import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import { type Event as EventType } from "@forento/shared/models/event"
import { toDateTimeString } from "@forento/shared/utilities/date"

import Button from "~/components/Button"
import { DeleteIcon, EditIcon } from "~/components/Icon"
import { dangerColor } from "~/utilities/styles"

interface Props {
	event: EventType
	onEdit(): void
	onDelete(): void
}

const Event: React.FC<Props> = ({ event, onEdit, onDelete }) => (
	<Container>
		<EventStartDate>{toDateTimeString(event.startDate)}</EventStartDate>
		<EventTitle>{event.title}</EventTitle>
		{event.shortDescription && <EventDescription>{event.shortDescription}</EventDescription>}
		<EventUsers>
			{event.price !== null && <p>Purchases: {event.users.orders.length}</p>}
			<p>Attending: {event.users.attendees.length}</p>
			<p>Interested: {event.users.interested.length}</p>
			<p>Not interested: {event.users.notInterested.length}</p>
		</EventUsers>
		<EventActions>
			<Tooltip tooltip="Edit">
				<EventAction onClick={onEdit}>
					<EditIcon />
				</EventAction>
			</Tooltip>
			<Tooltip tooltip="Delete">
				<EventAction onClick={onDelete}>
					<StyledDeleteIcon />
				</EventAction>
			</Tooltip>
		</EventActions>
	</Container>
)

const Container = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eaeaea;
	border-radius: 8px;
	padding: 22px;
	display: grid;
	grid-template-areas: "start-date users actions" "title users actions" "description users actions";
	grid-template-columns: 1fr max-content max-content;
	grid-template-rows: max-content max-content max-content;
	column-gap: 16px;
`

const EventStartDate = styled.time`
	grid-area: start-date;
	font-size: 14px;
`

const EventTitle = styled.h2`
	grid-area: title;
	font-weight: 600;
	font-size: 20px;
`

const EventDescription = styled.p`
	grid-area: description;
`

const EventUsers = styled.div`
	grid-area: users;
	text-align: right;
`

const EventActions = styled.div`
	grid-area: actions;
	display: flex;
	align-items: center;
	gap: 8px;
`

const EventAction = styled(Button)`
	width: 24px;
	height: 24px;
`

const StyledDeleteIcon = styled(DeleteIcon)`
	color: ${dangerColor};
`

export default Event
