import { createContext, useContext } from "react"

export type VideoUpload = { id: string; pageId: number; bunnyVideoId: string; label: string } & (
	| { status: "uploading"; progress: number }
	| { status: "preparing" | "failed" | "completed" }
)

export type VideoUploadValue = {
	start(args: { file: File; pageId: number; title: string }): Promise<{ id: string }>
	getByPageId(pageId: number): VideoUpload | null
}

const VideoUploadContext = createContext<VideoUploadValue>(undefined!)

export default VideoUploadContext

export function useVideoUploader() {
	return useContext(VideoUploadContext)
}
