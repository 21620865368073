import { useState } from "react"
import { useLocation } from "react-router"

import Form from "@forento/shared/components/Form"
import { parseNumber } from "@forento/shared/utilities/number"

import Button, { SubmitButton } from "~/components/Button"
import InputField from "~/components/InputField"
import LoadingIndicator from "~/components/LoadingIndicator"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

import Layout, { Content, Inputs, Logo, Text, Title } from "./Layout"

const NewPasswordPage: React.FC = () => {
	const params = new URLSearchParams(useLocation().search)

	const [isSubmitting, setSubmitting] = useState(false)
	const [isPasswordReset, setPasswordReset] = useState(false)
	const [password, setPassword] = useState("")
	const [passwordConfirmation, setPasswordConfirmation] = useState("")

	const userId = parseNumber(params.get("user") ?? "") ?? -1
	const token = params.get("token") ?? ""

	const tokenCheck = swr.user.checkResetPasswordToken.useSWR({ userId, token })

	const submitNewPassword = async () => {
		if (password.length === 0 || password !== passwordConfirmation || tokenCheck.data?.status !== "success") return

		setSubmitting(true)
		try {
			await trpc.user.setPasswordFromToken.mutate({ userId, token, newPassword: password })
			setPasswordReset(true)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout>
			<Logo />
			<Content as={Form} onSubmit={submitNewPassword}>
				<Title>Set new password</Title>
				{isPasswordReset ? (
					<>
						<Text>Your password is now updated. You can now sign in with your new password.</Text>
						<Button variant="primary" onClick={routes.account.signin()}>
							Sign in
						</Button>
					</>
				) : tokenCheck.error ? (
					<Text>An error occurred while resetting the password. Please try again later.</Text>
				) : !tokenCheck.data ? (
					<LoadingIndicator />
				) : tokenCheck.data.status === "invalid" ? (
					<Text>Invalid password reset link. It may be expired or already used.</Text>
				) : (
					<>
						<Inputs>
							<InputField
								inputType="password"
								label="New password"
								value={password}
								onChange={setPassword}
								autoComplete="new-password"
							/>

							<InputField
								inputType="password"
								label="Confirm new password"
								value={passwordConfirmation}
								onChange={setPasswordConfirmation}
								autoComplete="new-password"
							/>
						</Inputs>
						<SubmitButton variant="primary" isLoading={isSubmitting}>
							Reset password
						</SubmitButton>
					</>
				)}
			</Content>
		</Layout>
	)
}

export default NewPasswordPage
