import { Navigate, useParams } from "react-router"
import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { toDateTimeString } from "@forento/shared/utilities/date"
import { parseNumber } from "@forento/shared/utilities/number"

import Button from "~/components/Button"
import Layout, { PageBreadcrumb, PageHeader } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

import Course from "./Course"
import Downloadable from "./Downloadable"
import PageInput from "./PageInput"
import Video from "./Video"

const UserDetailPage: React.FC = () => {
	const alert = useAlert()

	const userId = parseNumber(useParams().userId ?? "") ?? -1

	const { data: user, error, mutate } = swr.user.getUserById.useSWR(userId)

	if (user === null) return <Navigate to={routes.user.index()} />

	async function handleDeleteUser() {
		if (!user) return

		let message = "Are you sure you want to delete this user?"
		if (user.membership) {
			message += "\n\n**- This user has an active membership**"
		}
		if (user.courses.some(x => x.status === "unlocked" && x.unlockedCause === "purchased")) {
			message += "\n\n**- This user has purchased content**"
		}

		const confirm = await alert.confirm("Delete user", message)
		if (!confirm.result) return

		await trpc.user.deleteStudent.mutate(user.id)
		await mutate()

		confirm.close()
	}

	return (
		<Layout>
			<PageHeader>
				<PageBreadcrumb
					path={[{ title: "Users", link: routes.user.index() }]}
					title={!user ? "..." : `${user.firstName} ${user.lastName}`}
				/>
				<Button variant="primary-danger" onClick={handleDeleteUser}>
					Delete
				</Button>
			</PageHeader>
			{error ? (
				<p>Failed to load courses.</p>
			) : !user ? (
				<PartialLoadingPage />
			) : (
				<Content>
					<Header>
						<Avatar profile={user} />
						<Details>
							<Name>
								{user.firstName} {user.lastName}
							</Name>
							<Email>{user.email}</Email>
						</Details>
					</Header>
					{user.phoneNumber && (
						<Group>
							<GroupTitle>Phone number</GroupTitle>
							<GroupValue>{user.phoneNumber}</GroupValue>
						</Group>
					)}
					<Group>
						<GroupTitle>Register date</GroupTitle>
						<GroupValue>{toDateTimeString(user.registerDate)}</GroupValue>
					</Group>
					<Group>
						<GroupTitle>Membership</GroupTitle>
						<GroupValue>{user.membership?.title ?? "None"}</GroupValue>
					</Group>
					<Group>
						<GroupTitle>Community profile</GroupTitle>
						<GroupValue>
							{user.communityProfileId !== null ? (
								<Button onClick={routes.community.profile(user.communityProfileId)}>
									{routes.community.profile(user.communityProfileId)}
								</Button>
							) : (
								"Not registered"
							)}
						</GroupValue>
					</Group>

					<Group>
						<GroupTitle>Courses</GroupTitle>
						<Courses>
							{user.courses.map(course => (
								<Course key={course.id} course={course} userId={user.id} reload={mutate} />
							))}
						</Courses>
					</Group>

					<Group>
						<GroupTitle>Videos</GroupTitle>
						<Courses>
							{user.videos.map(video => (
								<Video key={video.id} video={video} userId={userId} reload={mutate} />
							))}
						</Courses>
					</Group>

					<Group>
						<GroupTitle>Downloadables</GroupTitle>
						<Courses>
							{user.downloadables.map(downloadable => (
								<Downloadable
									key={downloadable.id}
									downloadable={downloadable}
									userId={userId}
									reload={mutate}
								/>
							))}
						</Courses>
					</Group>

					<Group>
						<GroupTitle>Course inputs</GroupTitle>
						<InputPages>
							{user.inputs.chapters
								.flatMap(chapter =>
									chapter.pages.map(page => ({ ...page, chapterTitle: chapter.title })),
								)
								.map(page => (
									<PageInput key={page.id} page={page} chapterTitle={page.chapterTitle} />
								))}
						</InputPages>
					</Group>
				</Content>
			)}
		</Layout>
	)
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const Header = styled.div`
	display: flex;
	gap: 16px;
`

const Avatar = styled(CommunityProfileAvatar).attrs({ size: 56 })`
	box-sizing: content-box;
	border: 4px solid #ede7fb;
`

const Details = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const Name = styled.div`
	font-size: 18px;
	font-weight: 600;
`

const Email = styled.div`
	font-size: 16px;
	color: #667085;
`

const Group = styled.div``

const GroupTitle = styled.h2`
	font-weight: 600;
	font-size: 20px;
	margin-bottom: 8px;
`

const GroupValue = styled.h2`
	font-size: 14px;
`

const Courses = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const InputPages = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`

export default UserDetailPage
