import { type FC, useLayoutEffect, useState } from "react"

import Button, { SubmitButton } from "~/components/Button"
import InputField, { InputTextArea, useRichTextArea } from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalText, ModalTitle } from "~/components/Modal"
import { useAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/UserContext"
import usePrice from "~/hooks/usePrice"
import trpc, { swr } from "~/utilities/trpc"

type Props = { sessionId: number | null; onClose(): void; onSubmit(): void }
const EditModal: FC<Props> = ({ sessionId, onClose, onSubmit }) => {
	const platform = usePlatform()
	const alert = useAlert()

	const [title, setTitle] = useState("")
	const [shortDescription, setShortDescription] = useState("")
	const longDescription = useRichTextArea({ label: "Description" })
	const { price, priceValue, setPrice } = usePrice()
	const [redemptionInstructions, setRedemptionInstructions] = useState("")
	const [redemptionLink, setRedemptionLink] = useState("")

	const [isSubmitting, setSubmitting] = useState(false)

	const { data: session, error, mutate } = swr.privateSession.get.useSWR(sessionId ?? -1)

	useLayoutEffect(() => {
		if (sessionId === null) return

		setTitle("")
		setShortDescription("")
		longDescription.set(null)
		setPrice("")
		setRedemptionInstructions("")
		setRedemptionLink("")
	}, [longDescription, sessionId, setPrice])

	useLayoutEffect(() => {
		if (!session) return

		setTitle(session.title ?? "")
		setShortDescription(session.shortDescription ?? "")
		longDescription.set(session.longDescription)
		setPrice(session.price?.amount.toString() ?? "")
		setRedemptionInstructions(session.redemptionInstructions ?? "")
		setRedemptionLink(session.redemptionLink ?? "")
	}, [longDescription, session, setPrice])

	async function handleSubmit() {
		if (!session) return

		setSubmitting(true)
		try {
			if (title.trim().length === 0 || priceValue === null) {
				return alert.show("Error", "You must fill out all required fields.")
			}
			if (redemptionInstructions.trim().length === 0 && redemptionLink.trim().length === 0) {
				return alert.show("Error", "You must provide either redemption instructions or a redemption link.")
			}
			await trpc.privateSession.edit.mutate({
				id: session.id,
				data: {
					title,
					shortDescription,
					longDescription: longDescription.exportEditorState(),
					price: priceValue > 0 ? priceValue : null,
					redemptionInstructions: redemptionInstructions.trim().length > 0 ? redemptionInstructions : null,
					redemptionLink: redemptionLink.trim().length > 0 ? redemptionLink : null,
				},
			})
			await mutate()
			onClose()
			onSubmit()
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={sessionId !== null} onSubmit={handleSubmit}>
			<ModalTitle>Edit session</ModalTitle>
			{error ? (
				<ModalText>Failed to load session.</ModalText>
			) : (
				<ModalInputs>
					<InputField label="Title *" value={title} onChange={setTitle} />
					<InputField label="Short description" value={shortDescription} onChange={setShortDescription} />
					{longDescription.element}
					<InputField
						inputType="number"
						label={`Price ${platform!.currency} *`}
						value={price}
						onChange={setPrice}
					/>
					<InputTextArea
						label="Redemption instructions"
						value={redemptionInstructions}
						onChange={setRedemptionInstructions}
					/>
					<InputField
						label="Redemption link"
						value={redemptionLink}
						onChange={setRedemptionLink}
						placeholder="Calendar booking, etc."
					/>
				</ModalInputs>
			)}
			<ModalButtons>
				<Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
					Cancel
				</Button>
				<SubmitButton variant="primary" isLoading={isSubmitting}>
					Save
				</SubmitButton>
			</ModalButtons>
		</DefaultModal>
	)
}

export default EditModal
