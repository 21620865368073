import { useLayoutEffect, useState } from "react"

import { type GamificationLevel } from "@forento/shared/models/community"

import Button, { SubmitButton } from "~/components/Button"
import InputField from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalTitle } from "~/components/Modal"
import trpc from "~/utilities/trpc"

interface Props {
	isOpen: boolean
	level: GamificationLevel | null
	onClose(): void
	onReload(): Promise<unknown>
}

const EditLevelModal: React.FC<Props> = ({ isOpen, level, onClose, onReload }) => {
	const [isSubmitting, setSubmitting] = useState(false)
	const [label, setLabel] = useState("")
	const [unlockPoints, setUnlockPoints] = useState("")

	useLayoutEffect(() => {
		setLabel(level?.label ?? "")
		setUnlockPoints(level?.unlockPoints.toString() ?? "")
	}, [level])

	if (level === null) return null

	const handleSave = async () => {
		setSubmitting(true)
		try {
			await trpc.community.updateLevel.mutate({
				id: level.id,
				data: { label: label.trim(), unlockPoints: parseInt(unlockPoints) },
			})
			await onReload()
			onClose()
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={isOpen} onSubmit={handleSave}>
			<ModalTitle>Edit level</ModalTitle>
			<ModalInputs>
				<InputField label="Label" value={label} onChange={setLabel} />
				<InputField label="Unlock points" inputType="number" value={unlockPoints} onChange={setUnlockPoints} />
			</ModalInputs>
			<ModalButtons>
				<Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
					Cancel
				</Button>
				<SubmitButton
					variant="primary"
					isLoading={isSubmitting}
					isDisabled={label.trim().length === 0 || unlockPoints.length === 0}
				>
					Save
				</SubmitButton>
			</ModalButtons>
		</DefaultModal>
	)
}

export default EditLevelModal
