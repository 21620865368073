import { useSearchParams } from "react-router-dom"

import trpc, { getInputErrors } from "~/utilities/trpc"

import Layout, { Logo } from "../Layout"
import PlatformInvite from "./PlatformInvite"
import Regular from "./Regular"

type SignupData = {
	firstName: string
	lastName: string
	password: string
	passwordConfirmation: string
	email?: string
}

const getFormError = (data: SignupData): string | null => {
	if (
		[
			data.firstName,
			data.lastName,
			data.password,
			data.passwordConfirmation,
			...(data.email !== undefined ? [data.email] : []),
		].some(x => x.trim().length === 0)
	)
		return "Please fill in all fields."

	if (data.password !== data.passwordConfirmation) return "Passwords don't match."

	return null
}

export async function signup(
	data: SignupData & Record<string, unknown> & { source?: string },
): Promise<{ status: "success" } | { status: "failed"; error: string }> {
	const error = getFormError(data)
	if (error !== null) return { status: "failed", error }

	const body = {
		...data,
		firstName: data.firstName.trim(),
		lastName: data.lastName.trim(),
		email: data.email?.trim(),
		password: data.password,
		source: data.source ?? null,
	}

	try {
		const { status } = await trpc.user.registerCreator.mutate(body)

		if (status === "email-taken") {
			return { status: "failed", error: "That e-mail address is already associated with an account." }
		}
		if (status === "invalid-referral-code") {
			return { status: "failed", error: "That referral code is invalid." }
		}
		if (status === "invalid-invite-token") {
			return { status: "failed", error: "That platform invite is invalid." }
		}
		if (status === "expired-invite-token") {
			return { status: "failed", error: "That platform invite is unfortunately expired." }
		}
		return { status: "success" }
	} catch (error) {
		const errors = getInputErrors(error)
		if ("email" in errors) {
			return { status: "failed", error: "Invalid email" }
		}
		console.error("Failed to sign up!", error)
		return { status: "failed", error: "Unknown error. Please try again later." }
	}
}

const SignupPage: React.FC = () => {
	const [searchParams] = useSearchParams()

	const inviteToken = searchParams.get("platform-invite")

	return (
		<Layout>
			<Logo />
			{inviteToken !== null ? <PlatformInvite token={inviteToken} /> : <Regular />}
		</Layout>
	)
}

export default SignupPage
