import { type FC } from "react"
import styled, { css } from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"

import Button from "~/components/Button"
import { DeleteIcon, EditIcon } from "~/components/Icon"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Table from "~/components/Table"
import { useAlert } from "~/contexts/AlertContext"
import { dangerColor } from "~/utilities/styles"
import trpc, { swr } from "~/utilities/trpc"

const PostCategories: FC = () => {
	const alert = useAlert()

	const { data, error, mutate } = swr.community.listPostCategories.useSWR()

	if (error) return <p>Failed to fetch post categories.</p>

	if (data === undefined) return <PartialLoadingPage />

	async function handleCreateCategory() {
		const result = await alert.input("Create category", "Please enter a name for the category.", "Name")
		if (result.result === null) return
		await trpc.community.createPostCategory.mutate(result.result.trim())
		await mutate()
		result.close()
	}

	async function handleUpdateCategory(id: number) {
		const category = data?.find(item => item.id === id)
		if (category === undefined) return

		const dialog = await alert.input(
			"Update category",
			"Please enter a new name for the category.",
			"Name",
			category.label,
		)
		if (dialog.result === null) return
		await trpc.community.setPostCategoryName.mutate({ id, label: dialog.result.trim() })
		await mutate()
		dialog.close()
	}

	async function handleDeleteCategory(id: number) {
		const category = data?.find(item => item.id === id)
		if (category === undefined) return

		const dialog = await alert.confirm(
			"Delete category",
			`Are you sure you want to delete the category **${category.label}**?`,
		)
		if (!dialog.result) return
		await trpc.community.deletePostCategory.mutate(id)
		await mutate()
		dialog.close()
	}

	return (
		<Container>
			<Table
				isCompact
				columns={["Name", "Posts", "Actions"]}
				data={data.map(item => [
					item.label,
					item.posts,
					<Actions>
						<Tooltip tooltip="Rename">
							<Action onClick={() => handleUpdateCategory(item.id)}>
								<ActionIcon as={EditIcon} />
							</Action>
						</Tooltip>
						<Tooltip tooltip="Delete">
							<Action onClick={() => handleDeleteCategory(item.id)}>
								<ActionIcon as={DeleteIcon} $isDanger />
							</Action>
						</Tooltip>
					</Actions>,
				])}
			/>
			<Button variant="primary" onClick={handleCreateCategory}>
				Create a new category
			</Button>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: flex-end;
`

const Actions = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`

const Action = styled(Button)`
	width: 24px;
	height: 24px;
`

const ActionIcon = styled.div<{ $isDanger?: boolean }>`
	width: 100%;
	height: 100%;

	${props =>
		props.$isDanger &&
		css`
			color: ${dangerColor};
		`}
`

export default PostCategories
