import { type ReactNode } from "react"
import styled, { css } from "styled-components"

import Modal from "@forento/shared/components/Modal"

interface Props {
	isOpen: boolean
	width?: string | number
	onSubmit?(): void
	children: ReactNode
}

export const DefaultModal: React.FC<Props> = ({ isOpen, width, onSubmit, children }) => (
	<StyledModal width={width ?? 500} isOpen={isOpen} onSubmit={onSubmit}>
		<Content>{children}</Content>
	</StyledModal>
)

const StyledModal = styled(Modal)`
	border-radius: 12px;
`

const Content = styled.div`
	padding: 24px 0;
`

export const ModalTitle = styled.h1`
	font-weight: 600;
	font-size: 18px;
	color: #101828;
	padding: 8px 24px 20px 24px;
`

export const ModalText = styled.div`
	color: #757575;
	font-size: 14px;
	padding: 0 24px 20px 24px;

	ul,
	ol {
		margin-left: 1em;
	}
`

export const ModalTexts = styled(ModalText)`
	display: flex;
	flex-direction: column;
	gap: 1em;
`

export const ModalPadding = styled.div`
	padding: 0 24px;
`

export const ModalContentPadding = styled.div`
	padding: 0 24px 20px 24px;
`

export const ModalInputs = styled.div`
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 0 24px 20px 24px;
	overflow-y: auto;
`

export const ModalInputsRow = styled.div`
	display: flex;
	gap: 16px;
`

export const ModalButtons = styled.div<{ $vertical?: boolean }>`
	display: flex;
	justify-content: flex-end;
	padding: 0 24px;
	gap: 12px;

	${props =>
		props.$vertical &&
		css`
			flex-direction: column;
		`}
`
