import styled from "styled-components"
import tinycolor from "tinycolor2"

import { CheckmarkIcon, CloseIcon } from "~/components/Icon"
import { dangerColor, successColor } from "~/utilities/styles"

const Checkbox: React.FC<{ isCorrect: boolean; onChange: (value: boolean) => void }> = ({ isCorrect, onChange }) => (
	<Container $backgroundColor={isCorrect ? successColor : dangerColor}>
		<Icon as={isCorrect ? CheckmarkIcon : CloseIcon} />
		<Input onChange={event => onChange(event.target.checked)} />
	</Container>
)

const Container = styled.label<{ $backgroundColor: string }>`
	width: 52px;
	height: 52px;
	background-color: ${props => props.$backgroundColor};
	border: 1px solid ${props => tinycolor(props.$backgroundColor).lighten(10).toHexString()};
	border-radius: 4px;
	cursor: pointer;
	padding: 8px;
`

const Icon = styled.div`
	width: 100%;
	height: 100%;
	color: white;
`

const Input = styled.input.attrs({ type: "checkbox" })`
	display: none;
`

export default Checkbox
