import { type FC, useState } from "react"

import Button, { SubmitButton } from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import InputField from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalTitle } from "~/components/Modal"
import { usePlatform } from "~/contexts/UserContext"
import trpc from "~/utilities/trpc"

type Props = { isOpen: boolean; closeModal(): void; onSubmitted(): void }
const CreateNewCourseModal: FC<Props> = ({ isOpen, closeModal, onSubmitted }) => {
	const platform = usePlatform()

	const [title, setTitle] = useState("")
	const [shortDescription, setShortDescription] = useState("")
	const [issueCertificates, setIssueCertificates] = useState(false)
	const [commentsEnabled, setCommentsEnabled] = useState(true)
	const [autoApproveComments, setAutoApproveComments] = useState(true)

	const isInputValid = title.trim().length > 0 && shortDescription.trim().length > 0

	async function handleSubmit() {
		if (!isInputValid) return

		await trpc.course.create.mutate({
			title: title.trim(),
			shortDescription: shortDescription.trim(),
			issueCertificates,
			commentsEnabled,
			autoApproveComments,
		})

		setTitle("")
		setShortDescription("")
		setIssueCertificates(false)
		setCommentsEnabled(true)
		setAutoApproveComments(true)

		onSubmitted()
	}

	return (
		<DefaultModal isOpen={isOpen} onSubmit={handleSubmit}>
			<ModalTitle>Create a new course</ModalTitle>
			<ModalInputs>
				<InputField label="Title" value={title} onChange={setTitle} />
				<InputField label="Short description" value={shortDescription} onChange={setShortDescription} />
				<Checkbox
					label="Issue certificates"
					isChecked={issueCertificates}
					onChange={setIssueCertificates}
					isDisabled={!platform!.plan.access.certificates}
				/>
				<Checkbox label="Enable comments" isChecked={commentsEnabled} onChange={setCommentsEnabled} />
				<Checkbox
					label="Automatically approve comments"
					isChecked={autoApproveComments}
					onChange={setAutoApproveComments}
					isDisabled={!commentsEnabled}
				/>
			</ModalInputs>
			<ModalButtons>
				<Button variant="secondary" onClick={closeModal}>
					Cancel
				</Button>
				<SubmitButton variant="primary" isDisabled={!isInputValid}>
					Create course
				</SubmitButton>
			</ModalButtons>
		</DefaultModal>
	)
}

export default CreateNewCourseModal
