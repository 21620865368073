import styled from "styled-components"

import Button from "~/components/Button"
import { DeleteIcon } from "~/components/Icon"

export const DeleteMediaButton = styled(Button)`
	position: absolute;
	top: 16px;
	left: 16px;
	width: 40px;
	height: 40px;
	padding: 8px;
	background-color: black;
	border-radius: 50%;
	color: white;
`

export const DeleteMediaIcon = styled(DeleteIcon)`
	width: 100%;
	height: 100%;
`

export const ButtonsRow = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
	gap: 12px;
`

export const ButtonsGroup = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 12px;
`
