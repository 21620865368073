import { type FC } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import { useUser } from "~/contexts/UserContext"
import { pageContentWidth, primaryLightColor } from "~/utilities/styles"

const ImpersonateBanner: FC = () => {
	const user = useUser()

	if (!user.user?.isImpersonating) return null

	return (
		<Container>
			<Content>
				<p>
					You are currently impersonating{" "}
					<strong>
						{user.user.firstName} {user.user.lastName}
					</strong>{" "}
					- <strong>{user.user.email}</strong>
				</p>
				<Button variant="primary" onClick={user.signOut}>
					Sign out
				</Button>
			</Content>
		</Container>
	)
}

const Container = styled.div`
	padding: 8px 16px;
	background-color: ${primaryLightColor};
`

const Content = styled.div`
	box-sizing: border-box;
	width: 100%;
	max-width: ${pageContentWidth - 16 * 2}px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
`

export default ImpersonateBanner
