import { useLayoutEffect, useState, type FC } from "react"
import { Navigate, useParams } from "react-router"
import styled from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import Button from "~/components/Button"
import { DeleteIcon } from "~/components/Icon"
import InputField, { useRichTextArea } from "~/components/InputField"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import routes from "~/utilities/routes"
import { dangerColor } from "~/utilities/styles"
import trpc, { swr } from "~/utilities/trpc"

import Answer from "./Answer"
import CreateItem from "./CreateItem"
import InlineTextEditor from "./InlineTextEditor"

const ExamPage: FC = () => {
	const examId = parseNumber(useParams().examId) ?? -1
	const alert = useAlert()

	const [shortDescription, setShortDescription] = useState("")
	const longDescription = useRichTextArea({ label: "Description" })
	const [isSavingDescriptions, setSavingDescriptions] = useState(false)

	const { data: exam, error, mutate } = swr.exam.get.useSWR(examId)

	useLayoutEffect(() => {
		setShortDescription(exam?.shortDescription ?? "")
		longDescription.set(exam?.longDescription ?? null)
	}, [exam, longDescription])

	if (exam === null) return <Navigate to={routes.exam.index()} />

	const handleSaveDescriptions = async () => {
		if (!exam) return
		setSavingDescriptions(true)
		try {
			await trpc.exam.update.mutate({
				id: exam.id,
				data: { shortDescription, longDescription: longDescription.exportEditorState() },
			})
			await mutate()
			setSavingDescriptions(false)
			await alert.show("Descriptions saved", "The descriptions has been saved.")
		} catch (error) {
			console.error(error)
			setSavingDescriptions(false)
			await alert.show("Error", "Failed to save descriptions. Please try again later.")
		}
	}

	const handleTextChange = async (questionId: number, text: string) => {
		try {
			await trpc.exam.updateQuestion.mutate({ id: questionId, text })
			await mutate()
		} catch (error) {
			console.error(error)
		}
	}

	const handlePublish = async () => {
		if (!exam) return
		const dialog = await alert.confirm(
			"Publish exam",
			"Are you sure you want to publish this exam? This action cannot be undone.",
		)
		if (!dialog.result) return

		try {
			await trpc.exam.update.mutate({ id: exam.id, data: { isPublished: true } })
			dialog.close()
			await mutate()
		} catch (error) {
			console.error(error)
		}
	}

	const handleDeleteQuestion = async (questionId: number) => {
		const dialog = await alert.confirm(
			"Delete question",
			"Are you sure you want to delete this question? This action cannot be undone.",
		)
		if (!dialog.result) return

		try {
			await trpc.exam.deleteQuestion.mutate(questionId)
			dialog.close()
			await mutate()
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<Layout>
			<PageBreadcrumb
				path={[{ title: "Exams", link: routes.exam.index() }]}
				title={exam !== undefined ? exam.name : "..."}
			/>
			{error ? (
				<p>Failed to load exam.</p>
			) : exam === undefined ? (
				<PartialLoadingPage />
			) : (
				<Content>
					<InputField label="Short description" value={shortDescription} onChange={setShortDescription} />
					{longDescription.element}
					<SaveButton variant="primary" onClick={handleSaveDescriptions} isLoading={isSavingDescriptions}>
						Save descriptions
					</SaveButton>
					<Questions>
						{exam.questions.map((question, index) => (
							<div key={question.id}>
								<Question>
									{index + 1}.{" "}
									<InlineTextEditor
										text={question.text}
										onSubmit={value => handleTextChange(question.id, value)}
									/>
									<DeleteButton onClick={() => handleDeleteQuestion(question.id)}>
										<DeleteIcon />
									</DeleteButton>
								</Question>
								<Answers>
									{question.answers.map((answer, index) => (
										<Answer key={answer.id} answer={answer} index={index} onChange={mutate} />
									))}
									<CreateItem
										content={{ type: "answer", questionId: question.id }}
										onCreate={mutate}
									/>
								</Answers>
							</div>
						))}
						<CreateItem content={{ type: "question", examId: exam.id }} onCreate={mutate} />
					</Questions>
					{exam.publishDate === null && (
						<PublishExamButton variant="primary" onClick={handlePublish}>
							Publish exam
						</PublishExamButton>
					)}
				</Content>
			)}
		</Layout>
	)
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const SaveButton = styled(Button).attrs({ variant: "primary" })`
	align-self: flex-start;
`

const Questions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Question = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

const DeleteButton = styled(Button)`
	width: 24px;
	height: 24px;
	color: ${dangerColor};
`

const Answers = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
	margin-left: 1em;
`

const PublishExamButton = styled(Button)`
	align-self: flex-start;
	margin-top: 16px;
`

export default ExamPage
