import styled from "styled-components"

import { toPriceString } from "@forento/shared/utilities/currency"

import { SalesIcon } from "~/components/Icon"

const Balance: React.FC<{ balances: { currency: string; amount: number }[] }> = ({ balances }) => (
	<Container>
		<Label>
			<Icon />
			Amount ready for payout
		</Label>
		<Amounts>
			{balances.map((balance, index) => (
				<Amount key={index}>{toPriceString(balance)}</Amount>
			))}
		</Amounts>
	</Container>
)

const Container = styled.div`
	flex: 0 0 auto;
	background-color: #f4f4f4;
	padding: 16px;
	display: flex;
	gap: 32px;
`

const Label = styled.p`
	font-size: 16px;
	display: flex;
	align-items: center;
`

const Icon = styled(SalesIcon)`
	width: 32px;
	height: 32px;
	margin-right: 6px;
`

const Amounts = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
`

const Amount = styled.p`
	font-weight: 700;
	font-size: 25px;
`

export default Balance
