import { createSWRProxyHooks } from "@trpc-swr/client"
import { TRPCClientError, createTRPCProxyClient, httpBatchLink, httpLink } from "@trpc/client"

import trpcTransformer from "@forento/shared/utilities/trpcTransformer"

import type { AppRoutes } from "../../../api/src/routes"

export const trpcOptions = {
	links: [CONFIG.isDevelopment ? httpLink({ url: "/api/_" }) : httpBatchLink({ url: "/api/_" })],
	transformer: trpcTransformer,
}

const trpc = createTRPCProxyClient<AppRoutes>(trpcOptions)

export const swr = createSWRProxyHooks<AppRoutes>(trpcOptions)

function isTRPCClientError(cause: unknown): cause is TRPCClientError<AppRoutes> {
	return cause instanceof TRPCClientError
}

export function getInputErrors(error: unknown): Record<string, string[]> {
	if (!isTRPCClientError(error)) return {}
	return error.data?.inputErrors ?? {}
}

export default trpc
