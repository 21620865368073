import React, { type ReactNode } from "react"
import styled from "styled-components"

import ErrorBoundary from "~/components/ErrorBoundary"
import { BreadcrumbSeparatorIcon } from "~/components/Icon"
import NewThemeModal from "~/components/Layout/NewThemeModal"
import { pageContentWidth } from "~/utilities/styles"

import Button from "../Button"
import ImpersonateBanner from "./ImpersonateBanner"
import Sidebar from "./Sidebar"
import StatusBanner from "./StatusBanner"

const Layout: React.FC<{ asideContent?: ReactNode; children: ReactNode }> = ({ asideContent, children }) => (
	<Container>
		<Sidebar />
		<ContentColumn>
			<ImpersonateBanner />
			<StatusBanner />
			<NewThemeModal />
			<ContentWrapper>
				<ErrorBoundary>
					<Content>{children}</Content>
				</ErrorBoundary>
				{asideContent}
			</ContentWrapper>
		</ContentColumn>
	</Container>
)

const Container = styled.div`
	background-color: white;
	display: flex;
`

const ContentColumn = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`

const ContentWrapper = styled.div`
	flex: 1;
	display: flex;
`

const Content = styled.div`
	flex: 1;
	max-width: ${pageContentWidth}px;
	padding: 34px 30px;
	display: flex;
	flex-direction: column;
`

export const PageTitle = styled.h1`
	font-weight: 600;
	font-size: 32px;
	color: black;
	margin-bottom: 22px;
`

const BreadcrumbContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 22px;
`

export const PageHeader = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 22px;

	*,
	${PageTitle}, ${BreadcrumbContainer} {
		margin-bottom: 0;
	}
`

export const PageHeaderGroup = styled.div`
	display: flex;
	gap: 22px;
`

export const PageBreadcrumb: React.FC<{ title: string; path: { title: string; link?: string }[] }> = ({
	title,
	path,
}) => (
	<BreadcrumbContainer>
		{path.map(({ title, link }, index) => (
			<React.Fragment key={index}>
				{link !== undefined ? (
					<BreadcrumbItem as={Button} onClick={link}>
						{title}
					</BreadcrumbItem>
				) : (
					<BreadcrumbItem>{title}</BreadcrumbItem>
				)}
				<BreadcrumbSeparator />
			</React.Fragment>
		))}
		<BreadcrumbTitle>{title}</BreadcrumbTitle>
	</BreadcrumbContainer>
)

const BreadcrumbItem = styled.div`
	font-weight: 600;
	font-size: 32px;
	color: #757575;
`

const BreadcrumbSeparator = styled(BreadcrumbSeparatorIcon)`
	width: 24px;
	height: 24px;
	color: #757575;
`

const BreadcrumbTitle = styled.h1`
	font-weight: 600;
	font-size: 32px;
`

export default Layout
