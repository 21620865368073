import { type Dispatch, type FC, useLayoutEffect, useState } from "react"
import { Helmet } from "react-helmet-async"

import { getCookieByName, setCookie } from "@forento/shared/utilities/cookie"
import { parseNumber } from "@forento/shared/utilities/number"

import Button from "~/components/Button"
import { DefaultModal, ModalButtons, ModalText, ModalTitle } from "~/components/Modal"

const cookieName = "cookie-option"
const options = {
	functional: 1 << 0,
	analytics: 1 << 1,
}

const CookieConsent: FC = () => {
	const [cookieOption, setCookieOption] = useCookieOption()

	if (cookieOption !== null) {
		return (
			<Helmet>
				<script async defer src="https://accounts.google.com/gsi/client" />
				<script async src="https://widget.intercom.io/widget/xu1aznd7" />
			</Helmet>
		)
	}

	return (
		<DefaultModal isOpen={cookieOption === null}>
			<ModalTitle>We value your privacy</ModalTitle>
			<ModalText>
				We use cookies to authenticate you and to reach you through our live chat. These cookies are necessary
				for Forento to work and no cookies are loaded until you accept.
			</ModalText>
			<ModalButtons>
				<Button variant="primary" onClick={() => setCookieOption(options.functional | options.analytics)}>
					Accept
				</Button>
			</ModalButtons>
		</DefaultModal>
	)
}

function useCookieOption(): [number | null, Dispatch<number | null>] {
	function getCookieOption(): number | null {
		const value = getCookieByName(cookieName)
		if (value === null) return null
		return parseNumber(value)
	}

	const [cookieOption, setCookieOption] = useState<number | null>(getCookieOption())

	useLayoutEffect(() => {
		if (cookieOption !== null) {
			const domain = CONFIG.isDevelopment ? window.location.hostname : "forento.io"
			setCookie({ name: cookieName, value: cookieOption, maxAge: 365 * 24 * 60 * 60, domain, path: "/" })
		}
	}, [cookieOption])

	return [cookieOption, setCookieOption]
}

export default CookieConsent
