import styled from "styled-components"

import Button from "~/components/Button"
import { DeleteIcon } from "~/components/Icon"
import InputField from "~/components/InputField"

import Checkbox from "./Checkbox"

const QuizAnswer: React.FC<{
	text: string
	isCorrect: boolean
	onTextChange: (value: string) => void
	onIsCorrectChange: (value: boolean) => void
	onDelete: () => void
}> = ({ text, isCorrect, onTextChange, onIsCorrectChange, onDelete }) => (
	<Container>
		<StyledInputField value={text} onChange={onTextChange} />
		<Checkbox isCorrect={isCorrect} onChange={onIsCorrectChange} />
		<StyledDeleteButton onClick={onDelete}>
			<DeleteIcon />
		</StyledDeleteButton>
	</Container>
)

const Container = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	gap: 8px;
`

const StyledInputField = styled(InputField).attrs({ placeholder: "Enter quiz answer..." })`
	flex: 1;
`

const StyledDeleteButton = styled(Button)`
	width: 52px;
	height: 52px;
	background-color: black;
	color: white;
	border-radius: 4px;
	padding: 8px;
`

export default QuizAnswer
