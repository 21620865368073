import routes from "@forento/shared/routes"

import Button from "~/components/Button"
import { ModalButtons, ModalText, ModalTitle } from "~/components/Modal"
import { useCustomAlert } from "~/contexts/AlertContext"
import { usePlatform } from "~/contexts/UserContext"

type Type = "course" | "exam" | "downloadable" | "session" | "event"

function getUrlPathByItem(type: Type, id: number): string {
	switch (type) {
		case "course":
			return routes.platform.course.detail(id)
		case "exam":
			return routes.platform.exam.detail(id)
		case "downloadable":
			return routes.platform.downloadable.detail(id)
		case "session":
			return routes.platform.session.detail(id)
		case "event":
			return routes.platform.event.detail(id)
	}
}

export default function useProductPublishedAlert(type: Type) {
	const platform = usePlatform()!

	return useCustomAlert<{ itemId: number }>(({ itemId }) => onClose => (
		<>
			<ModalTitle>Published!</ModalTitle>
			<ModalText>Congratulations on publishing your {type}!</ModalText>
			<ModalButtons>
				<Button variant="secondary" onClick={onClose}>
					Close
				</Button>
				<Button
					variant="primary"
					onClick={`//${platform.domain.platform}${getUrlPathByItem(type, itemId)}`}
					newTab
				>
					Visit {type}
				</Button>
			</ModalButtons>
		</>
	))
}
