import { type FC, useEffect, useState } from "react"

import { useGoogleSignIn } from "~/contexts/GoogleSignInContext"

const GoogleSigninButton: FC<{ text: "signin" | "signup"; className?: string }> = ({ text, className }) => {
	const googleSignIn = useGoogleSignIn()

	const [buttonElement, setButtonElement] = useState<HTMLDivElement | null>(null)

	useEffect(() => {
		if (buttonElement === null) return

		window.google?.accounts.id.renderButton(buttonElement, {
			theme: "outline",
			size: "large",
			shape: "rectangular",
			type: "standard",
			text: text === "signin" ? "signin_with" : "signup_with",
			logo_alignment: "left",
		})
	}, [buttonElement, googleSignIn.isInitialized, text])

	return <div ref={setButtonElement} className={className} />
}

export default GoogleSigninButton
