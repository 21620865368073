import { type ReactNode } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import { BackIcon } from "~/components/Icon"

const BackButton: React.FC<{ onClick: string | (() => void); children: ReactNode }> = ({ onClick, children }) => (
	<Container onClick={onClick}>
		<StyledIcon />
		{children}
	</Container>
)

const Container = styled(Button)`
	font-weight: 500;
	color: #667085;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
`

const StyledIcon = styled(BackIcon)`
	width: 20px;
	height: 20px;
`

export default BackButton
