import dayjs from "dayjs"
import { type FC, useState } from "react"
import styled from "styled-components"

import { toggleStateArray } from "@forento/shared/utilities/array"
import { toNullIfEmpty } from "@forento/shared/utilities/string"

import Button from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import InputField, { InputTextArea, useRichTextArea } from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import { DefaultModal, ModalButtons, ModalContentPadding, ModalInputs, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { useAlert } from "~/contexts/AlertContext"
import usePrice from "~/hooks/usePrice"
import trpc, { swr } from "~/utilities/trpc"

type Props = { isOpen: boolean; onClose(): void; onCreated(id: number): void }

const CreateEventModal: FC<Props> = ({ isOpen, onClose, onCreated }) => {
	const alert = useAlert()
	const now = dayjs()

	const [title, setTitle] = useState("")
	const [shortDescription, setShortDescription] = useState("")
	const longDescription = useRichTextArea({ label: "Description" })
	const [startDate, setStartDate] = useState(now.set("minute", 0).add(1, "hour"))
	const [endDate, setEndDate] = useState(now.set("minute", 0).add(2, "hour"))
	const [isPaid, setPaid] = useState(false)
	const { price, setPrice, priceValue } = usePrice()
	const [location, setLocation] = useState("")
	const [videoCallLink, setVideoCallLink] = useState("")
	const [membershipIds, setMembershipIds] = useState<number[]>([])
	const [isSubmitting, setSubmitting] = useState(false)

	const memberships = swr.membership.list.useSWR(undefined, { isDisabled: !isOpen })

	const isInputValid = title.trim().length > 0

	const handleSubmit = async () => {
		if (!isInputValid) return

		if (startDate > endDate) {
			await alert.show("Invalid date", "The start date must be before the end date.")
			return
		}

		setSubmitting(true)
		try {
			const created = await trpc.event.create.mutate({
				title: title.trim(),
				shortDescription,
				longDescription: longDescription.exportEditorState(),
				startDate: startDate.toDate(),
				endDate: endDate.toDate(),
				price: isPaid ? priceValue : null,
				location: toNullIfEmpty(location.trim()) ?? null,
				videoCallLink: toNullIfEmpty(videoCallLink.trim()) ?? null,
				membershipIds,
			})

			setTitle("")
			setShortDescription("")
			longDescription.set(null)
			setStartDate(now.set("minute", 0).add(1, "hour"))
			setEndDate(now.set("minute", 0).add(2, "hour"))
			setPaid(false)
			setPrice("")
			setLocation("")
			setVideoCallLink("")
			setMembershipIds([])

			onCreated(created.id)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<DefaultModal isOpen={isOpen}>
			<ModalTitle>Create a new event</ModalTitle>
			{memberships.error ? (
				<ModalContentPadding>Failed to load</ModalContentPadding>
			) : !memberships.data ? (
				<PartialLoadingPage />
			) : (
				<ModalInputs>
					<InputField label="Title *" value={title} onChange={setTitle} />
					<InputField label="Short description" value={shortDescription} onChange={setShortDescription} />
					{longDescription.element}
					<InputField
						inputType="datetime-local"
						label="Start date *"
						value={startDate.format("YYYY-MM-DD HH:mm")}
						onChange={value => setStartDate(dayjs(value))}
					/>
					<InputField
						inputType="datetime-local"
						label="End date *"
						value={endDate.format("YYYY-MM-DD HH:mm")}
						onChange={value => setEndDate(dayjs(value))}
					/>
					<div>
						<StyledCheckbox label="Is paid" isChecked={isPaid} onChange={setPaid} />
						<InputField
							label="Price"
							value={price}
							inputType="number"
							onChange={setPrice}
							disabled={!isPaid}
						/>
					</div>
					<InputTextArea label="Location" value={location} onChange={setLocation} />
					<InputField label="Video call link" value={videoCallLink} onChange={setVideoCallLink} />
					{memberships.data.length > 0 && (
						<div>
							<InputLabel>Include in memberships</InputLabel>
							<Memberships>
								{memberships.data.map(membership => (
									<Checkbox
										key={membership.id}
										label={membership.title}
										isChecked={membershipIds.includes(membership.id)}
										onChange={() => toggleStateArray(setMembershipIds, membership.id)}
									/>
								))}
							</Memberships>
						</div>
					)}
				</ModalInputs>
			)}
			<ModalButtons>
				<Button variant="secondary" onClick={onClose} isDisabled={isSubmitting}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleSubmit} isLoading={isSubmitting} isDisabled={!isInputValid}>
					Create event
				</Button>
			</ModalButtons>
		</DefaultModal>
	)
}

const StyledCheckbox = styled(Checkbox)`
	margin-bottom: 8px;
`

const Memberships = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 8px;
`

export default CreateEventModal
