import { type Payment, type PaymentStatus } from "@forento/shared/models/payment"
import { toPriceString } from "@forento/shared/utilities/currency"
import { toDateTimeString } from "@forento/shared/utilities/date"

import Table from "~/components/Table"

const PaymentsTab: React.FC<{ payments: Payment[] }> = ({ payments }) => (
	<Table
		columns={["Date", "Status", "Gross total", "Fee", "Net total"]}
		data={payments.map(payment => [
			toDateTimeString(payment.date),
			<PaymentStatusText status={payment.status} />,
			toPriceString({
				currency: payment.currency,
				amount: payment.amount.grossTotal,
			}),
			toPriceString({
				currency: payment.currency,
				amount: payment.amount.applicationFee,
			}),
			toPriceString({
				currency: payment.currency,
				amount: payment.amount.netTotal,
			}),
		])}
	/>
)

const PaymentStatusText: React.FC<{ status: PaymentStatus }> = ({ status }) => {
	if (status === "succeeded") return <>Paid</>
	else if (status === "pending") return <>Pending payment</>
	else if (status === "failed") return <>Failed</>
	else return <>Unknown</>
}

export default PaymentsTab
