import { type FC, useState } from "react"
import styled from "styled-components"
import tinycolor from "tinycolor2"

import { type PersonalizedCourse } from "@forento/shared/models/course"
import { toPriceString } from "@forento/shared/utilities/currency"

import Button from "~/components/Button"
import { usePlatform } from "~/contexts/UserContext"
import { dangerColor, primaryColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

type Props = { course: PersonalizedCourse; userId: number; reload(): Promise<unknown> }
const Course: FC<Props> = ({ course, userId, reload }) => {
	const hasAssignProductAccess = usePlatform()!.plan.access.assignProducts

	const [isSubmittingAccess, setSubmittingAccess] = useState(false)

	async function handleGiveAccess() {
		setSubmittingAccess(true)
		try {
			await trpc.product.giveManualAccess.mutate({ product: { type: "course", id: course.id }, userId })
			await reload()
		} catch (error) {
			console.error(error)
		} finally {
			setSubmittingAccess(false)
		}
	}

	async function handleRemoveAccess() {
		setSubmittingAccess(true)
		try {
			await trpc.product.removeManualAccess.mutate({ product: { type: "course", id: course.id }, userId })
			await reload()
		} catch (error) {
			console.error(error)
		} finally {
			setSubmittingAccess(false)
		}
	}

	return (
		<Container>
			<Title>{course.title}</Title>
			<Tags>
				{course.status === "unlocked" ? (
					<>
						{course.unlockedCause === "purchased" &&
							course.order.type === "external" &&
							course.order.createdBy === "manual" && (
								<AccessButton $isDanger onClick={handleRemoveAccess} isLoading={isSubmittingAccess}>
									Remove access
								</AccessButton>
							)}
						<Tag $color={dangerColor}>
							{course.progress === 1 ? "Completed" : `${Math.floor(course.progress * 100)}%`}
						</Tag>
						{course.unlockedCause === "tester" ? (
							<Tag $color={primaryColor}>Tester</Tag>
						) : course.unlockedCause === "purchased" ? (
							course.order.type === "external" ? (
								course.order.createdBy === "zapier" ? (
									<Tag $color={primaryColor}>Purchased through Zapier</Tag>
								) : (
									<Tag $color={primaryColor}>Manual access</Tag>
								)
							) : (
								<Tag $color={primaryColor}>Purchased</Tag>
							)
						) : course.unlockedCause === "membership" ? (
							<Tag $color={primaryColor}>Membership</Tag>
						) : null}
					</>
				) : (
					hasAssignProductAccess && (
						<AccessButton onClick={handleGiveAccess} isLoading={isSubmittingAccess}>
							Give access
						</AccessButton>
					)
				)}
				<Tag $color="#00B75A">
					{course.price !== null
						? toPriceString(course.price)
						: course.membershipIds.length > 0
							? "Membership"
							: "Free"}
				</Tag>
			</Tags>
		</Container>
	)
}

const Container = styled.div`
	background-color: #fbfbf9;
	border: 1px solid #eaeaea;
	border-radius: 12px;
	padding: 12px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
`

const Title = styled.p`
	font-weight: 600;
	font-size: 14px;
`

const Tags = styled.div`
	display: flex;
	gap: 16px;
`

const Tag = styled.div<{ $color: string }>`
	font-weight: 600;
	font-size: 12px;
	line-height: 1;
	color: ${props => props.$color};
	background-color: ${props => tinycolor(props.$color).setAlpha(0.05).toString()};
	border-radius: 12px;
	padding: 6px 12px;
`

const AccessButton = styled(Button).attrs({ loadingColor: "white" })<{ $isDanger?: boolean }>`
	font-weight: 600;
	font-size: 12px;
	line-height: 1;
	color: white;
	background-color: ${props => (props.$isDanger ? dangerColor : primaryColor)};
	border: none;
	border-radius: 12px;
	padding: 6px 12px;
	display: flex;
	align-items: center;
	gap: 6px;
`

export default Course
