import type React from "react"
import { useLayoutEffect, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import { type VideoCaptionLanguageCode } from "@forento/shared/models/video"
import { toggleStateArray } from "@forento/shared/utilities/array"

import Button, { SubmitButton } from "~/components/Button"
import { SettingsIcon } from "~/components/Icon"
import InputField, { useRichTextArea } from "~/components/InputField"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import ManageTagsModal from "~/components/ManageTagsModal"
import { DefaultModal, ModalButtons, ModalText, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import ProductPricing from "~/components/ProductPricing"
import SelectTags from "~/components/SelectTags"
import VideoCaptions from "~/components/VideoCaptions"
import { useAlert } from "~/contexts/AlertContext"
import usePrice from "~/hooks/usePrice"
import { arrayBufferToBase64 } from "~/utilities/buffer"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

const EditVideoPage: React.FC = () => {
	const { videoId } = useParams()
	const navigate = useNavigate()
	const alert = useAlert()

	const video = swr.video.get.useSWR({ videoId: Number(videoId) })
	const tags = swr.video.listTags.useSWR()
	const memberships = swr.membership.list.useSWR()

	const [title, setTitle] = useState("")
	const [shortDescription, setShortDescription] = useState("")
	const longDescription = useRichTextArea({ label: "Description" })
	const [isSignInRequired, setSignInRequired] = useState<boolean>(false)
	const { price, priceValue, setPrice } = usePrice()
	const [membershipIds, setMembershipIds] = useState<number[]>([])
	const [selectedTags, setSelectedTags] = useState<number[]>([])
	const [addCaptions, setAddCaptions] = useState<{ language: VideoCaptionLanguageCode; file: File }[]>([])
	const [removeCaptions, setRemoveCaptions] = useState<VideoCaptionLanguageCode[]>([])

	const [isManageTagsModalOpen, setManageTagsModalOpen] = useState(false)
	const [isSubmitting, setSubmitting] = useState(false)
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
	const [isDeleting, setDeleting] = useState(false)

	useLayoutEffect(() => {
		setTitle(video.data?.title ?? "")
		setShortDescription(video.data?.shortDescription ?? "")
		longDescription.set(video.data?.longDescription ?? null)
		setSignInRequired(video.data?.isSignInRequired ?? false)
		setPrice(video.data?.price?.amount.toString() ?? "")
		setMembershipIds(video.data?.membershipIds ?? [])
		setSelectedTags(video.data?.tags.map(x => x.id) ?? [])
		setAddCaptions([])
		setRemoveCaptions([])
	}, [longDescription, setPrice, video.data])

	if (video === null) return <Navigate to={routes.video.index()} />

	const handleSubmit = async () => {
		if (!video.data || title.trim().length === 0) return

		setSubmitting(true)
		try {
			await trpc.video.update.mutate({
				videoId: video.data.id,
				data: {
					title,
					shortDescription,
					longDescription: longDescription.exportEditorState(),
					isSignInRequired,
					price: priceValue,
					membershipIds,
					tags: selectedTags,
					removeCaptions,
					addCaptions: await Promise.all(
						addCaptions.map(async caption => ({
							language: caption.language,
							base64: arrayBufferToBase64(await caption.file.arrayBuffer()),
						})),
					),
				},
			})
			await video.mutate()
			navigate(routes.video.index())
		} catch (error) {
			console.error(error)
			setSubmitting(false)
		}
	}

	const handleDelete = async () => {
		if (video.data == null) return
		setDeleting(true)
		try {
			const { status } = await trpc.video.delete.mutate({ videoId: video.data.id })
			if (status !== "success") {
				if (status === "is-purchased") {
					await alert.show("Error", "You cannot delete a video that is purchased by a customer.")
				}
				return
			}

			navigate(routes.video.index())
		} finally {
			setDeleting(false)
		}
	}

	if (video.data === null) return <Navigate to={routes.video.index()} />

	return (
		<Layout>
			<PageBreadcrumb title="Edit" path={[{ title: "Videos", link: routes.video.index() }]} />

			{video.error || tags.error || memberships.error ? (
				<p>Failed to load data.</p>
			) : !video.data || !tags.data || !memberships.data ? (
				<PartialLoadingPage />
			) : (
				<Container>
					<ManageTagsModal
						type="video"
						isOpen={isManageTagsModalOpen}
						tags={tags.data}
						onClose={() => setManageTagsModalOpen(false)}
						onChange={tags.mutate}
					/>
					<DefaultModal isOpen={isDeleteModalOpen} onSubmit={handleDelete}>
						<ModalTitle>Delete video</ModalTitle>
						<ModalText>Are you sure you want to delete this video? This action is irreversible!</ModalText>
						<ModalButtons>
							<Button
								variant="secondary"
								onClick={() => setDeleteModalOpen(false)}
								isDisabled={isDeleting}
							>
								No, cancel
							</Button>
							<SubmitButton variant="primary-danger" isLoading={isDeleting}>
								Yes, delete
							</SubmitButton>
						</ModalButtons>
					</DefaultModal>

					<Section>
						<Subtitle>Basic information</Subtitle>
						<InputField placeholder="Title" value={title} onChange={setTitle} />
						<InputField
							label="Short description"
							placeholder="Short description"
							value={shortDescription}
							onChange={setShortDescription}
						/>
						{longDescription.element}
					</Section>

					<Section>
						<Subtitle>Pricing</Subtitle>
						<ProductPricing
							type="video"
							isSignInRequired={isSignInRequired}
							setSignInRequired={setSignInRequired}
							price={price}
							setPrice={setPrice}
							membershipIds={membershipIds}
							setMembershipIds={setMembershipIds}
						/>
					</Section>

					<Section>
						<div>
							<Header>
								<Subtitle>Tags</Subtitle>
								<Tooltip tooltip="Manage tags">
									<TagSettingsButton onClick={() => setManageTagsModalOpen(true)}>
										<SettingsIcon />
									</TagSettingsButton>
								</Tooltip>
							</Header>
							<Description>
								Tags help students find your video when they search for specific topics.
							</Description>
						</div>
						<SelectTags
							tags={tags.data}
							selectedTags={selectedTags}
							onChange={tagId => toggleStateArray(setSelectedTags, tagId)}
						/>
					</Section>

					<Section>
						<Subtitle>Captions</Subtitle>
						{video.data.captions ? (
							<VideoCaptions
								captions={video.data.captions}
								addCaptions={addCaptions}
								removeCaptions={removeCaptions}
								setAddCaptions={setAddCaptions}
								setRemoveCaptions={setRemoveCaptions}
							/>
						) : (
							<p>Captions are only available to newer videos. Upload a new video to add captions.</p>
						)}
					</Section>

					<Buttons>
						<Button variant="primary-danger" onClick={() => setDeleteModalOpen(true)}>
							Delete
						</Button>
						<Button variant="primary" onClick={handleSubmit} isLoading={isSubmitting}>
							Save
						</Button>
					</Buttons>
				</Container>
			)}
		</Layout>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Buttons = styled.div`
	display: flex;
	gap: 16px;
	justify-content: flex-end;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 24px;
	margin-bottom: 10px;
`

const Subtitle = styled.h2`
	font-weight: 600;
	font-size: 24px;
	color: black;
`

const Description = styled.p`
	color: black;
`

const TagSettingsButton = styled(Button)`
	width: 24px;
	height: 24px;
`

export default EditVideoPage
