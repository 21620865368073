import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { type FC } from "react"
import styled, { css } from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import routes from "@forento/shared/routes"

import Button from "~/components/Button"
import {
	CommentIcon,
	DeleteIcon,
	DragIndicatorIcon,
	EditIcon,
	HideIcon,
	InputIcon,
	LinkIcon,
	PreviewIcon,
	SettingsIcon,
} from "~/components/Icon"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import { dangerColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

type Props = {
	course: {
		id: number
		title: string
		publishDate: Date
		sales: number
		totalCommentsUnderReview: number
	}
	reload(): Promise<unknown>
}

const PublishedCourse: FC<Props> = ({
	course: { id, title, publishDate, sales, totalCommentsUnderReview },
	reload,
}) => {
	const user = useUser()
	const alert = useAlert()

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

	const style = { transform: CSS.Transform.toString(transform), transition }

	return (
		<ListItem ref={setNodeRef} style={style}>
			<div>
				<Button onClick={routes.app.course.detail(id)}>
					<Title>{title}</Title>
				</Button>
				<Subtitle>Published {publishDate.toLocaleDateString()}</Subtitle>
			</div>
			<Actions>
				{totalCommentsUnderReview > 0 && (
					<Tooltip tooltip="Review comments">
						<ActionButton onClick={routes.app.course.reviewComments(id)}>
							<NewCommentIndicator />
							<CommentIcon />
						</ActionButton>
					</Tooltip>
				)}
				<Tooltip tooltip="Edit">
					<ActionButton onClick={routes.app.course.detail(id)}>
						<EditIcon />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Inputs">
					<ActionButton onClick={routes.app.course.inputs(id)}>
						<InputIcon />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Settings">
					<ActionButton onClick={routes.app.course.settings(id)}>
						<SettingsIcon />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Open link">
					<ActionButton
						onClick={`//${user.user!.platform!.domain.platform}${routes.platform.course.detail(id)}`}
						newTab
					>
						<LinkIcon />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip="Preview">
					<ActionButton
						onClick={`//${user.user!.platform!.domain.platform}${routes.platform.course.player(
							id,
						)}?email=${encodeURIComponent(user.user!.email)}`}
						newTab
					>
						<PreviewIcon />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip={sales > 0 ? "You can't unpublish a course that has sales" : "Unpublish"}>
					<ActionButton
						isDisabled={sales > 0}
						onClick={async () => {
							const result = await alert.confirm(
								"Unpublish course",
								`Are you sure you want to unpublish the course **${title}**?`,
							)
							if (!result.result) return
							await trpc.course.unpublish.mutate(id)
							await reload()
							result.close()
						}}
					>
						<HideIcon />
					</ActionButton>
				</Tooltip>
				<Tooltip tooltip={sales > 0 ? "You can't delete a course that has sales" : "Delete"}>
					<ActionButton
						isDanger
						isDisabled={sales > 0}
						onClick={async () => {
							const result = await alert.confirm(
								"Delete course",
								`Are you sure you want to delete the course **${title}**? This action is irreversible.`,
							)
							if (!result.result) return
							await trpc.course.deleteById.mutate(id)
							await reload()
							result.close()
						}}
					>
						<DeleteIcon />
					</ActionButton>
				</Tooltip>
				<DragButton {...attributes} {...listeners}>
					<DragIndicatorIcon />
				</DragButton>
			</Actions>
		</ListItem>
	)
}

const ListItem = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 0;

	&:not(:last-child) {
		border-bottom: 1px solid #d6d6d6;
	}
`

const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 16px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 4px;
`

const Subtitle = styled.p`
	font-size: 14px;
	color: #6f6f6f;
`

const ActionButton = styled(Button)<{ isDanger?: boolean }>`
	width: 24px;
	height: 24px;

	${props =>
		props.isDanger &&
		!props.isDisabled &&
		css`
			color: ${dangerColor};
		`}
`

const DragButton = styled.div`
	width: 24px;
	height: 24px;
	cursor: grab;
`

const NewCommentIndicator = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: red;
`

export default PublishedCourse
