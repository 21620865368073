import styled from "styled-components"

import Layout, { PageBreadcrumb } from "~/components/Layout"
import { RouteTabs } from "~/components/Tabs"
import { usePlatform } from "~/contexts/UserContext"
import routes from "~/utilities/routes"

import BillingHistory from "./BillingHistory"
import PaymentMethod from "./PaymentMethod"
import Plans from "./Plans"

const BillingSettingsPage: React.FC = () => {
	const platform = usePlatform()!

	return (
		<Layout>
			<PageBreadcrumb title="Billing &amp; Plans" path={[{ title: "Settings", link: routes.settings.index() }]} />

			<RouteTabs
				tabs={[
					{
						title: "Plans",
						route: routes.settings.billing.plans(),
						content: (
							<TabContent>
								<Plans />
							</TabContent>
						),
					},
					{
						title: "Billing history",
						route: routes.settings.billing.history(),
						content: (
							<TabContent>
								<BillingHistory />
							</TabContent>
						),
					},
					...(platform.stripeCustomerId !== null
						? [
								{
									title: "Payment method",
									route: routes.settings.billing.paymentMethod(),
									content: (
										<TabContent>
											<PaymentMethod />
										</TabContent>
									),
								},
							]
						: []),
				]}
			/>
		</Layout>
	)
}

const TabContent = styled.div`
	margin-top: 24px;
`

export default BillingSettingsPage
