import {
	DndContext,
	type DragEndEvent,
	KeyboardSensor,
	PointerSensor,
	closestCenter,
	useSensor,
	useSensors,
} from "@dnd-kit/core"
import {
	SortableContext,
	rectSortingStrategy,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import { type FC, type ReactNode } from "react"

type Props = {
	type?: "vertical" | "grid"
	items: (number | string)[]
	onDragEnd(event: DragEndEvent): void
	children: ReactNode
}
const SortableListContext: FC<Props> = ({ type = "vertical", items, onDragEnd, children }) => {
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
	)

	return (
		<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
			<SortableContext
				items={items}
				strategy={type === "vertical" ? verticalListSortingStrategy : rectSortingStrategy}
			>
				{children}
			</SortableContext>
		</DndContext>
	)
}

export default SortableListContext
