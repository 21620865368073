import { useState } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import { primaryColor } from "~/utilities/styles"

const Preview: React.FC<{ imageFilePath: string; onDelete?: () => Promise<void> }> = ({ imageFilePath, onDelete }) => {
	const [isDeleting, setDeleting] = useState(false)

	const handleDelete = onDelete
		? async () => {
				setDeleting(true)
				try {
					await onDelete()
				} finally {
					setDeleting(false)
				}
			}
		: null

	return (
		<Container>
			<FileIconContainer>
				<FileIcon />
			</FileIconContainer>
			<Content>
				<Image alt="Image preview" src={imageFilePath} />
			</Content>
			{handleDelete !== null && (
				<Button variant="primary-danger" onClick={handleDelete} isLoading={isDeleting}>
					Delete image
				</Button>
			)}
			<CheckIcon />
		</Container>
	)
}

const Container = styled.div`
	background-color: white;
	border: 1px solid ${primaryColor};
	padding: 16px;
	display: flex;
	align-items: flex-start;
	gap: 16px;
	border-radius: 8px;
`

const FileIconContainer = styled.div`
	flex: 0 0 auto;
	width: 36px;
	height: 36px;
	background-color: #edebf3;
	border: 4px solid #f6f3ff;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`

const FileIcon: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height="16" width="16" fill={primaryColor}>
		<path d="M11 44Q9.8 44 8.9 43.1Q8 42.2 8 41V7Q8 5.8 8.9 4.9Q9.8 4 11 4H29.05L40 14.95V41Q40 42.2 39.1 43.1Q38.2 44 37 44ZM11 41H37Q37 41 37 41Q37 41 37 41V16.3H27.55V7H11Q11 7 11 7Q11 7 11 7V41Q11 41 11 41Q11 41 11 41ZM11 41Q11 41 11 41Q11 41 11 41V16.3V7Q11 7 11 7Q11 7 11 7V41Q11 41 11 41Q11 41 11 41Z" />
	</svg>
)

const Content = styled.div`
	flex: 1;
	align-self: stretch;
	display: flex;
	align-items: center;
`

const Image = styled.img`
	flex: 0 0 auto;
	max-width: 128px;
	max-height: 128px;
`

const CheckIcon: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height="16" width="16" fill={primaryColor}>
		<path d="M24 44Q19.75 44 16.1 42.475Q12.45 40.95 9.75 38.25Q7.05 35.55 5.525 31.9Q4 28.25 4 24Q4 19.8 5.525 16.15Q7.05 12.5 9.75 9.8Q12.45 7.1 16.1 5.55Q19.75 4 24 4Q28.2 4 31.85 5.55Q35.5 7.1 38.2 9.8Q40.9 12.5 42.45 16.15Q44 19.8 44 24Q44 28.25 42.45 31.9Q40.9 35.55 38.2 38.25Q35.5 40.95 31.85 42.475Q28.2 44 24 44ZM21.05 33.1 35.2 18.95 32.9 16.7 21.05 28.55 15.05 22.55 12.8 24.8Z" />
	</svg>
)

export default Preview
