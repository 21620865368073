import type React from "react"
import { useState } from "react"
import { useNavigate } from "react-router"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import {
	type VideoCaptionLanguageCode,
	videoCaptionLanguageCodes,
	videoCaptionLanguages,
} from "@forento/shared/models/video"
import { toggleStateArray } from "@forento/shared/utilities/array"

import Button from "~/components/Button"
import Dropdown from "~/components/Dropdown"
import FileChooser from "~/components/FileChooser"
import { SettingsIcon, SubtitlesIcon } from "~/components/Icon"
import InputField, { useRichTextArea } from "~/components/InputField"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import ManageTagsModal from "~/components/ManageTagsModal"
import { DefaultModal, ModalButtons, ModalInputs, ModalTitle } from "~/components/Modal"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import ProductPricing from "~/components/ProductPricing"
import SelectTags from "~/components/SelectTags"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import usePrice from "~/hooks/usePrice"
import { arrayBufferToBase64 } from "~/utilities/buffer"
import routes from "~/utilities/routes"
import { primaryColor } from "~/utilities/styles"
import trpc, { swr } from "~/utilities/trpc"

const CreateVideoPage: React.FC = () => {
	const navigate = useNavigate()
	const alert = useAlert()
	const platform = useUser().user!.platform!

	const [title, setTitle] = useState("")
	const [shortDescription, setShortDescription] = useState("")
	const longDescription = useRichTextArea({ label: "Description" })
	const [isSignInRequired, setSignInRequired] = useState(false)
	const { price, priceValue, setPrice } = usePrice()
	const [membershipIds, setMembershipIds] = useState<number[]>([])
	const [selectedTags, setSelectedTags] = useState<number[]>([])
	const [captions, setCaptions] = useState<{ language: VideoCaptionLanguageCode; file: File }[]>([])
	const [newCaptionLanguage, setNewCaptionLanguage] = useState<VideoCaptionLanguageCode>("en")
	const [newCaptionFile, setNewCaptionFile] = useState<File | null>(null)

	const [isManageTagsModalOpen, setManageTagsModalOpen] = useState(false)
	const [isSubmitting, setSubmitting] = useState(false)

	const tags = swr.video.listTags.useSWR()
	const memberships = swr.membership.list.useSWR()

	const handleSubmit = async () => {
		if (title.trim().length === 0) return
		if (!platform.paymentGateway?.isEnabled && priceValue !== null) {
			alert.show("Error", "You need to enable payments if you want to have paid videos.")
			return
		}

		setSubmitting(true)
		try {
			const video = await trpc.video.create.mutate({
				title,
				shortDescription,
				longDescription: longDescription.exportEditorState(),
				isSignInRequired,
				price: priceValue,
				membershipIds,
				tags: selectedTags,
				captions: await Promise.all(
					captions.map(async caption => ({
						language: caption.language,
						base64: arrayBufferToBase64(await caption.file.arrayBuffer()),
					})),
				),
			})
			navigate(routes.video.upload(video.id))
		} catch (error) {
			console.error(error)
			setSubmitting(false)
		}
	}

	return (
		<Layout>
			<DefaultModal isOpen={Boolean(newCaptionFile)}>
				<ModalTitle>Add captions</ModalTitle>
				<ModalInputs>
					<Dropdown
						items={videoCaptionLanguageCodes.map(x => ({ id: x, title: videoCaptionLanguages[x] }))}
						selectedItemId={newCaptionLanguage}
						onChange={value =>
							setNewCaptionLanguage(videoCaptionLanguageCodes.find(x => x === value) ?? "en")
						}
					/>
				</ModalInputs>
				<ModalButtons>
					<Button variant="secondary" onClick={() => setNewCaptionFile(null)}>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							if (!newCaptionFile) return
							setCaptions(current => [...current, { language: newCaptionLanguage, file: newCaptionFile }])
							setNewCaptionFile(null)
							setNewCaptionLanguage("en")
						}}
					>
						Add captions
					</Button>
				</ModalButtons>
			</DefaultModal>
			<PageBreadcrumb title="Upload" path={[{ title: "Videos", link: routes.video.index() }]} />
			{memberships.error || tags.error ? (
				<p>Failed to load data.</p>
			) : memberships.data === undefined || tags.data === undefined ? (
				<PartialLoadingPage />
			) : (
				<Container>
					<ManageTagsModal
						type="video"
						isOpen={isManageTagsModalOpen}
						tags={tags.data}
						onClose={() => setManageTagsModalOpen(false)}
						onChange={tags.mutate}
					/>

					<Section>
						<Title>Basic information</Title>
						<InputField label="Title" value={title} onChange={setTitle} />
						<InputField label="Short description" value={shortDescription} onChange={setShortDescription} />
						{longDescription.element}
					</Section>

					<Section>
						<Title>Pricing</Title>
						<ProductPricing
							type="video"
							isSignInRequired={isSignInRequired}
							setSignInRequired={setSignInRequired}
							price={price}
							setPrice={setPrice}
							membershipIds={membershipIds}
							setMembershipIds={setMembershipIds}
						/>
					</Section>

					<Section>
						<Header>
							<Title>Tags</Title>
							<Tooltip tooltip="Manage tags">
								<TagSettingsButton onClick={() => setManageTagsModalOpen(true)}>
									<SettingsIcon />
								</TagSettingsButton>
							</Tooltip>
						</Header>
						<Description>
							Tags help students find your video when they search for specific topics.
						</Description>
						<SelectTags
							tags={tags.data}
							selectedTags={selectedTags}
							onChange={tagId => toggleStateArray(setSelectedTags, tagId)}
						/>
					</Section>

					<Section>
						<Title>Captions</Title>
						{captions.length > 0 && (
							<Captions>
								{captions.map(caption => (
									<Caption key={caption.language}>
										<CaptionIcon />
										{videoCaptionLanguages[caption.language]}
									</Caption>
								))}
							</Captions>
						)}
						<UploadCaptions>
							<FileChooser type="video-captions" value={newCaptionFile} onChange={setNewCaptionFile} />
						</UploadCaptions>
					</Section>

					<SaveButton onClick={handleSubmit} isLoading={isSubmitting}>
						Save
					</SaveButton>
				</Container>
			)}
		</Layout>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`

const Section = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 24px;
	color: black;
`

const Description = styled.p`
	color: black;
`

const TagSettingsButton = styled(Button)`
	width: 24px;
	height: 24px;
`

const Captions = styled.div`
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
`

const Caption = styled.div`
	padding: 8px 16px;
	border-radius: 36px;
	line-height: 1;
	color: white;
	background-color: ${primaryColor};
	display: flex;
	align-items: center;
	gap: 8px;
`

const CaptionIcon = styled(SubtitlesIcon)`
	width: 20px;
	height: 20px;
`

const UploadCaptions = styled.div`
	max-width: 300px;
	display: flex;
	flex-direction: column;
	gap: 8px;
`

const SaveButton = styled(Button).attrs({ variant: "primary" })`
	align-self: flex-end;
`

export default CreateVideoPage
