import { useState } from "react"

import Button from "~/components/Button"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import trpc from "~/utilities/trpc"

import { SectionTitle, Section } from "./styles"

const SetPassword: React.FC = () => {
	const alert = useAlert()
	const user = useUser().user!

	const [isSubmitting, setSubmitting] = useState(false)

	const handleSubmit = async () => {
		setSubmitting(true)
		try {
			await trpc.user.resetCreatorPassword.mutate(user.email)
			alert.show("Email sent", "A password reset email has been sent to your email address.")
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Section>
			<SectionTitle>Change password</SectionTitle>
			<p>
				Since you've registered with a social sign in method, you don't have a password for your account. If you
				want to be able to sign in using a password, we can send an email to you with a link to set a password.
			</p>
			<Button variant="primary" onClick={handleSubmit} isLoading={isSubmitting}>
				Send email
			</Button>
		</Section>
	)
}

export default SetPassword
