import styled, { css } from "styled-components"

const SelectedStatus: React.FC<{ isSelected: boolean }> = ({ isSelected }) => (
	<Container $isSelected={isSelected}>
		{isSelected && <SelectedIcon />}
		{isSelected ? "Published" : "Not published"}
	</Container>
)

const Container = styled.p<{ $isSelected: boolean }>`
	font-size: 12px;
	display: flex;
	gap: 4px;
	align-items: center;

	${props =>
		props.$isSelected &&
		css`
			font-weight: 600;
			color: #00b533;
		`}
`

const SelectedIcon = styled.span`
	width: 8px;
	height: 8px;
	background-color: #00d33b;
	border-radius: 50%;
`

export default SelectedStatus
