import { type FC } from "react"
import { Helmet } from "react-helmet-async"
import { Pie, PieChart, ResponsiveContainer, Cell } from "recharts"
import styled from "styled-components"

import AspectRatio from "@forento/shared/components/AspectRatio"

import { DeviceIcon } from "~/components/Icon"
import { primaryColor } from "~/utilities/styles"

const COLORS = [primaryColor, "#F6BE4F", "#80D3FF", "#DCDCDC"]

const Devices: FC<{ devices: { label: string; visitsShare: number }[] }> = ({ devices }) => (
	<Container>
		<Helmet>
			<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto+Mono" />
		</Helmet>
		<Row>
			<Icon as={DeviceIcon} />
			<Title>Devices</Title>
		</Row>
		{devices.every(device => device.visitsShare === 0) ? (
			<NoDataText>No device data</NoDataText>
		) : (
			<Content>
				<AspectRatio aspectRatio={1} width={{ unit: "percent", value: 40 }}>
					<ResponsiveContainer>
						<PieChart>
							<Pie
								data={devices}
								nameKey="label"
								dataKey="visitsShare"
								innerRadius="75%"
								outerRadius="100%"
								cornerRadius={10}
							>
								{devices.map((device, index) => (
									<Cell key={device.label} fill={COLORS[index % COLORS.length]} />
								))}
							</Pie>
						</PieChart>
					</ResponsiveContainer>
				</AspectRatio>
				<LegendContainer>
					{devices.map((device, index) => (
						<LegendItem key={device.label}>
							<LegendColor $color={COLORS[index % COLORS.length]} />
							<LegendShare>{(device.visitsShare * 100).toFixed(2).padStart(5, "0")}%</LegendShare>
							<LegendLabel>{device.label}</LegendLabel>
						</LegendItem>
					))}
				</LegendContainer>
			</Content>
		)}
	</Container>
)

const Container = styled.div`
	grid-area: devices;
	background-color: #f4f4f4;
	padding: 32px;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
`

const Row = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-bottom: 32px;
`

const Icon = styled.div`
	width: 32px;
	height: 32px;
`

const Title = styled.h2`
	font-size: 16px;
	color: black;
`

const Content = styled.div`
	display: flex;
	align-items: center;
	gap: 32px;
	justify-content: space-between;
`

const LegendContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`

const LegendItem = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`

const LegendColor = styled.div<{ $color: string }>`
	width: 20px;
	height: 6px;
	background-color: ${props => props.$color};
	border-radius: 3px;
`

const LegendShare = styled.div`
	font-size: 14px;
`

const LegendLabel = styled.div`
	font-size: 14px;
`

const NoDataText = styled.p`
	font-size: 14px;
	margin-top: 8px;
`

export default Devices
