import { useEffect, useId, useState } from "react"
import styled from "styled-components"

import { type CommunityPrivilege, communityPrivileges } from "@forento/shared/models/community"
import { getCommunityPrivilegeDisplayName } from "@forento/shared/utilities/community"

import Button from "~/components/Button"
import Dropdown from "~/components/Dropdown"
import InputLabel from "~/components/InputLabel"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import trpc from "~/utilities/trpc"

import GamificationLevels from "./GamificationLevels"

const CommunitySettings: React.FC = () => {
	const user = useUser()
	const alert = useAlert()
	const platform = user.user!.platform!
	const defaultPrivilegeId = useId()

	const [defaultCommunityPrivilege, setDefaultCommunityPrivilege] = useState<CommunityPrivilege | null>(
		platform.community?.defaultPrivilege ?? null,
	)

	const [isSubmittingDefaultPrivilege, setSubmittingDefaultPrivilege] = useState(false)

	useEffect(() => {
		;(async () => {
			if ((platform.community?.defaultPrivilege ?? null) === defaultCommunityPrivilege) return
			setSubmittingDefaultPrivilege(true)
			try {
				await trpc.platform.update.mutate({ defaultCommunityPrivilege })
				await user.reload()
			} finally {
				setSubmittingDefaultPrivilege(false)
			}
		})()
	}, [defaultCommunityPrivilege, platform.community?.defaultPrivilege, user])

	async function handleDeleteCommunity() {
		const dialog = await alert.confirm("Delete community", "Are you sure you want to delete the community?")
		if (!dialog.result) return
		await trpc.platform.update.mutate({ isCommunityEnabled: false })
		await user.reload()
		dialog.close()
	}

	return (
		<Container>
			<div>
				<InputLabel htmlFor={defaultPrivilegeId}>Default user privilege</InputLabel>
				<Dropdown
					id={defaultPrivilegeId}
					items={[
						{ id: "none", title: "None" },
						...communityPrivileges.map(privilege => ({
							id: privilege,
							title: getCommunityPrivilegeDisplayName(privilege),
						})),
					]}
					selectedItemId={defaultCommunityPrivilege ?? "none"}
					onChange={value => setDefaultCommunityPrivilege(communityPrivileges.find(x => x === value) ?? null)}
					isDisabled={isSubmittingDefaultPrivilege}
				/>
			</div>
			<GamificationLevels />
			<div>
				<InputLabel>Danger zone</InputLabel>
				<Button variant="primary-danger" onClick={handleDeleteCommunity}>
					Delete community
				</Button>
			</div>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`

export default CommunitySettings
