import { useEffect, useState } from "react"
import styled from "styled-components"

import { type CoursePageLink } from "@forento/shared/models/course"

import Button from "~/components/Button"
import { DeleteIcon, LinkIcon } from "~/components/Icon"
import { dangerColor, primaryColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

import AddLink from "./AddLink"

const Links: React.FC<{ pageId: number; links: CoursePageLink[] }> = ({ pageId, links: initialLinks }) => {
	const [links, setLinks] = useState(initialLinks)

	useEffect(() => {
		setLinks(initialLinks)
	}, [pageId, initialLinks])

	const handleDeleteLinkById = async (pageLinkId: number) => {
		await trpc.course.deletePageLink.mutate(pageLinkId)
		setLinks(current => current.filter(link => link.id !== pageLinkId))
	}

	return (
		<Container>
			{links.map(link => (
				<Link key={link.id}>
					<StyledLinkIcon />
					<LinkLabel>
						{link.label} ({link.url})
					</LinkLabel>
					<DeleteLink onClick={() => handleDeleteLinkById(link.id)}>
						<DeleteIcon />
					</DeleteLink>
				</Link>
			))}
			<AddLink pageId={pageId} onAdd={attachment => setLinks(current => [...current, attachment])} />
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Link = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	border: 1px dashed ${primaryColor};
	border-radius: 8px;
	padding: 8px;
`

const StyledLinkIcon = styled(LinkIcon)`
	flex: 0 0 auto;
	width: 24px;
	height: 24px;
`

const LinkLabel = styled.p`
	flex: 1;
	font-weight: 500;
	font-size: 16px;
`

const DeleteLink = styled(Button)`
	flex: 0 0 auto;
	width: 24px;
	height: 24px;
	color: ${dangerColor};
`

export default Links
