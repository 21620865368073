import { type FC, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { Link } from "react-router-dom"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"
import { checkMjml } from "@forento/shared/utilities/mjml"
import { replacePlaceholders } from "@forento/shared/utilities/string"

import { SubmitButton } from "~/components/Button"
import EmailPreview from "~/components/EmailPreview"
import InputField from "~/components/InputField"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import RadioButtons from "~/components/RadioButtons"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import CodeEditor from "~/pages/settings/StudentEmailDetail/CodeEditor"
import routes from "~/utilities/routes"
import trpc, { swr } from "~/utilities/trpc"

const mjml2html = import("mjml-browser")

const options = [
	{ id: "default", label: "Use default content" },
	{ id: "custom", label: "Use custom content (MJML)" },
	{ id: "custom-html", label: "Use manual custom content (HTML, advanced)" },
] as const
type OptionId = (typeof options)[number]["id"]

function mapContent(args: {
	selectedOptionId: OptionId
	layout: string
	defaultContent: string
	customContent: string
	customHtmlContent: string
}): { type: "mjml" | "html"; value: string; valueWithLayout: string } {
	if (args.selectedOptionId === "default") {
		return {
			type: "mjml",
			value: args.defaultContent,
			valueWithLayout: args.layout.replace("{content}", args.defaultContent),
		}
	}
	if (args.selectedOptionId === "custom") {
		return {
			type: "mjml",
			value: args.customContent,
			valueWithLayout: args.layout.replace("{content}", args.customContent),
		}
	}
	return { type: "html", value: args.customHtmlContent, valueWithLayout: args.customHtmlContent }
}

const StudentEmailDetailSettingsPage: FC = () => {
	const { emailId } = useParams()
	const platform = useUser().user!.platform!
	const navigate = useNavigate()
	const alert = useAlert()

	const [selectedOptionId, setSelectedOptionId] = useState<OptionId>("default")
	const [customTitle, setCustomTitle] = useState("")
	const [customHtmlContent, setCustomHtmlContent] = useState("")
	const [customContent, setCustomContent] = useState("")
	const [isSubmitting, setSubmitting] = useState(false)

	const layout = swr.studentEmails.getLayout.useSWR()
	const email = swr.studentEmails.get.useSWR(emailId ?? "")

	useEffect(() => {
		setSelectedOptionId(
			email.data?.custom?.content.type === "html"
				? "custom-html"
				: email.data?.custom?.content.type === "mjml"
					? "custom"
					: "default",
		)
		setCustomTitle(email.data?.custom?.title ?? email.data?.default.title ?? "")
		if (email.data?.custom?.content?.type === "html") {
			setCustomHtmlContent(email.data.custom.content.value)
			setCustomContent(email.data.default.content)
		} else {
			setCustomContent(email.data?.custom?.content?.value ?? email.data?.default.content ?? "")
			setCustomHtmlContent("")
		}
	}, [email.data])

	const isValid =
		selectedOptionId === "default" ||
		(selectedOptionId === "custom" && customTitle.trim().length > 0 && customContent.trim().length > 0) ||
		(selectedOptionId === "custom-html" && customTitle.trim().length > 0 && customHtmlContent.trim().length > 0)

	const mappedContent =
		email.data && layout.data
			? {
					title: customTitle.trim(),
					content: mapContent({
						selectedOptionId,
						layout: layout.data.content,
						defaultContent: email.data.default.content,
						customContent,
						customHtmlContent,
					}),
					placeholders: [
						{ key: "platformName", value: platform.name },
						{ key: "primaryColor", value: platform.primaryColor.value },
						...email.data.placeholders.map(x => ({
							key: x.key,
							value: x.previewValue,
						})),
					],
				}
			: null

	const availablePlaceholderKeys = email.data
		? ["platformName", "primaryColor", ...email.data.placeholders.map(x => x.key)]
		: []

	const contentWithPlaceholders = mappedContent
		? {
				title: replacePlaceholders({ text: mappedContent.title, placeholders: mappedContent.placeholders }),
				content: {
					type: mappedContent.content.type,
					value: replacePlaceholders({
						text: mappedContent.content.valueWithLayout,
						placeholders: mappedContent.placeholders,
					}),
				},
			}
		: null

	async function handleSave() {
		if (!email.data || !contentWithPlaceholders || !isValid) return

		if (selectedOptionId === "custom") {
			const mjmlStatus = checkMjml(contentWithPlaceholders.content.value, (await mjml2html).default)
			if (mjmlStatus.status === "invalid") {
				if (mjmlStatus.errors.length > 0) {
					await alert.show(
						"Invalid content",
						`The custom content is not a valid MJML.\n\n${mjmlStatus.errors.map(x => `- ${x}`).join("\n")}`,
					)
				} else {
					await alert.show("Invalid content", `The custom content is not a valid MJML.`)
				}
				return
			}
		}

		setSubmitting(true)
		try {
			await trpc.studentEmails.update.mutate({
				emailId: email.data.id,
				data: {
					custom:
						mappedContent && selectedOptionId !== "default"
							? {
									title: mappedContent.title,
									content: { type: mappedContent.content.type, value: mappedContent.content.value },
									placeholders: mappedContent.placeholders,
								}
							: null,
				},
			})
			await email.mutate()
			navigate(routes.settings.studentEmail.index())
		} catch (error) {
			console.error(error)
		} finally {
			setSubmitting(false)
		}
	}

	return (
		<Layout asideContent={contentWithPlaceholders && <EmailPreview {...contentWithPlaceholders} />}>
			<PageBreadcrumb
				title={email.data === undefined ? "..." : email.data === null ? "Not Found" : email.data.label}
				path={[
					{ title: "Settings", link: routes.settings.index() },
					{ title: "Student Emails", link: routes.settings.studentEmail.index() },
				]}
			/>
			<Content onSubmit={handleSave}>
				{layout.error || email.error ? (
					<p>Failed to load student email.</p>
				) : !layout.data || !email.data ? (
					<PartialLoadingPage />
				) : (
					<>
						<RadioButtons
							options={options.map(x => ({ value: x.id, label: x.label }))}
							value={selectedOptionId}
							onChange={value => setSelectedOptionId(options.find(x => x.id === value)?.id ?? "default")}
						/>
						{selectedOptionId === "custom" && (
							<>
								<p>
									Design your email using{" "}
									<a
										href="https://documentation.mjml.io/#getting-started"
										target="_blank"
										rel="noopener noreferrer"
									>
										MJML
									</a>
									. This will use your layout customized{" "}
									<Link to={routes.settings.studentEmail.layout()}>here</Link>.
								</p>
								<p>
									The available placeholders are{" "}
									{availablePlaceholderKeys.map((key, index) => (
										<>
											<code key={key}>{`{${key}}`}</code>
											{index < availablePlaceholderKeys.length - 1 ? ", " : ""}
										</>
									))}
								</p>
								<StyledInput label="Subject" value={customTitle} onChange={setCustomTitle} />
								<CodeEditor
									label="MJML content"
									defaultValue={customContent}
									onChange={setCustomContent}
								/>
							</>
						)}
						{selectedOptionId === "custom-html" && (
							<>
								<p>
									Write email compliant HTML code to customize your email. This will not use your
									layout customized <Link to={routes.settings.studentEmail.layout()}>here</Link>.
									Please note that even if the email looks good in the preview it may not look
									identical when sent. This is because email clients have varying support for HTML. We
									recommend you design your email in MJML above instead.
								</p>
								<p>
									The available placeholders are{" "}
									{availablePlaceholderKeys.map((key, index) => (
										<>
											<code key={key}>{`{${key}}`}</code>
											{index < availablePlaceholderKeys.length - 1 ? ", " : ""}
										</>
									))}
								</p>
								<StyledInput label="Subject" value={customTitle} onChange={setCustomTitle} />
								<CodeEditor
									label="HTML content"
									defaultValue={customHtmlContent}
									onChange={setCustomHtmlContent}
								/>
							</>
						)}
						<SubmitButton variant="primary" isDisabled={!isValid} isLoading={isSubmitting}>
							Save
						</SubmitButton>
					</>
				)}
			</Content>
		</Layout>
	)
}

const Content = styled(Form)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`

const StyledInput = styled(InputField)`
	align-self: stretch;
`

export default StudentEmailDetailSettingsPage
