import { type ChangeEvent } from "react"
import styled from "styled-components"

interface DropdownItem {
	id: string
	title: string
}

interface DropdownProps {
	items: DropdownItem[]
	selectedItemId: string
	onChange: (id: string) => void
	id?: string
	isDisabled?: boolean
	className?: string
}

const Dropdown: React.FC<DropdownProps> = ({ items, selectedItemId, onChange, id, isDisabled, className }) => {
	const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const selectedItem = items.find(item => item.id === event.target.value)
		if (selectedItem === undefined) return
		onChange(selectedItem.id)
	}

	return (
		<Container value={selectedItemId} onChange={handleChange} id={id} disabled={isDisabled} className={className}>
			{items.map(item => (
				<option key={item.id} value={item.id}>
					{item.title}
				</option>
			))}
		</Container>
	)
}

const Container = styled.select`
	width: 100%;
	appearance: none;
	background: white;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M24 24H0V0h24v24z' fill='none' opacity='.87'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position-x: calc(100% - 12px);
	background-position-y: 5px;
	border: 1px solid #d0d5dd;
	border-radius: 8px;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	padding: 8px ${8 + 24 + 16}px 8px 16px;
`

export default Dropdown
