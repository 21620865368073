import { type ReactNode } from "react"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"

interface TableProps {
	columns: (string | { label: string; tooltip?: string; flex?: { grow: number; basis: number } })[]
	data: ReactNode[][]
	emptyText?: string
	isCompact?: boolean
	className?: string
}

const defaultFlex = { grow: 1, basis: 0 }

const Table: React.FC<TableProps> = ({ columns, data, emptyText, isCompact = false, className }) => (
	<Container className={className}>
		<Header $isCompact={isCompact}>
			{columns.map(column => {
				const label = typeof column === "string" ? column : column.label

				return (
					<HeaderCell
						key={label}
						$flex={typeof column !== "string" && column.flex !== undefined ? column.flex : defaultFlex}
						$isCompact={isCompact}
					>
						{typeof column !== "string" && column.tooltip !== undefined ? (
							<StyledTooltip tooltip={column.tooltip}>{label}</StyledTooltip>
						) : (
							label
						)}
					</HeaderCell>
				)
			})}
		</Header>
		{data.length > 0 ? (
			data.map((row, index) => (
				<TableRow key={index} $isCompact={isCompact}>
					{row.map((column, index) => {
						const columnHeader = columns[index]
						const flex =
							typeof columnHeader !== "string" && columnHeader.flex !== undefined
								? columnHeader.flex
								: defaultFlex
						return (
							<TableCell key={index} $flex={flex} $isCompact={isCompact}>
								{column}
							</TableCell>
						)
					})}
				</TableRow>
			))
		) : emptyText ? (
			<EmptyText>{emptyText}</EmptyText>
		) : null}
	</Container>
)

const Container = styled.div`
	width: 100%;
	background-color: white;
	border: 1px solid #e4e4e4;
	box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
	border-radius: 8px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`

const Header = styled.div<{ $isCompact: boolean }>`
	background: #fafafa;
	display: flex;
	gap: ${props => (props.$isCompact ? 16 : 32)}px;
`

const HeaderCell = styled.div<{ $flex: { grow: number; basis: number }; $isCompact: boolean }>`
	flex: ${props => `${props.$flex.grow} 0 ${props.$flex.basis}px`};
	box-sizing: content-box;
	color: #828282;
	font-weight: 600;
	font-size: 14px;
	padding: 12px ${props => (props.$isCompact ? 12 : 24)}px;
	overflow-wrap: anywhere;
	display: flex;
	align-items: flex-start;
`

const StyledTooltip = styled(Tooltip).attrs({ showInfoIndicator: true })`
	color: #828282;
	font-weight: 600;
	font-size: 14px;
`

const TableRow = styled.div<{ $isCompact: boolean }>`
	display: flex;
	gap: ${props => (props.$isCompact ? 16 : 32)}px;
	border-top: 1px solid #eeeeee;
`

const TableCell = styled.div<{ $flex: { grow: number; basis: number }; $isCompact: boolean }>`
	flex: ${props => `${props.$flex.grow} 0 ${props.$flex.basis}px`};
	box-sizing: content-box;
	font-size: 14px;
	padding: ${props => (props.$isCompact ? 12 : 24)}px;
	overflow-wrap: anywhere;
	display: flex;
	flex-direction: column;
	justify-content: center;
`

const EmptyText = styled.p`
	color: #828282;
	font-weight: 600;
	font-size: 14px;
	padding: 12px 24px;
	text-align: center;
`

export default Table
