import { type FC, useState } from "react"

import Dropzone from "./Dropzone"
import Preview from "./Preview"
import Uploading from "./Uploading"

type Props = { filePath: string | null; onUpload(file: File): Promise<void>; onDelete(): Promise<void> }

const ImageUploader: FC<Props> = ({ filePath, onUpload, onDelete }) => {
	const [uploadingFile, setUploadingFile] = useState<File>()

	if (filePath !== null) {
		return <Preview imageFilePath={filePath} onDelete={onDelete} />
	}

	if (uploadingFile) {
		return <Uploading file={{ name: uploadingFile.name, size: uploadingFile.size }} />
	}

	return (
		<Dropzone
			onImageDrop={async (file: File) => {
				setUploadingFile(file)
				await onUpload(file)
				setUploadingFile(undefined)
			}}
		/>
	)
}

export default ImageUploader
