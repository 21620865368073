import { type DragEndEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { type FC, useState, useMemo, useLayoutEffect } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import Layout, { PageTitle } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import Tabs from "~/components/Tabs"
import SortableListContext from "~/contexts/SortableListContext"
import useProductPublishedAlert from "~/hooks/useProductPublishedAlert"
import trpc, { swr } from "~/utilities/trpc"

import CreateNewModal from "./CreateNewModal"
import Exam from "./Exam"

const ExamsPage: FC = () => {
	const publishedAlert = useProductPublishedAlert("exam")

	const { data: exams, error, mutate } = swr.exam.list.useSWR()

	const [selectedTabIndex, setSelectedTabIndex] = useState(0)
	const [isCreateNewModalOpen, setCreateNewModalOpen] = useState(false)
	const [examsOrder, setExamsOrder] = useState<number[]>([])

	const publishedExams = useMemo(() => exams?.filter(exam => exam.publishDate), [exams])
	const draftExams = exams?.filter(exam => exam.publishDate === null)

	useLayoutEffect(() => {
		setExamsOrder(publishedExams?.toSorted((a, b) => a.sortOrder - b.sortOrder).map(exam => exam.id) ?? [])
	}, [publishedExams])

	function handleDragEnd(event: DragEndEvent) {
		if (!publishedExams) return
		const { active, over } = event
		if (!over || active.id === over.id) return

		const newItems = arrayMove(
			examsOrder,
			examsOrder.indexOf(Number(active.id)),
			examsOrder.indexOf(Number(over.id)),
		)
		setExamsOrder(newItems)

		for (const exam of publishedExams) {
			trpc.exam.update.mutate({ id: exam.id, data: { sortOrder: newItems.indexOf(exam.id) } })
		}
	}

	return (
		<Layout>
			<PageTitle>Exams</PageTitle>
			{error ? (
				<p>Failed to load exams.</p>
			) : publishedExams === undefined || draftExams === undefined ? (
				<PartialLoadingPage />
			) : (
				<>
					<Tabs
						selectedTabIndex={selectedTabIndex}
						setSelectedTabIndex={setSelectedTabIndex}
						tabs={[
							{
								title: "Published",
								sticker: publishedExams.length.toString(),
								content: (
									<SortableListContext items={examsOrder} onDragEnd={handleDragEnd}>
										<List>
											{publishedExams
												.toSorted((a, b) => examsOrder.indexOf(a.id) - examsOrder.indexOf(b.id))
												.map(exam => (
													<Exam key={exam.id} exam={exam} onDelete={mutate} />
												))}
										</List>
									</SortableListContext>
								),
							},
							{
								title: "Draft",
								sticker: draftExams.length.toString(),
								content: (
									<List>
										{draftExams.map(exam => (
											<Exam key={exam.id} exam={exam} onDelete={mutate} />
										))}
									</List>
								),
							},
						]}
					/>
					<CreateNewButton
						onClick={() => setCreateNewModalOpen(true)}
						reducedMargin={
							(selectedTabIndex === 0 && publishedExams.length > 0) ||
							(selectedTabIndex === 1 && draftExams.length > 0)
						}
					>
						Create exam
					</CreateNewButton>
					<CreateNewModal
						isOpen={isCreateNewModalOpen}
						onClose={() => setCreateNewModalOpen(false)}
						onCreated={async examId => {
							setSelectedTabIndex(1)
							await mutate()
							await publishedAlert.show({ itemId: examId })
						}}
					/>
				</>
			)}
		</Layout>
	)
}

const CreateNewButton = styled(Button).attrs({ variant: "primary" })<{ reducedMargin: boolean }>`
	align-self: flex-end;
	margin-top: ${props => (props.reducedMargin ? 6 : 30)}px;
`

const List = styled.ul`
	list-style: none;
`

export default ExamsPage
