import styled from "styled-components"

export const Title = styled.h2`
	font-weight: 600;
	font-size: 24px;
	color: black;
	margin-top: 24px;
	margin-bottom: 10px;
`

export const Description = styled.p`
	color: black;
	margin-bottom: 16px;
`
