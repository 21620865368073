import { Fragment, useId } from "react"
import styled, { css } from "styled-components"

import { primaryColor, primaryLightColor } from "~/utilities/styles"

interface RadioButtonsProps {
	options: {
		value: string
		label: string
		description?: string
	}[]
	value: string
	onChange: (value: string) => void
}

const RadioButtons: React.FC<RadioButtonsProps> = ({ options, value, onChange }) => {
	const groupName = useId()

	return (
		<List>
			{options.map(option => {
				const isChecked = value === option.value

				return (
					<Fragment key={option.value}>
						<Input
							id={option.value}
							name={groupName}
							checked={isChecked}
							onChange={event => onChange(event.target.id)}
						/>
						<Container htmlFor={option.value}>
							<Indicator $isChecked={isChecked} />
							<Info>
								<Label>{option.label}</Label>
								{option.description !== undefined && <Description>{option.description}</Description>}
							</Info>
						</Container>
					</Fragment>
				)
			})}
		</List>
	)
}

const List = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`

const Container = styled.label`
	display: flex;
	gap: 12px;
	cursor: pointer;
`

const Input = styled.input.attrs({ type: "radio" })`
	display: none;
`

const Indicator = styled.div<{ $isChecked: boolean }>`
	position: relative;
	width: 20px;
	height: 20px;
	border: 1px solid #d0d5dd;
	border-radius: 50%;

	${props =>
		props.$isChecked &&
		css`
			border-color: ${primaryColor};
			background-color: ${primaryLightColor};

			&:after {
				position: absolute;
				top: 5px;
				bottom: 5px;
				left: 5px;
				right: 5px;
				content: "";
				background-color: ${primaryColor};
				border-radius: 50%;
			}
		`}
`

const Info = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
`

const Label = styled.p`
	font-weight: 700;
	font-size: 16px;
	color: black;
`

const Description = styled.p`
	font-size: 16px;
	color: #626262;
`

export default RadioButtons
