import { useState } from "react"

import { type Event } from "@forento/shared/models/event"

import { ControlledTabs } from "~/components/Tabs"

import EditEventModal from "./EditEventModal"
import EventsList from "./EventsList"

const Events: React.FC<{ events: Event[]; reloadEvents(): void }> = ({ events, reloadEvents }) => {
	const [editEventId, setEditEventId] = useState<number>()

	const upcomingEvents = events.filter(event => event.endDate > new Date())
	const pastEvents = events.filter(event => !upcomingEvents.some(upcomingEvent => upcomingEvent.id === event.id))

	return (
		<>
			<EditEventModal
				eventId={editEventId ?? null}
				onClose={() => {
					setEditEventId(undefined)
					reloadEvents()
				}}
			/>
			<ControlledTabs
				tabs={[
					{
						title: "Upcoming",
						sticker: upcomingEvents.length.toString(),
						content: (
							<EventsList
								events={upcomingEvents}
								setEditEventId={setEditEventId}
								reloadEvents={reloadEvents}
							/>
						),
					},
					{
						title: "Past",
						sticker: pastEvents.length.toString(),
						content: (
							<EventsList
								events={pastEvents}
								setEditEventId={setEditEventId}
								reloadEvents={reloadEvents}
							/>
						),
					},
				]}
			/>
		</>
	)
}

export default Events
