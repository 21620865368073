import { type FC, useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import Tooltip from "@forento/shared/components/Tooltip"
import { chapterDripIntervals, type ChapterDripInterval } from "@forento/shared/models/course"

import Button from "~/components/Button"
import Checkbox from "~/components/Checkbox"
import Dropdown from "~/components/Dropdown"
import { CheckmarkIcon, CloseIcon, SettingsIcon } from "~/components/Icon"
import InputField, { InputTextArea } from "~/components/InputField"
import InputLabel from "~/components/InputLabel"
import ManageTagsModal from "~/components/ManageTagsModal"
import { usePlatform } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { lightTextColor, primaryColor } from "~/utilities/styles"

import { Description, Title } from "./styles"

interface Props {
	data: CourseInformationData
	setData: (data: CourseInformationData) => void
	tags: { id: number; label: string }[]
	reloadTags: () => void
	thumbnailFilePath: string | null
	onThumbnailChange: (file: File | null) => void
}

export interface CourseInformationData {
	title: string
	shortDescription: string
	chapterDripInterval: ChapterDripInterval | null
	issueCertificates: boolean
	commentsEnabled: boolean
	autoApproveComments: boolean
	tags: number[]
}

const chapterDripOptions: { id: ChapterDripInterval | "none"; title: string }[] = [
	{ id: "none", title: "Release all instantly" },
	{ id: "day", title: "One chapter per day" },
	{ id: "week", title: "One chapter per week" },
	{ id: "month", title: "One chapter per month" },
]

const CourseInformation: FC<Props> = ({
	data,
	setData,
	tags,
	reloadTags,
	thumbnailFilePath: initialThumbnailFilePath,
	onThumbnailChange,
}) => {
	const platform = usePlatform()

	const uploadImageInputRef = useRef<HTMLInputElement>(null)
	const [thumbnailFilePath, setThumbnailFilePath] = useState(initialThumbnailFilePath)
	const [imageFile, setImageFile] = useState<File | null>()
	const [isManageTagsModalOpen, setManageTagsModalOpen] = useState(false)

	useEffect(() => {
		if (imageFile === undefined) return

		onThumbnailChange(imageFile)

		if (imageFile !== null) {
			const reader = new FileReader()

			reader.onload = event => {
				setThumbnailFilePath(event.target?.result?.toString() ?? null)
			}

			reader.readAsDataURL(imageFile)
		} else {
			setThumbnailFilePath(null)
		}
	}, [imageFile, onThumbnailChange])

	return (
		<>
			<ManageTagsModal
				type="course"
				isOpen={isManageTagsModalOpen}
				tags={tags}
				onClose={() => setManageTagsModalOpen(false)}
				onChange={reloadTags}
			/>
			<Title>Thumbnail</Title>
			<Description>
				A picture or social icon, used as thumbnail when you share your course on social medias.
			</Description>
			{thumbnailFilePath !== null && <Image alt="Thumbnail" src={thumbnailFilePath} />}
			<UploadImageInput ref={uploadImageInputRef} onChange={event => setImageFile(event.target.files?.[0])} />
			<ThumbnailActions>
				<Button variant="secondary" onClick={() => uploadImageInputRef.current?.click()}>
					Upload image
				</Button>
				{thumbnailFilePath !== null && (
					<Button variant="primary-danger" onClick={() => setImageFile(null)}>
						Delete image
					</Button>
				)}
			</ThumbnailActions>
			<Title>Basic information</Title>
			<Description>Information on how to discover your course online.</Description>
			<Inputs>
				<InputField label="Title" value={data.title} onChange={title => setData({ ...data, title })} />
				<InputTextArea
					label="Description"
					value={data.shortDescription}
					onChange={shortDescription => setData({ ...data, shortDescription })}
				/>
				<div>
					<InputLabel>Drip chapters</InputLabel>
					<Dropdown
						items={chapterDripOptions}
						selectedItemId={
							platform?.plan.access.chapterDripping ? (data.chapterDripInterval ?? "none") : "none"
						}
						isDisabled={!platform!.plan.access.chapterDripping}
						onChange={value =>
							setData({
								...data,
								chapterDripInterval: chapterDripIntervals.find(x => x === value) ?? null,
							})
						}
					/>
					{!platform!.plan.access.chapterDripping && (
						<InputDescription>
							Chapter dripping is not available on your current plan. You can{" "}
							<Link to={routes.settings.billing.plans()}>browse plans here</Link>.
						</InputDescription>
					)}
				</div>
				<div>
					<Checkbox
						label="Issue certificates"
						isChecked={platform!.plan.access.certificates && data.issueCertificates}
						onChange={issueCertificates => setData({ ...data, issueCertificates })}
						isDisabled={!platform!.plan.access.certificates}
					/>
					{!platform!.plan.access.certificates && (
						<InputDescription>
							Certificates are not available on your current plan. You can{" "}
							<Link to={routes.settings.billing.plans()}>browse plans here</Link>.
						</InputDescription>
					)}
				</div>
				<Checkbox
					label="Enable comments"
					isChecked={data.commentsEnabled}
					onChange={commentsEnabled => setData({ ...data, commentsEnabled })}
				/>
				<Checkbox
					label="Automatically approve comments"
					isChecked={data.autoApproveComments}
					onChange={autoApproveComments => setData({ ...data, autoApproveComments })}
					isDisabled={!data.commentsEnabled}
				/>
			</Inputs>
			<Header>
				<Title>Tags</Title>
				<Tooltip tooltip="Manage tags">
					<TagSettingsButton onClick={() => setManageTagsModalOpen(true)}>
						<SettingsIcon />
					</TagSettingsButton>
				</Tooltip>
			</Header>
			<Description>Tags help students find your course when they search for specific topics.</Description>
			<Tags>
				{tags.map(tag => {
					const isSelected = data.tags.includes(tag.id)
					return (
						<Tag
							key={tag.id}
							$isSelected={isSelected}
							onClick={() =>
								setData({
									...data,
									tags: data.tags.includes(tag.id)
										? data.tags.filter(x => x !== tag.id)
										: [...data.tags, tag.id],
								})
							}
						>
							<TagIcon as={isSelected ? CheckmarkIcon : CloseIcon} /> {tag.label}
						</Tag>
					)
				})}
			</Tags>
		</>
	)
}

const Image = styled.img`
	width: 500px;
	height: ${(500 / 16) * 9}px;
	object-fit: cover;
	border-radius: 8px;
	margin-bottom: 16px;
`

const UploadImageInput = styled.input.attrs({ type: "file" })`
	display: none;
`

const ThumbnailActions = styled.div`
	display: flex;
	gap: 16px;
	margin-bottom: 8px;
`

const Inputs = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 24px;
	margin-bottom: 10px;

	${Title} {
		margin: 0;
	}
`

const InputDescription = styled.p`
	color: black;
	margin-top: 6px;
`

const TagSettingsButton = styled(Button)`
	width: 24px;
	height: 24px;
`

const Tags = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
`

const Tag = styled(Button)<{ $isSelected: boolean }>`
	padding: 8px 16px;
	border-radius: 36px;
	line-height: 1;
	color: white;
	background-color: ${props => (props.$isSelected ? primaryColor : lightTextColor)};
	display: flex;
	align-items: center;
	gap: 8px;
`

const TagIcon = styled.div`
	width: 20px;
	height: 20px;
`

export default CourseInformation
