import styled, { css } from "styled-components"

import Button from "~/components/Button"
import { primaryColor } from "~/utilities/styles"

export type ChartCategory = "sales" | "visitors" | "unique-visitors"

const ChartCategorySelector: React.FC<{
	selectedValue: ChartCategory
	onChange: (newValue: ChartCategory) => void
}> = ({ selectedValue, onChange }) => {
	const options: { id: ChartCategory; label: string }[] = [
		{ id: "sales", label: "Sales" },
		{ id: "visitors", label: "Visitors" },
		{ id: "unique-visitors", label: "Unique visitors" },
	]
	return (
		<Container>
			{options.map(option => (
				<Option key={option.id} isActive={selectedValue === option.id} onClick={() => onChange(option.id)}>
					{option.label}
				</Option>
			))}
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	border-radius: 8px;
`

const Option = styled(Button)<{ isActive: boolean }>`
	font-weight: 600;
	text-decoration: none;
	padding: 10px 14px;
	transition: 0.2s;
	display: flex;
	align-items: center;
	gap: 8px;

	${props =>
		props.isActive &&
		css`
			color: ${primaryColor};
			background-color: white;
			box-shadow:
				0 1px 3px rgba(16, 24, 40, 0.1),
				0 1px 2px rgba(16, 24, 40, 0.06);
			border-radius: 6px;
		`}
`

export default ChartCategorySelector
