import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { type PublicCommunityProfile } from "@forento/shared/models/community"

import Button from "~/components/Button"
import { MessageIcon } from "~/components/Icon"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { primaryColor } from "~/utilities/styles"

const Profile: React.FC<{ profile: PublicCommunityProfile }> = ({ profile }) => {
	const user = useUser()

	return (
		<Container>
			<StyledAvatar profile={profile} />
			<Name>
				{profile.firstName} {profile.lastName}
			</Name>
			{profile.gamification.currentLevel !== null ? (
				<RegisterDate>
					Level {profile.gamification.currentLevel.tier} - {profile.gamification.currentLevel.label}
				</RegisterDate>
			) : (
				<RegisterDate>since {profile.registerDate.community.getFullYear()}</RegisterDate>
			)}
			<Divider />
			<Stats>
				<Stat.Container>
					<Stat.Value>{profile.posts}</Stat.Value>
					<Stat.Label>Posts</Stat.Label>
				</Stat.Container>
				<Stat.Container>
					<Stat.Value>{profile.reactions}</Stat.Value>
					<Stat.Label>Reactions</Stat.Label>
				</Stat.Container>
				<Stat.Container>
					<Stat.Value>{profile.comments}</Stat.Value>
					<Stat.Label>Comments</Stat.Label>
				</Stat.Container>
			</Stats>
			{user.user!.communityProfileId !== profile.id && (
				<Actions>
					<Action onClick={routes.community.message(profile.id)}>
						<ActionIcon as={MessageIcon} />
					</Action>
				</Actions>
			)}
		</Container>
	)
}

const Container = styled.div`
	grid-area: profile;
	width: 300px;
	border: 1.4px solid #e6e6e6;
	border-radius: 8px;
	padding: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

const StyledAvatar = styled(CommunityProfileAvatar).attrs({ size: 100 })`
	margin-bottom: 12px;
`

const Name = styled.p`
	font-weight: 600;
	font-size: 20px;
	margin-bottom: 2px;
`

const RegisterDate = styled.p`
	font-size: 14px;
	color: #828282;
`

const Divider = styled.hr`
	width: 100%;
	border: none;
	border-bottom: 1px solid #dedede;
	margin: 24px 0;
`

const Stats = styled.div`
	width: 100%;
	display: flex;
	gap: 8px;
`

const Stat = {
	Container: styled.div`
		flex: 1 0 0;
		display: flex;
		flex-direction: column;
		align-items: center;
	`,
	Value: styled.p`
		font-weight: 700;
		font-size: 26px;
		margin-bottom: 6px;
	`,
	Label: styled.p`
		font-size: 14px;
	`,
}

const Actions = styled.div`
	display: flex;
	justify-content: center;
	gap: 12px;
	margin-top: 24px;
`

const Action = styled(Button)`
	width: 24px;
	height: 24px;
	box-sizing: content-box;
	color: white;
	background-color: ${primaryColor};
	border-radius: 8px;
	padding: 8px;
`

const ActionIcon = styled.div`
	width: 100%;
	height: 100%;
`

export default Profile
