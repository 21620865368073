import { type FC, useState } from "react"
import styled from "styled-components"

import { type PaymentInformation } from "@forento/shared/models/payment"

import Button from "~/components/Button"
import { OpenExternalIcon } from "~/components/Icon"
import { ControlledTabs } from "~/components/Tabs"
import { primaryColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

import PaymentsTab from "../PaymentsTab"
import Balance from "./Balance"
import PayoutsTab from "./PayoutsTab"

type Props = { paymentInformation: Extract<PaymentInformation, { type: "stripe" }> }
const Stripe: FC<Props> = ({ paymentInformation }) => {
	const [isSubmittingStripeDashboard, setSubmittingStripeDashboard] = useState(false)

	async function handleClickStripeDashboard() {
		setSubmittingStripeDashboard(true)
		try {
			const loginUrl = await trpc.payment.stripe.createDashboardUrl.mutate()
			window.open(loginUrl, "_blank")
		} catch (error) {
			console.error(error)
		} finally {
			setSubmittingStripeDashboard(false)
		}
	}

	return (
		<>
			<Header>
				<Balance balances={paymentInformation.balances} />
			</Header>
			<ControlledTabs
				tabs={[
					{
						title: "Payments",
						content: (
							<TabContent>
								<PaymentsTab payments={paymentInformation.payments} />
							</TabContent>
						),
					},
					{
						title: "Payouts",
						content: (
							<TabContent>
								<PayoutsTab payouts={paymentInformation.payouts} />
							</TabContent>
						),
					},
				]}
				asideComponent={
					<StripeDashboardButton onClick={handleClickStripeDashboard} isLoading={isSubmittingStripeDashboard}>
						{!isSubmittingStripeDashboard && <OpenExternalIcon />}
						Stripe dashboard
					</StripeDashboardButton>
				}
			/>
		</>
	)
}

export default Stripe

const Header = styled.div`
	display: flex;
	gap: 32px;
	justify-content: flex-end;
	margin-bottom: 14px;
`

const TabContent = styled.div`
	margin-top: 24px;
`

const StripeDashboardButton = styled(Button)`
	font-weight: 600;
	font-size: 14px;
	color: ${primaryColor};
	display: flex;
	align-items: center;
	gap: 8px;
	margin-right: 16px;

	> svg {
		width: 18px;
		height: 18px;
	}
`
