import styled from "styled-components"

import Button from "~/components/Button"
import { useUser } from "~/contexts/UserContext"

const DomainCard: React.FC = () => {
	const platform = useUser().user!.platform!

	return (
		<Container>
			<Header>
				<Label>Domain</Label>
			</Header>
			<Content>
				{platform.domain.landingPage !== null && (
					<>
						<Title>Website</Title>
						<Button newTab onClick={`//${platform.domain.landingPage}`}>
							{platform.domain.landingPage}
						</Button>
					</>
				)}
				<Title>Platform</Title>
				<Button newTab onClick={`//${platform.domain.platform}`}>
					{platform.domain.platform}
				</Button>
			</Content>
		</Container>
	)
}

const Container = styled.div`
	flex: 1 1 400px;
	background-color: #f4f4f4;
	border-radius: 12px;
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 16px;
`

const Label = styled.p`
	font-weight: 500;
	font-size: 16px;
`

export default DomainCard
