import { useState } from "react"
import styled from "styled-components"

import Form from "@forento/shared/components/Form"

import { SubmitButton } from "~/components/Button"
import GoogleSigninButton from "~/components/GoogleSigninButton"
import Layout, { PageBreadcrumb } from "~/components/Layout"
import { useAlert } from "~/contexts/AlertContext"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

import ChangePassword from "./ChangePassword"
import ProfileAvatar from "./ProfileAvatar"
import SetPassword from "./SetPassword"
import { SectionTitle, Section, StyledInputField } from "./styles"

const AccountSettingsPage: React.FC = () => {
	const user = useUser()
	const alert = useAlert()

	const [firstName, setFirstName] = useState(user.user!.firstName)
	const [lastName, setLastName] = useState(user.user!.lastName)

	const [isSubmittingBasicDetails, setSubmittingBasicDetails] = useState(false)

	const handleSaveBasicDetails = async () => {
		setSubmittingBasicDetails(true)
		const data = {
			firstName: firstName.trim(),
			lastName: lastName.trim(),
		}
		if (Object.values(data).some(x => x.length === 0)) {
			alert.show("Error", "Your name cannot be empty.")
			return
		}

		try {
			await trpc.user.update.mutate(data)
			alert.show("Account saved", "Your account settings are successfully saved.")
		} finally {
			setSubmittingBasicDetails(false)
		}

		await user.reload()
	}

	return (
		<Layout>
			<PageBreadcrumb title="Account" path={[{ title: "Settings", link: routes.settings.index() }]} />
			<Sections>
				<AccountDetails>
					{user.user!.communityProfileId !== null && <ProfileAvatar />}
					<div>
						<AccountName>
							{user.user!.firstName} {user.user!.lastName}
						</AccountName>
						<AccountEmail>{user.user!.email}</AccountEmail>
					</div>
				</AccountDetails>

				<Section as={Form} onSubmit={handleSaveBasicDetails}>
					<InputGroup>
						<StyledInputField
							label="Your personal account first name"
							value={firstName}
							onChange={setFirstName}
							autoComplete="given-name"
						/>
						<StyledInputField
							label="Your personal account last name"
							value={lastName}
							onChange={setLastName}
							autoComplete="family-name"
						/>
					</InputGroup>
					<SubmitButton variant="primary" isLoading={isSubmittingBasicDetails}>
						Save
					</SubmitButton>
				</Section>

				{user.user!.hasPassword ? <ChangePassword /> : <SetPassword />}
				<Section>
					<SectionTitle>Social sign in</SectionTitle>
					{user.user!.hasGoogleSignin ? (
						<p>Google sign in is connected.</p>
					) : (
						<GoogleSigninButton text="signin" />
					)}
				</Section>
			</Sections>
		</Layout>
	)
}

const Sections = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`

const AccountDetails = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
`

const AccountName = styled.h2`
	font-weight: 600;
	font-size: 18px;
`

const AccountEmail = styled.p`
	font-size: 16px;
	color: #667085;
`

const InputGroup = styled.div`
	width: 100%;
	display: flex;
	gap: 16px;
`

export default AccountSettingsPage
