import { type ReactNode, useState } from "react"
import snarkdown from "snarkdown"

import { addonTypes, type AddonType } from "@forento/shared/models/payment"
import { type AppSumoPlatformPlan, type VieDeDinguePlatformPlan } from "@forento/shared/models/platform"

import Button from "~/components/Button"
import { DefaultModal, ModalButtons, ModalTexts, ModalTitle } from "~/components/Modal"
import PaymentIntervalSelector from "~/components/PaymentIntervalSelector"
import { useUser } from "~/contexts/UserContext"
import CancelSubscriptionModal from "~/pages/settings/BillingSettingsPage/Plans/CancelSubscriptionModal"
import Addon from "~/pages/settings/BillingSettingsPage/Plans/useLtdAddon/Addon"
import { swr } from "~/utilities/trpc"

import Plan, { PlanQuestion, PlanQuestions, PlanTitle } from "../Plan"

const questions: { question: string; answer: string }[] = [
	{
		question: "What's included in the addon?",
		answer: `
With this addon, you can accept payments externally without relying on PayPal or Stripe. It provides functionality to assign and revoke product access to any of your platform members, either manually or automatically through Zapier.

Additionally, this addon eliminates all sales commission fees on your platform transactions.

Whether you want to use a local payment gateway, sell via invoices, or scale your business, this addon is ideal for you.

- Assign and revoke product access for any platform member
- Zapier integration for connecting to external payment providers
- 0% sales commission fee for all sales, including PayPal and Stripe transactions made on your platform
		`,
	},
	{
		question: "Who is this for?",
		answer: `
This upgrade is perfect for anyone who wants to:

1. Use a local or external payment gateway instead of PayPal or Stripe
1. Reduce transaction fees associated with their business

In addition to removing sales commission fees, this upgrade allows you to accept external payments and assign access to members either manually or automatically.
		`,
	},
	{
		question: "Why are you selling an addon?",
		answer: `
As part of your lifetime deal, you pay a sales commission fee ranging from 2% to 10% on any platform sales. This fee applies to all sales made through PayPal or Stripe, for any product or membership.

Previously, the only way to remove the sales commission was to get a full-price subscription, which costs $49/month or $468/year for Start customers.

After numerous requests for a commission-free plan for lifetime deal customers, we've introduced the "No Commissions Addon." Now, creators can remove the sales commission fee for as little as $19/month (for Start & Basic 3), without needing a full-price subscription.
		`,
	},
	{
		question: "I already have a lifetime deal, do I need this upgrade?",
		answer: `
You already have a lifetime deal for Forento, giving you access to a range of powerful features to help you succeed. This addon is not required to use Forento.

This addon is for those who:

1. Want to use a local payment gateway and manually or automatically assign product access
1. Want to scale their business and limit transaction fees

Note: We have never, and will never, remove features from your account to sell them as addons or upgrades.
		`,
	},
	{
		question: "How does the Zapier integration work?",
		answer: "Zapier can be used to easily create workflows and automations across different apps. For example, if a user joins a Forento membership, you may want to add them to an external service as well. To integrate Forento with other applications, install our Zapier app, which can be found on the Zapier settings page when you sign in to your Forento account.",
	},
]

type PlatformPlan = AppSumoPlatformPlan | VieDeDinguePlatformPlan
type Result =
	| { status: "error" | "loading" }
	| { status: "success"; paymentIntervalComponent: ReactNode; planComponent: ReactNode }
export default function useLtdAddon({ platformPlan }: { platformPlan: PlatformPlan }): Result {
	const user = useUser()

	const [isMonthlySelected, setMonthlySelected] = useState(
		!platformPlan.addon || platformPlan.addon.paymentInterval === "month",
	)
	const [isCancelModalOpen, setCancelModalOpen] = useState(false)
	const [submittingAddon, setSubmittingAddon] = useState<AddonType>()
	const [faqModal, setFaqModal] = useState<{ isOpen: boolean; questionIndex: number }>({
		isOpen: false,
		questionIndex: 0,
	})

	const { data: addonDetails, error } = swr.payment.creator.getAddonDetails.useSWR()

	if (error) return { status: "error" }
	if (!addonDetails) return { status: "loading" }
	return {
		status: "success",
		paymentIntervalComponent: (
			<PaymentIntervalSelector
				annualDiscount={
					1 -
					addonDetails.noCommission.prices.year.amount / 12 / addonDetails.noCommission.prices.month.amount
				}
				isMonthlySelected={isMonthlySelected}
				setMonthlySelected={setMonthlySelected}
			/>
		),
		planComponent: (
			<>
				<DefaultModal isOpen={faqModal.isOpen}>
					<ModalTitle>{questions[faqModal.questionIndex].question}</ModalTitle>
					<ModalTexts
						dangerouslySetInnerHTML={{
							__html: snarkdownWithParagraphs(questions[faqModal.questionIndex].answer.trim()),
						}}
					/>
					<ModalButtons>
						<Button
							variant="primary"
							onClick={() => setFaqModal(current => ({ ...current, isOpen: false }))}
						>
							Close
						</Button>
					</ModalButtons>
				</DefaultModal>
				<CancelSubscriptionModal
					type="addon"
					isOpen={isCancelModalOpen}
					onClose={() => setCancelModalOpen(false)}
					onSubmitted={async () => {
						await user.reload()
						setCancelModalOpen(false)
					}}
				/>
				{addonTypes.map(type => (
					<Addon
						key={type}
						type={type}
						details={addonDetails[type]}
						platformPlan={platformPlan}
						isMonthlySelected={isMonthlySelected}
						setSubmitting={value => setSubmittingAddon(value ? type : undefined)}
						isDisabled={submittingAddon !== undefined}
						isSubmitting={submittingAddon === type}
						onCancel={() => setCancelModalOpen(true)}
					/>
				))}
				<Plan>
					<PlanTitle>Why an addon?</PlanTitle>
					<PlanQuestions>
						{questions.map((item, index) => (
							<PlanQuestion
								key={index}
								onClick={() => setFaqModal({ isOpen: true, questionIndex: index })}
							>
								{item.question}
							</PlanQuestion>
						))}
					</PlanQuestions>
				</Plan>
			</>
		),
	}
}

function snarkdownWithParagraphs(markdown: string): string {
	const htmls = markdown
		.split(/(?:\r?\n){2,}/)
		.map(line =>
			[" ", "\t", "#", "-", "1", "*"].some(ch => line.startsWith(ch))
				? snarkdown(line)
				: `<p>${snarkdown(line)}</p>`,
		)
	return htmls.join("\n\n")
}
