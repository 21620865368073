import type React from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import {
	BillingIcon,
	CustomizeIcon,
	EmailIcon,
	IntegrationIcon,
	NotificationIcon,
	TeamIcon,
	UserIcon,
} from "~/components/Icon"
import Layout, { PageTitle } from "~/components/Layout"
import { usePlatform } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { primaryColor } from "~/utilities/styles"

const SettingsPage: React.FC = () => {
	const platform = usePlatform()!

	return (
		<Layout>
			<PageTitle>Settings</PageTitle>
			<SettingsItems>
				<SettingsItem onClick={routes.settings.team()}>
					<SettingsItemIconContainer>
						<SettingsItemIcon as={TeamIcon} />
					</SettingsItemIconContainer>
					<SettingsItemDetails>
						<SettingsItemTitle>Team Members</SettingsItemTitle>
						<SettingsItemSubtitle>
							Manage your platform team members or add more people to your team
						</SettingsItemSubtitle>
					</SettingsItemDetails>
				</SettingsItem>

				<SettingsItem onClick={routes.settings.platform()}>
					<SettingsItemIconContainer>
						<SettingsItemIcon as={CustomizeIcon} />
					</SettingsItemIconContainer>
					<SettingsItemDetails>
						<SettingsItemTitle>Customize Platform</SettingsItemTitle>
						<SettingsItemSubtitle>Change the appearence and make it yours</SettingsItemSubtitle>
					</SettingsItemDetails>
				</SettingsItem>

				<SettingsItem onClick={routes.settings.studentEmail.index()}>
					<SettingsItemIconContainer>
						<SettingsItemIcon as={EmailIcon} />
					</SettingsItemIconContainer>
					<SettingsItemDetails>
						<SettingsItemTitle>Student Emails</SettingsItemTitle>
						<SettingsItemSubtitle>Customize the emails your students receive</SettingsItemSubtitle>
					</SettingsItemDetails>
				</SettingsItem>

				<SettingsItem onClick={routes.settings.domain.index()}>
					<SettingsItemIconContainer>
						<SettingsItemIcon as={DomainIcon} />
					</SettingsItemIconContainer>
					<SettingsItemDetails>
						<SettingsItemTitle>Custom Domain</SettingsItemTitle>
						<SettingsItemSubtitle>Set a custom domain for your platform</SettingsItemSubtitle>
					</SettingsItemDetails>
				</SettingsItem>

				<SettingsItem onClick={routes.settings.notification()}>
					<SettingsItemIconContainer>
						<SettingsItemIcon as={NotificationIcon} />
					</SettingsItemIconContainer>
					<SettingsItemDetails>
						<SettingsItemTitle>Notifications</SettingsItemTitle>
						<SettingsItemSubtitle>
							Configure which notifications you want to receive by e-mail.
						</SettingsItemSubtitle>
					</SettingsItemDetails>
				</SettingsItem>

				{platform.plan.access.zapier && (
					<SettingsItem onClick={routes.settings.zapier()}>
						<SettingsItemIconContainer>
							<SettingsItemIcon as={IntegrationIcon} />
						</SettingsItemIconContainer>
						<SettingsItemDetails>
							<SettingsItemTitle>Zapier</SettingsItemTitle>
							<SettingsItemSubtitle>Connect your platform with Zapier</SettingsItemSubtitle>
						</SettingsItemDetails>
					</SettingsItem>
				)}

				<SettingsItem onClick={routes.settings.billing.plans()}>
					<SettingsItemIconContainer>
						<SettingsItemIcon as={BillingIcon} />
					</SettingsItemIconContainer>
					<SettingsItemDetails>
						<SettingsItemTitle>Billing &amp; Plans</SettingsItemTitle>
						<SettingsItemSubtitle>Upgrade your plan or change your billing settings</SettingsItemSubtitle>
					</SettingsItemDetails>
				</SettingsItem>

				<SettingsItem onClick={routes.settings.account()}>
					<SettingsItemIconContainer>
						<SettingsItemIcon as={UserIcon} />
					</SettingsItemIconContainer>
					<SettingsItemDetails>
						<SettingsItemTitle>Account</SettingsItemTitle>
						<SettingsItemSubtitle>Change your name or password</SettingsItemSubtitle>
					</SettingsItemDetails>
				</SettingsItem>
			</SettingsItems>
		</Layout>
	)
}

const SettingsItems = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const SettingsItem = styled(Button)`
	background-color: #fbfbf9;
	border: 1px solid #eaeaea;
	border-radius: 8px;
	padding: 22px;
	display: flex;
	gap: 16px;
`

const SettingsItemIconContainer = styled.div`
	width: 56px;
	height: 56px;
	color: white;
	background-color: ${primaryColor};
	padding: 12px;
	border-radius: 8px;
`

const SettingsItemIcon = styled.div`
	width: 100%;
	height: 100%;
	color: white;
`

const SettingsItemDetails = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const SettingsItemTitle = styled.h2`
	font-weight: 600;
	font-size: 20px;
	text-align: left;
`

const SettingsItemSubtitle = styled.p`
	font-size: 16px;
	text-align: left;
`

const DomainIcon: React.FC<{ className?: string }> = ({ className }) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className={className}>
		<path
			fill="currentColor"
			d="M28.2 36Q27.65 36 27.35 35.55Q27.05 35.1 27.25 34.6L36.1 12.6Q36.2 12.3 36.475 12.15Q36.75 12 37.05 12H44Q44.55 12 44.85 12.45Q45.15 12.9 44.9 13.4L36.05 35.4Q35.95 35.7 35.675 35.85Q35.4 36 35.1 36ZM14.75 36Q14.2 36 13.9 35.55Q13.6 35.1 13.85 34.6L22.7 12.6Q22.8 12.3 23.075 12.15Q23.35 12 23.65 12H30.6Q31.15 12 31.425 12.45Q31.7 12.9 31.5 13.4L22.6 35.4Q22.5 35.7 22.25 35.85Q22 36 21.7 36ZM8.45 25Q7.9 25 7.6 24.55Q7.3 24.1 7.5 23.6L9.55 18.6Q9.65 18.3 9.9 18.15Q10.15 18 10.45 18H15.3Q15.85 18 16.175 18.45Q16.5 18.9 16.25 19.4L14.2 24.4Q14.1 24.7 13.85 24.85Q13.6 25 13.3 25ZM4 36Q3.45 36 3.15 35.55Q2.85 35.1 3.1 34.6L5.1 29.6Q5.2 29.3 5.45 29.15Q5.7 29 6 29H10.9Q11.45 29 11.725 29.45Q12 29.9 11.8 30.4L9.8 35.4Q9.7 35.7 9.425 35.85Q9.15 36 8.85 36Z"
		/>
	</svg>
)

export default SettingsPage
