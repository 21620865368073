import styled from "styled-components"

import Button from "~/components/Button"
import { CheckIcon } from "~/components/Icon"

const Plan = styled.div`
	flex: 0 1 320px;
	display: flex;
	flex-direction: column;
	background-color: white;
	border: 1px solid #e4e7ec;
	box-shadow:
		0 12px 16px -4px rgba(16, 24, 40, 0.1),
		0 4px 6px -2px rgba(16, 24, 40, 0.05);
	border-radius: 12px;
	padding: 40px 32px 20px 32px;
`
export default Plan

export const PlanTitle = styled.h2`
	font-weight: 700;
	font-size: 24px;
	text-align: center;
	margin-bottom: 16px;
`

export const PlanPrice = styled.p`
	font-weight: 600;
	font-size: 38px;
	line-height: 1;
	text-align: center;
	margin-bottom: 8px;
`

export const PlanInterval = styled.span`
	font-size: 18px;
`

export const PlanBillingInterval = styled.p`
	font-size: 12px;
	min-height: 12px;
	line-height: 1;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 8px;
`

export const PlanDescription = styled.p`
	font-weight: 500;
	font-size: 14px;
	text-align: center;
`

export const PlanUsps = styled.div`
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export const PlanUspContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
`

export const PlanUspIcon = styled(CheckIcon).attrs({ light: true })`
	flex: 0 0 auto;
	width: 24px;
	height: 24px;
`

export const PlanUspText = styled.p`
	font-size: 14px;
`

export const PlanDivider = styled.hr`
	width: 100%;
	border: none;
	border-bottom: 1px solid #dedede;
	margin: 20px 0;
`

export const PlanPadding = styled.div`
	flex: 0 0 20px;
`

export const PlanSpacer = styled.div`
	flex: 1 0 0;
`

export const PlanQuestions = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 16px;
	margin-top: ${(54 - 38) / 2}px;
`

export const PlanQuestion = styled(Button).attrs({ variant: "link" })`
	text-align: center;
`
