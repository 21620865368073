import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { type CommunityPostComment } from "@forento/shared/models/community"
import { toDateTimeString } from "@forento/shared/utilities/date"

import { toRelativeDateString } from "~/utilities/date"

const Comment: React.FC<{ comment: CommunityPostComment }> = ({ comment }) => {
	return (
		<Container key={comment.id}>
			<CommunityProfileAvatar profile={comment.profile} size={32} backgroundColor="white" />
			<Content>
				{comment.text}
				<Author>
					{comment.profile.firstName} {comment.profile.lastName}
					<Date title={toDateTimeString(comment.createDate)}>{toRelativeDateString(comment.createDate)}</Date>
				</Author>
			</Content>
		</Container>
	)
}

const Container = styled.div`
	display: flex;
	gap: 6px;
`

const Content = styled.div`
	width: 100%;
	background-color: white;
	border-radius: 8px;
	padding: 6px 10px;
`

const Author = styled.div`
	display: flex;
	gap: 8px;
	font-size: 12px;
`

const Date = styled.div`
	color: #949494;
	font-size: 12px;
`

export default Comment
