import { createContext, useContext, useLayoutEffect } from "react"

export type GoogleSignInContextValue = { isInitialized: boolean; disable(): void }

const GoogleSignInContext = createContext<GoogleSignInContextValue>({ isInitialized: false, disable() {} })

export default GoogleSignInContext

export function useGoogleSignIn() {
	return useContext(GoogleSignInContext)
}

export function useDisableGoogleSignIn() {
	const googleSignIn = useGoogleSignIn()

	useLayoutEffect(() => {
		googleSignIn.disable()
	}, [googleSignIn])
}
