import { type FC, useState } from "react"
import styled from "styled-components"

import CommunityProfileAvatar from "@forento/shared/components/CommunityProfileAvatar"
import { type CommunityPost } from "@forento/shared/models/community"
import { toDateTimeString } from "@forento/shared/utilities/date"
import { toCountFormat } from "@forento/shared/utilities/number"

import Button from "~/components/Button"
import { CommentIcon, DeleteIcon, LikeIcon, MoreOptionsIcon } from "~/components/Icon"
import PostReactions from "~/components/PostReactions"
import { useAlert } from "~/contexts/AlertContext"
import useDropdownMenu from "~/hooks/useDropdownMenu"
import { toRelativeDateString } from "~/utilities/date"
import { lightTextColor } from "~/utilities/styles"
import trpc from "~/utilities/trpc"

import Attachment from "./Attachment"
import PollOption from "./PollOption"

type Props = { post: CommunityPost; isSelected: boolean; onSelect(): void; onChange(): void }
const Post: FC<Props> = ({ post, isSelected, onSelect, onChange }) => {
	const alert = useAlert()

	const [isReactionsModalOpen, setReactionsModalOpen] = useState(false)

	const dropdownMenu = useDropdownMenu([{ label: "Delete", icon: DeleteIcon, isDanger: true, onClick: handleDelete }])

	async function handleVote(pollOptionId: number | null) {
		await trpc.community.setPostVote.mutate({
			postId: post.id,
			pollOptionId:
				pollOptionId !== null && !post.pollOptions.find(x => x.id === pollOptionId)?.isSelected
					? pollOptionId
					: null,
		})
		onChange()
	}

	async function handleLike() {
		if (!post.hasUserReacted) {
			await trpc.community.createPostReaction.mutate(post.id)
		} else {
			await trpc.community.deletePostReaction.mutate(post.id)
		}
		onChange()
	}

	async function handleDelete() {
		const dialog = await alert.confirm(
			"Delete post",
			"Are you sure you want to delete this post? This action cannot be undone.",
		)
		if (!dialog.result) return
		await trpc.community.deletePost.mutate(post.id)
		dialog.close()
		onChange()
	}

	return (
		<Container $isSelected={isSelected}>
			{dropdownMenu.component}
			<PostReactions
				postId={isReactionsModalOpen ? post.id : null}
				onClose={() => setReactionsModalOpen(false)}
			/>
			<Header>
				<div>
					{post.category !== null && <Category>{post.category.label}</Category>}
					<Button onClick={onSelect}>
						<Title>{post.title}</Title>
					</Button>
				</div>
				<ActionsButton {...dropdownMenu.button}>
					<MoreOptionsIcon />
				</ActionsButton>
			</Header>
			<Content dangerouslySetInnerHTML={{ __html: post.htmlContent }} />
			{post.pollOptions.length > 0 && (
				<PollOptions>
					{post.pollOptions.map((option, index) => (
						<PollOption
							key={index}
							option={option}
							onChange={isSelected => (isSelected ? handleVote(option.id) : handleVote(null))}
						/>
					))}
				</PollOptions>
			)}
			{post.attachments.length > 0 && (
				<Attachments>
					{post.attachments.map(attachment => (
						<Attachment key={attachment.id} attachment={attachment} />
					))}
				</Attachments>
			)}
			<Author>
				<CommunityProfileAvatar profile={post.profile} size={38} />
				{post.profile.firstName} {post.profile.lastName}
				<Date title={toDateTimeString(post.createDate)}>{toRelativeDateString(post.createDate)}</Date>
			</Author>
			<Footer>
				<FooterItem>
					<Button onClick={() => setReactionsModalOpen(true)}>{toCountFormat(post.reactions)}</Button>
					<Button onClick={handleLike}>
						<FooterIcon as={LikeIcon} isFilled={post.hasUserReacted} />
					</Button>
				</FooterItem>
				<FooterItem as={Button} onClick={onSelect}>
					{toCountFormat(post.comments)}
					<FooterIcon as={CommentIcon} />
				</FooterItem>
			</Footer>
		</Container>
	)
}

const Container = styled.div<{ $isSelected: boolean }>`
	width: 100%;
	border: 1.4px solid ${props => (props.$isSelected ? "black" : "#e6e6e6")};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
`

const Header = styled.div`
	display: flex;
	gap: 24px;
	justify-content: space-between;
	margin: 24px 24px 12px;
`

const ActionsButton = styled(Button)`
	width: 24px;
	height: 24px;
`

const Category = styled.p`
	font-size: 14px;
	color: ${lightTextColor};
`

const Title = styled.h2`
	font-weight: 700;
	font-size: 20px;
`

const Content = styled.div`
	margin: 0 24px 12px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const PollOptions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin: 0 24px 12px;
`

const Attachments = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	margin: 0 24px 12px;
`

const Author = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
	margin: 0 24px 20px;
`

const Date = styled.div`
	color: #949494;
`

const Footer = styled.div`
	display: flex;
	gap: 24px;
	padding: 16px 24px;
	border-top: 1px solid #dedede;
`

const FooterItem = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
`

const FooterIcon = styled.div`
	width: 20px;
	height: 20px;
`

export default Post
