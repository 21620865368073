import { type FC, type ReactNode } from "react"
import { useMemo, useState } from "react"
import snarkdown from "snarkdown"
import randomUUID from "uuid-random"

import Button from "~/components/Button"
import InputField from "~/components/InputField"
import { DefaultModal, ModalButtons, ModalInputs, ModalText, ModalTitle } from "~/components/Modal"

import AlertContext, {
	type AlertContextValue,
	type ConfirmResult,
	type InputResult,
	type MultipleInputsResult,
} from "."

type Modal = {
	id: string
	closeDate?: Date
} & (
	| {
			type: "alert"
			onResolve?: () => void
			title: string
			message: string
			isSubmitting?: never
	  }
	| {
			type: "confirm"
			onResolve: (params: ConfirmResult) => void
			title: string
			message: string
			isSubmitting: boolean
	  }
	| {
			type: "input"
			onResolve: (params: InputResult) => void
			title: string
			message: string
			input: { label: string; value: string }
			isSubmitting: boolean
	  }
	| {
			type: "multiple-inputs"
			onResolve: (params: MultipleInputsResult) => void
			title: string
			message: string
			inputs: { label: string; value: string }[]
			isSubmitting: boolean
	  }
	| {
			type: "custom"
			onResolve: () => void
			content: (onClose: () => void) => ReactNode
	  }
)

const generateModalId = randomUUID

const AlertContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [modals, setModals] = useState<Modal[]>([])

	const closeModalById = (modalId: string) => {
		const modal = modals.find(x => x.id === modalId)
		if (modal === undefined) return

		setModals(current => [{ ...modal, closeDate: new Date() }, ...current.filter(x => x.id !== modalId)])

		setTimeout(() => {
			setModals(current => current.filter(x => x.id !== modalId))
		}, 10_000)
	}

	const setSubmittingByModalById = (modalId: string, value: boolean) => {
		const modal = modals.find(x => x.id === modalId)
		if (modal === undefined || modal.type === "alert") return

		setModals(current => [{ ...modal, isSubmitting: value }, ...current.filter(x => x.id !== modalId)])
	}

	const value = useMemo<AlertContextValue>(
		() => ({
			show: (title: string, message: string) =>
				new Promise<void>(resolve => {
					setModals(current => [
						{ type: "alert", onResolve: resolve, id: generateModalId(), title, message },
						...current,
					])
				}),

			confirm: (title: string, message: string) =>
				new Promise<ConfirmResult>(resolve => {
					setModals(current => [
						{
							type: "confirm",
							onResolve: resolve,
							id: generateModalId(),
							title,
							message,
							isSubmitting: false,
						},
						...current,
					])
				}),

			input: (title: string, message: string, label: string, defaultValue?: string) =>
				new Promise<InputResult>(resolve => {
					setModals(current => [
						{
							type: "input",
							onResolve: resolve,
							id: generateModalId(),
							title,
							message,
							input: { label, value: defaultValue ?? "" },
							isSubmitting: false,
						},
						...current,
					])
				}),

			multipleInputs: (title: string, message: string, inputs: { label: string; defaultValue?: string }[]) =>
				new Promise<MultipleInputsResult>(resolve => {
					setModals(current => [
						{
							type: "multiple-inputs",
							onResolve: resolve,
							id: generateModalId(),
							title,
							message,
							inputs: inputs.map(x => ({ label: x.label, value: x.defaultValue ?? "" })),
							isSubmitting: false,
						},
						...current,
					])
				}),

			custom: (content: (onClose: () => void) => ReactNode) =>
				new Promise<void>(resolve =>
					setModals(current => [
						{ type: "custom", onResolve: resolve, id: generateModalId(), content },
						...current,
					]),
				),
		}),
		[],
	)

	const modalToShow = modals.find(x => x.closeDate === undefined)
	const modalsToRender = [
		...modals.filter(x => x.closeDate !== undefined),
		...(modalToShow !== undefined ? [modalToShow] : []),
	]

	return (
		<AlertContext.Provider value={value}>
			{children}
			{modalsToRender.map(modal => (
				<DefaultModal key={modal.id} isOpen={modal.closeDate === undefined}>
					{modal.type === "custom" ? (
						modal.content(() => {
							closeModalById(modal.id)
							modal.onResolve?.()
						})
					) : (
						<>
							<ModalTitle>{modal.title}</ModalTitle>
							<ModalText dangerouslySetInnerHTML={{ __html: snarkdown(modal.message) }} />
							{modal.type === "alert" ? (
								<ModalButtons>
									<Button
										variant="primary"
										onClick={() => {
											closeModalById(modal.id)
											modal.onResolve?.()
										}}
									>
										Close
									</Button>
								</ModalButtons>
							) : modal.type === "confirm" ? (
								<ModalButtons>
									<Button
										variant="secondary"
										onClick={() => {
											modal.onResolve({ result: false })
											closeModalById(modal.id)
										}}
										isDisabled={modal.isSubmitting}
									>
										No
									</Button>
									<Button
										variant="primary-danger"
										onClick={async () => {
											setSubmittingByModalById(modal.id, true)
											modal.onResolve({ result: true, close: () => closeModalById(modal.id) })
										}}
										isLoading={modal.isSubmitting}
									>
										Yes
									</Button>
								</ModalButtons>
							) : modal.type === "input" ? (
								<>
									<ModalInputs>
										<InputField
											label={modal.input.label}
											value={modal.input.value}
											onChange={value => {
												setModals(current => [
													{ ...modal, input: { ...modal.input, value } },
													...current.filter(x => x.id !== modal.id),
												])
											}}
										/>
									</ModalInputs>
									<ModalButtons>
										<Button
											variant="secondary"
											onClick={() => {
												modal.onResolve({ result: null })
												closeModalById(modal.id)
											}}
											isDisabled={modal.isSubmitting}
										>
											Cancel
										</Button>
										<Button
											variant="primary"
											onClick={async () => {
												if (modal.input.value.trim().length === 0) return
												setSubmittingByModalById(modal.id, true)
												modal.onResolve({
													result: modal.input.value,
													close: () => closeModalById(modal.id),
												})
											}}
											isLoading={modal.isSubmitting}
										>
											Save
										</Button>
									</ModalButtons>
								</>
							) : modal.type === "multiple-inputs" ? (
								<>
									<ModalInputs>
										{modal.inputs.map((input, index) => (
											<InputField
												key={index}
												label={input.label}
												value={input.value}
												onChange={value => {
													setModals(current => [
														{
															...modal,
															inputs: modal.inputs.map((input, editedIndex) =>
																editedIndex === index ? { ...input, value } : input,
															),
														},
														...current.filter(x => x.id !== modal.id),
													])
												}}
											/>
										))}
									</ModalInputs>
									<ModalButtons>
										<Button
											variant="secondary"
											onClick={() => {
												modal.onResolve({ result: null })
												closeModalById(modal.id)
											}}
											isDisabled={modal.isSubmitting}
										>
											Cancel
										</Button>
										<Button
											variant="primary"
											onClick={async () => {
												if (modal.inputs.some(x => x.value.trim().length === 0)) return
												setSubmittingByModalById(modal.id, true)
												modal.onResolve({
													result: modal.inputs.map(x => x.value.trim()),
													close: () => closeModalById(modal.id),
												})
											}}
											isLoading={modal.isSubmitting}
										>
											Save
										</Button>
									</ModalButtons>
								</>
							) : null}
						</>
					)}
				</DefaultModal>
			))}
		</AlertContext.Provider>
	)
}

export default AlertContextProvider
