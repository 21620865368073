import { type ComponentType } from "react"
import styled from "styled-components"

const StatCard: React.FC<{
	icon: ComponentType
	value: number
	unit?: string
	label: string
	color: string
	gridArea: string
}> = ({ icon, value, unit, label, color, gridArea }) => (
	<Container $color={color} $gridArea={gridArea}>
		<Row>
			<Icon as={icon} />
			<Label>{label}</Label>
		</Row>
		<Value>
			{value}
			{unit !== undefined ? ` ${unit}` : ""}
		</Value>
	</Container>
)

const Container = styled.div<{ $gridArea: string; $color: string }>`
	grid-area: ${props => props.$gridArea};
	background-color: ${props => props.$color};
	padding: 32px;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`

const Row = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`

const Icon = styled.div`
	width: 32px;
	height: 32px;
`

const Label = styled.h2`
	font-size: 16px;
	color: black;
`

const Value = styled.div`
	font-weight: 700;
	font-size: 30px;
	color: black;
`

export default StatCard
