import { Component, type ErrorInfo, type ReactNode } from "react"
import styled from "styled-components"

export default class ErrorBoundary extends Component<{ children: ReactNode }, { hasError: boolean }> {
	state = { hasError: false }

	static getDerivedStateFromError() {
		return { hasError: true }
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error(error, errorInfo)
	}

	render() {
		if (this.state.hasError) {
			return (
				<Container>
					<Title>Something went wrong.</Title>
					<Description>
						We're sorry this happened. A report of this error has been sent to our development team.
						<br />
						If you are experiencing problems, you are welcome to contact us in the live chat.
					</Description>
				</Container>
			)
		}

		return this.props.children
	}
}

const Container = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
`

const Title = styled.h1`
	max-width: 1000px;
	font-weight: 700;
	font-size: 36px;
	text-align: center;
	margin: 0 32px;
`

const Description = styled.p`
	max-width: 1000px;
	font-size: 24px;
	line-height: 1.5;
	text-align: center;
	margin: 0 32px;
`
