import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import styled from "styled-components"

import { parseNumber } from "@forento/shared/utilities/number"

import Button from "~/components/Button"
import LoadingIndicator from "~/components/LoadingIndicator"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import trpc from "~/utilities/trpc"

import Layout, { Content, Logo, Text, Title } from "./Layout"

const VerifyEmailPage: React.FC = () => {
	const user = useUser()
	const params = new URLSearchParams(useLocation().search)
	const navigate = useNavigate()

	const userId = parseNumber(params.get("user") ?? "")
	const token = params.get("token")

	const [state, setState] = useState<"loading" | "failed" | "success">("loading")

	useEffect(() => {
		async function verify() {
			if (userId === null || token === null) {
				navigate(routes.main.index(), { replace: true })
				return
			}
			const { status } = await trpc.user.verifyEmail.mutate({ userId, token })
			setState(status === "success" ? "success" : "failed")
			await user.reload()
		}
		verify()
	}, [navigate, token, user, userId])

	return (
		<Layout>
			<Logo />
			<Content>
				<Title>Check your email</Title>
				{state === "loading" ? (
					<>
						<StyledLoadingIndicator />
						<Text>Verifying email address...</Text>
					</>
				) : state === "failed" ? (
					<Text>Failed to verify email address. The link may be expired. Please try again.</Text>
				) : (
					<>
						<Text>Your email address is now verified!</Text>
						<Button variant="primary" onClick={routes.main.index()}>
							Go to dashboard
						</Button>
					</>
				)}
			</Content>
		</Layout>
	)
}

const StyledLoadingIndicator = styled(LoadingIndicator)`
	align-self: center;
`

export default VerifyEmailPage
