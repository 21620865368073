import { type FC } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import Layout, { PageTitle } from "~/components/Layout"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"

const NotFoundPage: FC = () => {
	const user = useUser()

	if (user.user === undefined) {
		return (
			<Content>
				<Title>Page was not found</Title>
				<Button variant="primary" onClick={routes.account.signin()}>
					Go to sign in
				</Button>
			</Content>
		)
	}

	return (
		<Layout>
			<PageTitle>Page was not found</PageTitle>
			<StyledButton variant="primary" onClick={routes.main.index()}>
				Go to dashboard
			</StyledButton>
		</Layout>
	)
}

const Content = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const Title = styled.h1`
	font-size: 36px;
	font-weight: bold;
	margin-bottom: 20px;
`

const StyledButton = styled(Button)`
	align-self: flex-start;
`

export default NotFoundPage
