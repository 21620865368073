import type React from "react"
import { useState } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import Layout, { PageHeader, PageHeaderGroup, PageTitle } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import { swr } from "~/utilities/trpc"

import InviteUsersModal from "./InviteUsersModal"
import InvitedUsers from "./InvitedUsers"
import Users from "./Users"

const UsersPage: React.FC = () => {
	const [isInviteUsersOpen, setInviteUsersOpen] = useState(false)

	const users = swr.platform.listUsers.useSWR()
	const invitedUsers = swr.platform.listInvitedUsers.useSWR()

	return (
		<Layout>
			<InviteUsersModal
				isOpen={isInviteUsersOpen}
				onClose={() => {
					setInviteUsersOpen(false)
					invitedUsers.mutate()
				}}
			/>
			<PageHeader>
				<PageTitle>Users</PageTitle>
				<PageHeaderGroup>
					<Button variant="primary" onClick={() => setInviteUsersOpen(true)}>
						Invite users
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							if (!users.data) return
							const csv = [
								"First name,Last name,Email,Phone number,Register date",
								...users.data.map(
									user =>
										`${user.firstName},${user.lastName},${user.email},${user.phoneNumber ?? ""},${user.registerDate.toISOString()}`,
								),
							].join("\n")
							const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csv}`)
							const link = document.createElement("a")
							link.setAttribute("href", encodedUri)
							link.setAttribute("download", "Users.csv")
							document.body.appendChild(link)
							link.click()
							document.body.removeChild(link)
						}}
					>
						Export users
					</Button>
				</PageHeaderGroup>
			</PageHeader>
			{users.error ? (
				<p>Failed to load users.</p>
			) : users.data === undefined ? (
				<PartialLoadingPage />
			) : (
				<Users users={users.data} />
			)}
			{invitedUsers.error ? (
				<InvitedUsersContainer>
					<Subtitle>Invited students</Subtitle>
					<p>Failed to load invited students.</p>
				</InvitedUsersContainer>
			) : invitedUsers.data === undefined || invitedUsers.data.length === 0 ? null : (
				<InvitedUsersContainer>
					<Subtitle>Invited students</Subtitle>
					<InvitedUsers invites={invitedUsers.data} />
				</InvitedUsersContainer>
			)}
		</Layout>
	)
}

const InvitedUsersContainer = styled.div`
	margin-top: 32px;
`

const Subtitle = styled.h2`
	font-weight: 600;
	font-size: 24px;
	margin-bottom: 16px;
`

export default UsersPage
