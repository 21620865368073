import styled from "styled-components"

import Button from "~/components/Button"
import Layout, { PageBreadcrumb, PageHeader } from "~/components/Layout"
import PartialLoadingPage from "~/components/PartialLoadingPage"
import routes from "~/utilities/routes"
import { swr } from "~/utilities/trpc"

import Email from "./Email"

const StudentEmailsSettingsPage: React.FC = () => {
	const { data: emails, error, mutate } = swr.studentEmails.list.useSWR()

	return (
		<Layout>
			<PageHeader>
				<PageBreadcrumb title="Student Emails" path={[{ title: "Settings", link: routes.settings.index() }]} />
				<Button variant="primary" onClick={routes.settings.studentEmail.layout()}>
					Edit layout
				</Button>
			</PageHeader>
			{error ? (
				<p>Failed to load student emails.</p>
			) : !emails ? (
				<PartialLoadingPage />
			) : (
				<Content>
					{emails.map(email => (
						<Email key={email.id} reloadEmails={mutate} {...email} />
					))}
				</Content>
			)}
		</Layout>
	)
}

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

export default StudentEmailsSettingsPage
