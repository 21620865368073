import { type FC, useState } from "react"
import styled from "styled-components"

import Button from "~/components/Button"
import { lightTextColor } from "~/utilities/styles"

type Props = {
	illustrationUrl: string
	title: string
	text: string
	callToAction?: {
		label: string
		onClick(): Promise<void>
	}
}

const FeatureIntroduction: FC<Props> = ({ illustrationUrl, title, text, callToAction }) => {
	const [isSubmitting, setSubmitting] = useState(false)

	return (
		<Container>
			<Illustration src={illustrationUrl} alt={`${title} illustration`} />
			<Title>{title}</Title>
			<Text>{text}</Text>
			{callToAction && (
				<Button
					variant="primary"
					onClick={async () => {
						setSubmitting(true)
						await callToAction.onClick()
						setSubmitting(false)
					}}
					isLoading={isSubmitting}
				>
					Enable website
				</Button>
			)}
		</Container>
	)
}

const Container = styled.div`
	background-color: #fbfaf8;
	border: 1px solid #eeeeed;
	border-radius: 8px;
	padding: 42px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
`

const Illustration = styled.img`
	width: 200px;
	margin-bottom: 8px;
`

const Title = styled.h2`
	font-weight: 600;
	font-size: 24px;
`

const Text = styled.p`
	color: ${lightTextColor};
	text-align: center;
	max-width: 500px;
`

export default FeatureIntroduction
