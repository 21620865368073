import styled from "styled-components"

import { type CommunityPostAttachment } from "@forento/shared/models/community"

import Button from "~/components/Button"
import { AttachmentIcon } from "~/components/Icon"
import { primaryColor } from "~/utilities/styles"

const Attachment: React.FC<{ attachment: CommunityPostAttachment }> = ({ attachment }) => (
	<Container newTab onClick={attachment.filePath}>
		<Icon />
		{attachment.label}
	</Container>
)

const Container = styled(Button)`
	display: flex;
	align-items: center;
	gap: 8px;
	border: 1px dashed ${primaryColor};
	border-radius: 8px;
	padding: 8px;
`

const Icon = styled(AttachmentIcon)`
	width: 24px;
	height: 24px;
`

export default Attachment
