import { type ComponentType } from "react"
import { useLocation } from "react-router"
import styled, { css } from "styled-components"

import Button from "~/components/Button"
import {
	CourseIcon,
	HomeIcon,
	PaymentIcon,
	SettingsIcon,
	AnalyticsIcon,
	UserIcon,
	LogoutIcon,
	CommunityIcon,
	MembershipIcon,
	WebIcon,
	VideoIcon,
	MoneyIcon,
	CalendarIcon,
	DiscountIcon,
	QuizIcon,
	DownloadIcon,
	SessionsIcon,
} from "~/components/Icon"
import { useUser } from "~/contexts/UserContext"
import routes from "~/utilities/routes"
import { primaryColor, primaryLightColor } from "~/utilities/styles"

import forentoLogo from "~/assets/logo.svg"

interface SidebarButton {
	label: string
	icon: ComponentType
	onClick: string | (() => void)
	activeUrl?: string
	newFeature?: boolean
}

const Sidebar: React.FC = () => {
	const user = useUser()
	const platform = user.user!.platform!
	const location = useLocation()

	const buttons: SidebarButton[] = [
		{ label: "Dashboard", icon: HomeIcon, onClick: routes.main.index() },
		{ label: "Website", icon: WebIcon, onClick: routes.main.websiteEditor() },
		{ label: "Courses", icon: CourseIcon, onClick: routes.course.index() },
		{ label: "Exams", icon: QuizIcon, onClick: routes.exam.index() },
		{ label: "Videos", icon: VideoIcon, onClick: routes.video.index() },
		{ label: "Downloadables", icon: DownloadIcon, onClick: routes.downloadable.index() },
		{ label: "Membership", icon: MembershipIcon, onClick: routes.membership.index() },
		{
			label: "Community",
			icon: CommunityIcon,
			onClick: platform.community !== null ? routes.community.profiles() : routes.community.index(),
			activeUrl: routes.community.index(),
		},
		{ label: "Sessions", icon: SessionsIcon, onClick: routes.sessions.index() },
		{ label: "Events", icon: CalendarIcon, onClick: routes.main.event() },
		{ label: "Users", icon: UserIcon, onClick: routes.user.index() },
		{ label: "Analytics", icon: AnalyticsIcon, onClick: routes.main.analytics() },
		{ label: "Payments", icon: PaymentIcon, onClick: routes.payments.index(), activeUrl: routes.payments.index() },
		{ label: "Discount codes", icon: DiscountIcon, onClick: routes.main.discountCodes() },
		{ label: "Referrals", icon: MoneyIcon, onClick: routes.main.referral() },
		{ label: "Settings", icon: SettingsIcon, onClick: routes.settings.index() },
		{ label: "Sign out", icon: LogoutIcon, onClick: user.signOut },
	]

	return (
		<Container>
			<SidebarButton onClick={routes.main.index()}>
				<Logo alt="Forento's logo" src={forentoLogo} />
			</SidebarButton>
			<Spacer />
			{buttons.map(button => (
				<SidebarButtonContainer key={button.label}>
					{button.newFeature && <NewFeatureBanner>New</NewFeatureBanner>}
					<SidebarButton
						key={button.label}
						onClick={button.onClick}
						$isActive={
							button.activeUrl !== undefined
								? location.pathname.startsWith(button.activeUrl)
								: typeof button.onClick === "string" &&
									(button.onClick === "/"
										? location.pathname === button.onClick
										: location.pathname.startsWith(button.onClick))
						}
					>
						<SidebarButtonIcon as={button.icon} />
						{button.label}
					</SidebarButton>
				</SidebarButtonContainer>
			))}
		</Container>
	)
}

const Container = styled.div`
	flex: 0 0 247px;
	min-height: 100vh;
	background-color: ${primaryLightColor};
	padding: 30px 20px;
	display: flex;
	flex-direction: column;
`

const Logo = styled.img`
	width: 124px;
	height: 32px;
	object-fit: contain;
`

const SidebarButtonContainer = styled.div`
	position: relative;
`

const NewFeatureBanner = styled.div`
	position: absolute;
	top: 50%;
	right: 0;
	border-radius: 4px;
	padding: 2px 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	color: white;
	background-color: ${primaryColor};
	transform: translateY(-50%);
	pointer-events: none;
`

const SidebarButton = styled(Button)<{ $isActive?: boolean }>`
	font-size: 14px;
	padding: 10px 15px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	gap: 8px;

	${props =>
		props.$isActive &&
		css`
			font-weight: 600;
			color: white;
			background-color: ${primaryColor};
		`}
`

const SidebarButtonIcon = styled.div`
	width: 24px;
	height: 24px;
`

const Spacer = styled.div`
	height: 30px;
`

export default Sidebar
