import { Decimal } from "decimal.js"
import { type Dispatch, useReducer } from "react"

export default function usePrice(defaultValue?: string): {
	price: string
	setPrice: Dispatch<string>
	priceValue: number | null
} {
	const [price, setPrice] = useReducer((current: string, value: string) => {
		if (value === "") return value
		const hasTrailingComma = [".", ","].includes(value[value.length - 1])
		const price = new Decimal(hasTrailingComma ? value.slice(0, -1) : value)
		if (price.isNaN()) return current
		return price.clamp(1, 999999.99).toDecimalPlaces(2, Decimal.ROUND_FLOOR) + (hasTrailingComma ? "." : "")
	}, defaultValue ?? "")

	return {
		price,
		setPrice,
		priceValue: price.length > 0 ? parseFloat(price) : null,
	}
}
